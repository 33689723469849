const labResultsFields = [
    {
        inputField: "text",
        model: "TOTAL LDL-C VALUE",
        componentParams: 
            {
                label: "Insert New LDL-C Value",
            },
        
        hasInsurance: []
    },
    {
        inputField: "text",
        model: "TOTAL CHOLESTEROL VALUE",
        componentParams: 
            {
                label: "Insert New TC Value",
            },
        
        hasInsurance: []
    },
];
export{
    labResultsFields
}
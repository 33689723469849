<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <v-row class="mb-3">
          <h2>Lab Results:</h2>
        </v-row>
        <!-- <v-row>
        <v-btn color="primary" min-width="200px" @click.native="saveTrack"
          >Save Lab Values</v-btn
        >
        </v-row> -->
      </v-col>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <recursive-tree
          v-for="(field, index) in labResultsFields"
          :key="index"
          :questionLabel="field.questionLabel"
          :nodes="field.nodes"
          :inputField="field.inputField"
          :model="field.model"
          :componentParams="field.componentParams"
          :expectedValue="field.expectedValue"
          :previousValue="field.previousValue"
          :rules="field.rules"
          :depth="0"
          :hasInsurance="field.hasInsurance"
          :items="field.items"
        >
        </recursive-tree>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecursiveTree from "@/components/common/ui/Layout/RecursiveTree/RecursiveTree";
import { mapGetters } from "vuex";
import RequestLab from "@/components/common/ui/Form/RequestLab/RequestLab";
import { labService } from "@/render/api/zarya/index";
import { labResultsFields } from "./LabResults";
import storeRequestLab from "@/components/common/ui/Form/RequestLab/store/index";

export default {
  inherit: true,
  components: {
    "recursive-tree": RecursiveTree,
    // "pg-request-lab": RequestLab,
  },
  data() {
    return {
      labResultsFields: labResultsFields,
    };
  },
  computed: {
    ...mapGetters({
      patientProfile: "PatientProfile",
      // labResults: "LabRecord",
      userProfile: "UserProfile",
      pharmacyProfile: "PharmacyProfile",
      isLabRecordAvailable: "IsLabRecordAvailable",
      isLabRecordRequest: "IsLabRecordRequest",
    }),
  },
  beforeCreate() {
    // if (typeof this.$store.getters.LabRecord === "undefined") {
    //   // create module
    //   this.$store.registerModule("RequestLab", storeRequestLab);
    // }
  },
  methods: {
    // async saveTrack() {
    //   const sendLabResults = {
    //     // "TOTAL CHOLESTEROL VALUE": this.labResults.attributes.TC,
    //     // "TOTAL LDL-C VALUE": this.labResults.attributes.LDL
    //     // get from getters
    //     "TOTAL CHOLESTEROL VALUE": this.$store.state.Praluent.praluentPatient[
    //       "TOTAL CHOLESTEROL VALUE"
    //     ],
    //     "TOTAL LDL-C VALUE": this.$store.state.Praluent.praluentPatient[
    //       "TOTAL LDL-C VALUE"
    //     ],
    //   };
    //   // this.$emit("onUpdateState", sendLabResults);
    //   if (this.labResults._id) {
    //     await labService.updateLabByID({
    //       userID: this.$store.getters.UserProfile._id,
    //       pharmacyID: this.$store.getters.PharmacyProfile._id,
    //       patientID: this.$store.state.Patient.data.PatientID,
    //       attributes: {
    //         LDL: sendLabResults["TOTAL LDL-C VALUE"],
    //         TC: sendLabResults["TOTAL CHOLESTEROL VALUE"],
    //       },
    //       _id: this.labResults._id,
    //     });
    //     this.$store.dispatch("setProcess", {
    //       labID: this.labResults._id,
    //       labRequested: true,
    //       labRequestedDateTime: this.$moment().format("YYYY-MM-DD HH:MM:SS"),
    //     });
    //   } else {
    //     const lab = await labService.requestLab({
    //       userID: this.userProfile._id,
    //       pharmacyID: this.pharmacyProfile._id,
    //       patientID: this.patientProfile.PatientID,
    //       attributes: {
    //         LDL: sendLabResults["TOTAL LDL-C VALUE"],
    //         TC: sendLabResults["TOTAL CHOLESTEROL VALUE"],
    //       },
    //     });
    //     this.$store.dispatch("setProcess", {
    //       labID: this.labResults._id,
    //       labRequested: true,
    //       labRequestedDateTime: this.$moment().format("YYYY-MM-DD HH:MM:SS"),
    //     });
    //   }
    // },
    sendResults() {
      // this.$emit("onUpdateState", patientProfile);
    },
  },
};
</script>

<style scoped>
</style>
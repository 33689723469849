<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <v-row class="mb-2">
          <h2>Doctor Information:</h2>
        </v-row>
        <v-row class="">
          <v-dialog v-model="dialogSearch" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="auto" xs="12" sm="12" md="5">
                  <doctor-search
                    v-bind="attrs"
                    v-on="on"
                  ></doctor-search>
                  <v-btn color="primary" @click="openDrOverlay" min-width="200px"
                    >Doctor Search</v-btn
                  >
                </v-col>
              </v-row>
            </template>
          </v-dialog>
        </v-row>

        <v-row class="mb-9" v-if="!specificInsurance(['RXP','CS','ESI02','EP02','AS16','GS','AS','AS11','SSQ','SQ','NEX','NX'])">
          <v-dialog v-model="dialogInfo" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="12" xs="6" sm="auto" md="5">
                  <v-btn color="#B30000"
              class="white--text" v-bind="attrs" v-on="on" min-width="200px">
                    Additional Info
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-card-title>
                <span class="h4">Additional Questions</span>
              </v-card-title>
              <v-card-text>
                <v-form @submit.prevent>
                  <v-container>
                    <v-row v-if="specificInsurance(['BC','OB','ESI', 'EP', 'ESI11','ESI12','EP12'])">
                      <v-col cols="auto" xs="12" sm="12" md="3">
                        <div class="h5">Doctor Info:</div>
                      </v-col>
                      <v-col cols="auto" xs="12" sm="12" md="9">
                        <recursive-tree
                          v-for="(field, index) in docInfoSub"
                          :key="index"
                          :questionLabel="field.questionLabel"
                          :nodes="field.nodes"
                          :inputField="field.inputField"
                          :model="field.model"
                          :componentParams="field.componentParams"
                          :expectedValue="field.expectedValue"
                          :previousValue="field.previousValue"
                          :rules="field.rules"
                          :hasInsurance="field.hasInsurance"
                          :depth="0"
                          :items="field.items"
                        >
                        </recursive-tree>

                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dialogInfo = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-col cols="auto" xs="12" sm="12" md="9">
        <v-row>
        <recursive-tree-multiple
          v-for="(field, index) in docInfo"
          :key="index"
          :questionLabel="field.questionLabel"
          :nodes="field.nodes"
          :inputField="field.inputField"
          :model="field.model"
          :componentParams="field.componentParams"
          :expectedValue="field.expectedValue"
          :previousValue="field.previousValue"
          :rules="field.rules"
          :hasInsurance="field.hasInsurance"
          :depth="0"
        >
        </recursive-tree-multiple>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import RecursiveTree from '@/components/common/ui/Layout/RecursiveTree/RecursiveTree'
import RecursiveTreeMultiple from '@/components/common/ui/Layout/RecursiveTree/RecursiveTreeMultiple'
import Section from '@/components/common/ui/Layout/Section/Section'
import { docInfo } from './praluentData/docInfoMain'
import { docInfoSub } from './praluentData/docInfoSub'
import DoctorSearch from '@/components/common/ui/Form/DoctorSearch/DoctorSearch.vue'

export default {
  components: {
    'pg-section': Section,
    'doctor-search': DoctorSearch,
    'recursive-tree': RecursiveTree,
    'recursive-tree-multiple': RecursiveTreeMultiple,
  },
  data() {
    return {
      docInfo: docInfo,
      docInfoSub: docInfoSub,
      filteredArr: null,
      dialogSearch: false,
      dialogInfo: false,
    }
  },

  computed: {
    ...mapGetters(['PharmacyProfile', 'TrackProcess']),
  },
  methods: {
    filter() {
      // FIXME: Same fix that needs to be done in patientInfo component for this method for optional to work properly
      const arr = []
      const doctorObj = this._data
      for (const prop in doctorObj) {
        if (doctorObj[prop] !== undefined && doctorObj[prop] !== null) {
          // let innerObj = {};
          // innerObj[prop] = doctorObj[prop]
          // arr.push(innerObj);
          arr.push(doctorObj[prop])
        }
      }
      this.filteredArr = arr.every(field => field !== undefined && field !== null)
      // this.filteredArr = true;
      // console.log(this.filteredArr);
    },

    openDrOverlay() {
      window.searchDoctor.open(
        ({
          DoctorFirstName,
          DoctorLastName,
          CPSO,
          telephoneNo,
          faxNo,
          streetNo,
          streetName,
          City,
          Province,
          Postal,
        }) => {
          this.$store.commit('SET_PRALUENT_OVERLAY_DATA', {
            'PRIMARY FIRST NAME': DoctorFirstName,
            'PRIMARY LAST NAME': DoctorLastName,
            CPSO: CPSO,
          })
        },
      )
    },
    specificInsurance(insuranceCompanies) {
      let flag = false
      let privateInsurance
      if (Object.entries(this.$store.getters.TrackProcess.praluentPatient).length === 0) {
        return flag
      } else {
        if (
          this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb' &&
          this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider.length < 2
        ) {
          privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        } else if (this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb') {
          privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[1]
        } else {
          privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        insuranceCompanies.forEach(insuranceCompany => {
          if (privateInsurance.apikey === 'odb') {
            flag = true
          }
          if (privateInsurance.code === insuranceCompany) {
            flag = true
          }
        })
        return flag
      }
    },
  },
}
</script>

<style>
</style>
const patientCoverage = [
    {
        questionLabel: "Are you currently on, or have you previously been on Praluent?",
        inputField: "radio",
        model: "PREVIOUS REPATHA PATIENT",
        childrenModels:[
            'DATE INITIAL VALUE',
            'OTHER INSURANCE NAME REPATHA',
        ],
        expectedValue: null,
        previousValue: null,
        componentParams: [
            {
                label: "Yes",
                value: "previousPraluentYes"
            },
            {
                label: "No",
                value: "previousPraluentNo"
            }
        ],
        hasInsurance: ['AS11'],
        nodes: [
            {
                // FIXME: Should be datepicker
                expectedValue: "previousPraluentYes",
                inputField: "datepicker",
                model: "DATE INITIAL VALUE",
                componentParams: 
                    {
                        label: "Indicate Start Date",
                    },
                
                hasInsurance: ['AS11']
            },
            {
                expectedValue: "previousPraluentYes",
                inputField: "text",
                model: "OTHER INSURANCE NAME REPATHA",
                componentParams: 
                    {
                        label: "Name of Insurance Company",
                    },
                
                hasInsurance: ['AS11']
            },
        ]
    },
    {
        questionLabel: "Do you have valid Medicare Coverage in current province of residence?",
        inputField: "radio",
        model: "MEDICARE BC RADIO",
        componentParams: [
            {
                label: "Yes",
                value: "medicareYes"
            },
            {
                label: "No",
                value: "medicareNo"
            },
        ],
        hasInsurance: ['BC', 'OB']
    },
    {
        questionLabel: "Does patient have Provincial Coverage?",
        inputField: "radio",
        model: "PROVINCIAL COVERAGE RADIO",
        childrenModels:[
            'NO PROVINCIAL APPLICATION',
            'PROVINCIAL PROGRAM NAME',
            'PROVINCIAL APPROVAL RADIO',
            'PROVINCIAL APPROVAL REQUEST',
            'NO PROVINCIAL APPROVAL TEXT'

        ],
        componentParams: [
            {
                label: "Yes",
                value: "provincialCoverageYes"
            },
            {
                label: "No",
                value: "provincialCoverageNo"
            },
        ],
        hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS11', 'ESI02','EP02', 'RXP', 'CS', 'BC', 'OB'],
        nodes: [
            {
                expectedValue: "provincialCoverageNo",
                inputField: "text",
                model: "NO PROVINCIAL APPLICATION",
                componentParams: 
                    {
                        label: "Reason for no application/no provincial coverage"
                    },
                
                hasInsurance: ['ESI', 'EP', 'AS11', 'BC', 'OB', 'ESI02','EP02']
            },
            {
                expectedValue: "provincialCoverageYes",
                inputField: "text",
                model: "PROVINCIAL PROGRAM NAME",
                componentParams: 
                    {
                        label: "Provincial Program Name"
                    },
                
                hasInsurance: ['ESI', 'EP', 'AS11']
            },
            {
                questionLabel: "Has a request for reimbursment been submitted?",
                expectedValue: "provincialCoverageYes",
                inputField: "radio",
                model: "PROVINCIAL APPROVAL RADIO",
                hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS11', 'GS', 'RXP', 'CS','ESI02','EP02'],
                componentParams: [
                    {
                        label: "Yes",
                        value: "provincialApprovedYes"
                    },
                    {
                        label: "No",
                        value: "provincialApprovedNo"
                    }
                ],
                nodes: [
                    {
                        questionLabel: "What was the outcome?",
                        expectedValue: "provincialApprovedYes",
                        inputField: "radio",
                        model: "PROVINCIAL APPROVAL REQUEST",
                        hasInsurance: ['ESI', 'EP', 'GS', 'RXP', 'CS'],
                        componentParams: [
                            {
                                label: "Approved",
                                value: "provincialApproved"
                            },
                            {
                                label: "Denied",
                                value: "provincialDenied"
                            }
                        ],
                    },
                    {
                        expectedValue: "provincialApprovedNo",
                        inputField: "text",
                        model: "NO PROVINCIAL APPROVAL TEXT",
                        hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'ESI02','EP02'],
                        componentParams: 
                            {
                                label: "Please Explain",
                            },
                        
                    }
                ]
            },
        ]
    },



    {
        questionLabel: "Does the patient have/applied for provincial/private coverage?",
        inputField: "radio",
        model: "PROVINCIAL COVERAGE RADIO",
        childrenModels:[
            'PROVINCIAL APPROVAL RADIO',
            'PROVINCIAL APPROVAL RADIO',
            'NO PROVINCIAL APPROVAL TEXT'

        ],
        componentParams: [
            {
                label: "Yes",
                value: "provincialCoverageYes"
            },
            {
                label: "No",
                value: "provincialCoverageNo"
            },
        ],
        hasInsurance: ['GS'],
        nodes:
            [
                {
                    questionLabel: "Has a request for reimbursement been submitted",
                    expectedValue: "provincialCoverageYes",
                    inputField: "radio",
                    model: "PROVINCIAL APPROVAL RADIO",
                    hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS11', 'GS', 'RXP', 'CS'],
                    componentParams: [
                        {
                            label: "Yes",
                            value: "provincialApprovedYes"
                        },
                        {
                            label: "No",
                            value: "provincialApprovedNo"
                        },
                    ],
                },
                {
                    questionLabel: "Have you been approved by the provincial program for this drug?",
                    expectedValue: "provincialCoverageYes",
                    inputField: "radio",
                    model: "PROVINCIAL APPROVAL RADIO",
                    hasInsurance: ['ESI02','EP02'],
                    componentParams: [
                        {
                            label: "Yes",
                            value: "provincialApprovedYes"
                        },
                        {
                            label: "No",
                            value: "provincialApprovedNo"
                        },
                    ],
                    nodes: [
                        {
                            expectedValue: "provincialApprovedNo",
                            inputField: "text",
                            model: "NO PROVINCIAL APPROVAL TEXT",
                            hasInsurance: ['ESI02','EP02'],
                            componentParams: 
                                {
                                    label: "Please Explain",
                                },
                            
                        }
                    ]
                },
            ]
    },
    {
        questionLabel: "Have you already purchased this prescription? (If yes, please attach your paid-in-full receipt. If submitted already, please indicate the date of oldest receipt)",
        // expectedValue:"provincialCoverageYes",
        inputField: "radio",
        childrenModels:[
            'ALREADY DATE SUBMITTED RECEIPT'
        ],
        model: "ALREADY PURCHASED RADIO",
        hasInsurance: ['BC', 'OB'],
        componentParams: [
            {
                label: "Yes",
                value: "alreadyPurchasedYes"
            },
            {
                label: "No",
                value: "alreadyPurchasedNo"
            },
        ],
        nodes: [
            {
                // should be datepicker
                // v-menu model : MENURECEIPT
                expectedValue: "alreadyPurchasedYes",
                inputField: "datepicker",
                model: "ALREADY DATE SUBMITTED RECEIPT",
                hasInsurance: ['BC', 'OB'],
                componentParams: 
                    {
                        label: "Select Date",
                    },
            }
        ]
    },

    {
        questionLabel: "Does Patient have another insurance/covered under any other group plan for the prescribed drug?",
        inputField: "radio",
        childrenModels:[
            'OTHER INSURANCE PERSON',
            'OTHER INSURANCE NAME',
            'OTHER INSURANCE GROUP',
            'OTHER INSURANCE ID',
            'SECOND INSURANCE RELATIONSHIP',
            'OTHER DRUG PLAN',
            'ALREADY SUBMITTED RADIO',
            'STATUS OF SUBMITTED RADIO',
            'PRIOR AUTH REQUEST',
            'STATUS OF PRIOR AUTH RADIO'
        ],
        model: "OTHER INSURANCE RADIO",
        hasInsurance: ['AS11', 'AS16', 'SSQ', 'SQ', 'ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value: 'otherInsuranceYes'
            },
            {
                label: "No",
                value: 'otherInsuranceNo'
            },
        ],
        nodes: [
            {
                expectedValue: "otherInsuranceYes",
                inputField: "text",
                model: "OTHER INSURANCE PERSON",
                hasInsurance: ['AS11', 'AS16'],
                componentParams: 
                    {
                        label: "Name of covered member",
                    },
                
            },
            {
                expectedValue: "otherInsuranceYes",
                inputField: "text",
                model: "OTHER INSURANCE NAME",
                hasInsurance: ['AS11', 'ESI02','EP02', 'AS16'],
                componentParams: 
                    {
                        label: "Name of Insurance Company",
                    },
                
            },
            {
                expectedValue: "otherInsuranceYes",
                inputField: "text",
                model: "OTHER INSURANCE GROUP",
                hasInsurance: ['AS11', 'ESI02','EP02', 'AS16'],
                componentParams: 
                    {
                        label: "Plan Contract number",
                    },
                
            },
            {
                expectedValue: "otherInsuranceYes",
                inputField: "text",
                model: "OTHER INSURANCE ID",
                hasInsurance: ['AS11', 'ESI02','EP02', 'AS16'],
                componentParams: 
                    {
                        label: "Plan Member Certificate number",
                    },
                
            },
            {
                expectedValue: "otherInsuranceYes",
                inputField: "text",
                model: "SECOND INSURANCE RELATIONSHIP",
                hasInsurance: ['AS11'],
                componentParams: 
                    {
                        label: "Relationship to member",
                    },
                
            },
            {
                questionLabel: "Private or public insurance plan type?",
                expectedValue: "otherInsuranceYes",
                inputField: "radio",
                model: "OTHER DRUG PLAN",
                hasInsurance: ['SSQ', 'SQ'],
                componentParams: [
                    {
                        label: "Private",
                        value: "ssqPRIVATE"
                    },
                    {
                        label: "Public",
                        value: "ssqPublic"
                    },
                ],
            },
            {
                questionLabel: "Have you ever submitted a claim, for this drug?",
                expectedValue: "otherInsuranceYes",
                inputField: "radio",
                model: "ALREADY SUBMITTED RADIO",
                hasInsurance: ['SSQ', 'SQ'],
                componentParams: [
                    {
                        label: "Yes",
                        value: "ssqPreviousClaimYES"
                    },
                    {
                        label: "No",
                        value: "ssqPreviousClaimNO"
                    },
                ],
                nodes: [
                    {
                        questionLabel: "What is the status of the claim?",
                        expectedValue: "ssqPreviousClaimYES",
                        inputField: "radio",
                        model: "STATUS OF SUBMITTED RADIO",
                        hasInsurance: ['SSQ', 'SQ'],
                        componentParams: [
                            {
                                label: "Approved",
                                value: "ssqApprovedYES"
                            },
                            {
                                label: "Rejected",
                                value: "ssqRejectedNO"
                            },
                            {
                                label: "Under Review",
                                value: "ssqUNDERREVIEW"
                            },
                        ],
                    }
                ]
            },
            {
                questionLabel: "Did this insurer ask you to complete a prior authorization request?",
                expectedValue: "otherInsuranceYes",
                inputField: "radio",
                model: "PRIOR AUTH REQUEST",
                hasInsurance: ['SSQ', 'SQ'],
                componentParams: [
                    {
                        label: "Yes",
                        value: "ssqPriorAuthYES"
                    },
                    {
                        label: "No",
                        value: "ssqPriorAuthNO"
                    },
                ],
                nodes: [
                    {
                        questionLabel: "What is the status of the prior authorization request?",
                        expectedValue: "ssqPriorAuthYES",
                        inputField: "radio",
                        model: "STATUS OF PRIOR AUTH RADIO",
                        hasInsurance: ['SSQ', 'SQ'],
                        componentParams: [
                            {
                                label: "Yes",
                                value: "ssqPriorAuthApprovedYES"
                            },
                            {
                                label: "No",
                                value: "ssqPriorAuthRejectedNO"
                            },
                            {
                                label: "Under Review",
                                value: "ssqPriorAuthUNDERREVIEW"
                            },
                        ],
                    }
                ]
            },
        ]
    },

    {
        questionLabel: "Did previous plan recently transfer drug benefits to Manulife?",
        inputField: "radio",
        model: "RECENT TRANSFER RADIO",
        hasInsurance: ['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value: "manulifePlanTransferYes"
            },
            {
                label: "No",
                value: "manulifePlanTransferNo"
            },
        ],
    },
    {
        questionLabel: "Before joining Manulife, were you receiving coverage for this drug through your previous insurance company?",
        inputField: "radio",
        model: "PREVIOUS INSURANCE RADIO",
        hasInsurance: ['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes (Please attach proof of payment)",
                value: "manulifePreviousCoverageYes"
            },
            {
                label: "No",
                value: "manulifePreviousCoverageNo"
            },
        ],
    },

    {
        questionLabel: "Is patient disabled?",
        inputField: "radio",
        model: "DISABLED RADIO",
        hasInsurance: ['AS16'],
        componentParams: [
            {
                label: "Yes",
                value: "sunlifeDisabledYes"
            },
            {
                label: "No",
                value: "sunlifeDisabledNo"
            },
        ],
    }
]
const patientQuestionnaire = [
    {
        inputField: "text",
        model: "PATIENT CODE",
        componentParams: 
            {
                label: "Patient Code",
            },
        
        hasInsurance: ['ESI12','EP12', 'NEX', 'NX']
    },
    {
        inputField: "text",
        model: "NAME OF EMPLOYER",
        componentParams: 
            {
                label: "Name of Employer",
            },
        
        hasInsurance: ['ESI12','EP12', 'GS', 'SSQ', 'SQ', 'AS16']
    },
    {
        questionLabel: "Patient's Gender",
        inputField: "radio",
        model: "PATIENT GENDER",
        expectedValue: null,
        previousValue: null,
        componentParams: [
            {
                label: "Male",
                value: "patientGenderMale"
            },
            {
                label: "Female",
                value: "patientGenderFemale"
            }
        ],
        hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS16']
    },

    {
        inputField: "text",
        model: "PATIENT WEIGHT",
        childrenModels:[
            'WEIGHT MEASURE',
        ],
        componentParams: 
            {
                label: "Patient's Weight",
            },
        
        hasInsurance: ['ESI12','EP12', 'BC', 'OB', 'RXP', 'CS'],
        nodes: [
            {
                // default this to lb and remove radio?
                questionLabel: "Kg/lb",
                inputField: "radio",
                model: "WEIGHT MEASURE",
                expectedValue: null,
                previousValue: null,
                componentParams: [
                    {
                        label: "lb",
                        value: "pounds"
                    },
                    {
                        label: "kg",
                        value: "kilos"
                    }
                ],
                hasInsurance: ['ESI12','EP12', 'BC', 'OB']
            }
        ]
    },
    {
        inputField: "text",
        model: "PREFERRED NUMBER",
        componentParams: 
            {
                label: "Preferred Contact Number",
            },
        
        hasInsurance: ['AS11']
    },
    {
        inputField: "text",
        model: "PREFERRED TIME",
        componentParams: 
            {
                label: "Preferred Contact Time",
            },
        
        hasInsurance: ['AS11']
    },

    {
        questionLabel: "Communication Preference",
        inputField: "radio",
        model: "RESPONSE RADIO",
        componentParams: [
            {
                label: "Fax",
                value: "responseFAX"
            },
            {
                label: "Mail",
                value: "responseMAIL"
            }
        ],
        hasInsurance: ['ESI12','EP12']
    },
    {
        questionLabel: "Communication Preference",
        inputField: "radio",
        model: "RESPONSE RADIO",
        componentParams: [
            {
                label: "Email",
                value: "responseEmail"
            },
            {
                label: "Telephone",
                value: "responseTelephone"
            },
            {
                label: "Fax",
                value: "responseFax"
            },
            {
                label: "Mail",
                value: "responseMail"
            }
        ],
        hasInsurance: ['ESI', 'EP']
    },
    {
        questionLabel: "Communication Preference",
        inputField: "radio",
        model: "RESPONSE RADIO",
        componentParams: [
            {
                label: "Email",
                value: "responseEMAIL"
            },
            {
                label: "Telephone",
                value: "responseTELEPHONE"
            },
            {
                label: "Fax",
                value: "responseFAX"
            },
            {
                label: "Pharmacy",
                value: "telusPHARMACY"
            },
        ],
        hasInsurance: ['AS'],
        nodes:[ 
            {
                expectedValue: "responseFAX",
                inputField: "text",
                model: "FAX NUMBER",
                hasInsurance: ['AS'],
                componentParams: 
                    {
                        label: "Fax Number",
                    },
            }
        ]
    },
    {
        questionLabel: "Communication Preference",
        inputField: "radio",
        model: "RESPONSE RADIO",
        componentParams: [
            {
                label: "Telephone",
                value: "responseTelephone"
            },
            {
                label: "Fax",
                value: "responseFax"
            },
            {
                label: "Mail",
                value: "responseMail"
            }
        ],
        childrenModels:[
            'FAX NUMBER'
        ],
        hasInsurance: ['AS16'],
        nodes:[
            {
                expectedValue: "responseFax",
                inputField: "text",
                model: "FAX NUMBER",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Fax Number",
                    },
            }
        ]
    },
    {
        questionLabel: "Communication Preference",
        inputField: "radio",
        model: "RESPONSE RADIO",
        componentParams: [
            {
                label: "Email",
                value: "responseEMAIL"
            },
            {
                label: "Pharmacy",
                value: "nexCommPHARMACY"
            },
            {
                label: "Patient/Legal Guardian",
                value: "nexCommPATIENT"
            },
        ],
        hasInsurance: ['NEX','NX']
    },
    {
        questionLabel: "If registered with eProfile, would you like the response be sent to your eProfile email?",
        inputField: "radio",
        model: "RESPONSE RADIO",
        componentParams: [
            {
                label: "Yes, Send to my eProfile account",
                value: "eProfileYes"
            },
            {
                label: "No, do not email a response",
                value: "eProfileNo"
            },
        ],
        hasInsurance: ['RXP', 'CS']
    },
    {
        questionLabel: "Be communicated by email?",
        inputField: "radio",
        model: "RESPONSE RADIO",
        componentParams: [
            {
                label: "Yes",
                value: "gwlEmailYes"
            },
            {
                label: "No",
                value: "gwlEmailNo"
            },
        ],
        hasInsurance: ['AS11']
    },
]


export { patientCoverage, patientQuestionnaire }
const statinQuestionsTemplate = {
        daysintherapy: "",
        statin:"",
        statincompliance:"",
        statindosestatinend:"",
        statinoutcome:"",
        statinstart:""
    }

export {
    statinQuestionsTemplate
}
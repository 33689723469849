<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <v-row class="mb-2">
          <h2>Pharmacist Information:</h2>
        </v-row>
        <v-row class="mb-0">
          <v-dialog v-model="dialogSearch" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="auto" xs="12" sm="12" md="5">
                  <pharmacist-search
                    v-bind="attrs"
                    v-on="on"
                    :key="refresh"
                  ></pharmacist-search>
                  <v-btn color="primary" @click.native="openPharmacistOverlay"
                    >Pharmacist Search</v-btn
                  >
                </v-col>
              </v-row>

            </template>
          </v-dialog>
        </v-row>

        <v-row class="mb-9" v-if="!specificInsurance(['RXP','CS','ESI02','EP02','GS','AS','AS11','ESI','ESI11','ESI12','EP12','SSQ','SQ','NEX','NX','ODB','DB'])">
          <v-dialog v-model="dialogInfo" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="12" xs="6" sm="auto" md="5">
                  <v-btn color="#B30000"
              class="white--text" v-bind="attrs" v-on="on" min-width="200px">
                    Additional Info
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-card-title>
                <span class="h4">Additional Questions</span>
              </v-card-title>
              <v-card-text>
                <v-form @submit.prevent>
                  <v-container>
                    <v-row v-if="specificInsurance(['AS16','BC','OB'])">
                      <v-col cols="auto" xs="12" sm="12" md="3">
                        <div class="h5">Communication Option:</div>
                      </v-col>
                      <v-col cols="auto" xs="12" sm="12" md="9">
                        <recursive-tree
                          v-for="(field, index) in pharmacistInfoSub"
                          :key="index"
                          :questionLabel="field.questionLabel"
                          :nodes="field.nodes"
                          :inputField="field.inputField"
                          :model="field.model"
                          :componentParams="field.componentParams"
                          :expectedValue="field.expectedValue"
                          :previousValue="field.previousValue"
                          :rules="field.rules"
                          :hasInsurance="field.hasInsurance"
                          :depth="0"
                          :items="field.items"
                        >
                        </recursive-tree>

                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dialogInfo = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>



      </v-col>
      <v-col cols="auto" xs="12" sm="12" md="9">
        <v-row>
        <recursive-tree-multiple
          v-for="(field, index) in pharmacistInfo"
          :key="index"
          :questionLabel="field.questionLabel"
          :nodes="field.nodes"
          :inputField="field.inputField"
          :model="field.model"
          :componentParams="field.componentParams"
          :expectedValue="field.expectedValue"
          :previousValue="field.previousValue"
          :rules="field.rules"
          :hasInsurance="field.hasInsurance"
          :depth="0"
        >
        </recursive-tree-multiple>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import RecursiveTree from "@/components/common/ui/Layout/RecursiveTree/RecursiveTree";
import RecursiveTreeMultiple from "@/components/common/ui/Layout/RecursiveTree/RecursiveTreeMultiple";
import Section from "@/components/common/ui/Layout/Section/Section";
import PharmacistSearch from "@/components/common/ui/Form/PharmacistSearch/PharmacistSearch";
import { pharmacistInfo } from "./praluentData/pharmacistInfoMain";
import {
  pharmacistInfoSub
} from "./praluentData/pharmacistInfoSub";

export default {
  components: {
    "pg-section": Section,
    "pharmacist-search": PharmacistSearch,
    "recursive-tree": RecursiveTree,
    "recursive-tree-multiple": RecursiveTreeMultiple,
  },
  data() {
    return {
      pharmacistInfo: pharmacistInfo,
      pharmacistInfoSub: pharmacistInfoSub,
      dialogSearch: false,
      dialogInfo: false,
      refresh: true,
    };
  },

  computed: {
    ...mapGetters(["PharmacyProfile","TrackProcess"]),
  },
  methods: {
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.$store.commit("SET_PRALUENT_OVERLAY_DATA", {
          //FIXME: probably able to clean this up a bit better
          rphfirstname: UserName,
          ocp: PharmID,
          pharmacyName: this.$store.getters.PharmacyProfile.name,
          pharmacyEmail: this.$store.getters.PharmacyProfile.email,
          pharmacyTelephoneNo: this.$store.getters.PharmacyProfile.phone,
          pharmacyFaxNo: this.$store.getters.PharmacyProfile.fax,
          pharmacyUnitNo: this.$store.getters.PharmacyProfile.address.unit,
          pharmacyStreetNo: this.$store.getters.PharmacyProfile.address
            .streetNumber,
          pharmacyStreetName: this.$store.getters.PharmacyProfile.address
            .streetName,
          pharmacyCity: this.$store.getters.PharmacyProfile.address.city,
          pharmacyProvince: this.$store.getters.PharmacyProfile.address.state,
          pharmacyPostal: this.$store.getters.PharmacyProfile.address.code,
        });
      });
    },
    specificInsurance(insuranceCompanies) {
      let flag = false;
      let privateInsurance;
      if(Object.entries(this.$store.getters.TrackProcess.praluentPatient).length === 0 ){
        return flag;
      }else{
        if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb' && this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider.length < 2){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        else if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb'){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[1]
        }
        else{
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        insuranceCompanies.forEach(insuranceCompany => {
          if(privateInsurance.apikey === 'odb'){
            flag = true;
          }
          if (privateInsurance.code === insuranceCompany) {
            flag = true;
          }
        });
        return flag;
      }
    },
  },
};
</script>
<template>
    <div>
      <div v-if="!isLabRecordRequest && !isLabRecordAvailable">
        <p class="subheading text-xs-left">Please review and accept the following in order to proceed, Request for patient assessment:</p>

          <v-layout row wrap>
            <v-flex xs1 fill-height fluid class="text-xs-right">
              <v-checkbox ripple="ripple" v-model="authorizeLabResults"></v-checkbox>
            </v-flex>
            <v-flex xs11 fill-height fluid align-content-start justify-center class="subheading text-sm-left">
              Please assess “this patient's” current medication regimen, and provide lab data and recommendations relating to lipid management.
            </v-flex>
            <v-flex xs1 fill-height fluid class="text-xs-right">
              <v-checkbox ripple="ripple" v-model="patientConsent"></v-checkbox>
            </v-flex>
            <v-flex xs11 fill-height fluid align-content-start justify-center class="subheading text-sm-left">
              this patient” has consented to including healthcare providers working with PharmaGuide within their circle of care and is aware that their medical records and lab data may be accessed and discussed with their other healthcare providers in order to optimize their healthcare.
            </v-flex>
          </v-layout>

          <v-btn 
              color="primary"
              type="button"
              :disabled="!(patientConsent && authorizeLabResults)"
              @click.native="requestLabResults()"
          >
              Get lab results
          </v-btn>
      </div>

      <p class="subheading text-xs-left" v-if="isLabRecordRequest && !isLabRecordAvailable">
          Lab results has been requested, you will be notified when results are available. 
      </p>
    </div>
</template>

<script>
// import PatientInfo from "@/components/common/PatientInfo/PatientInfo";
// import Section from "@/components/common/ui/Layout/Section/Section";
import storeRequestLab from "./store/index";
import { mapGetters } from 'vuex';

export default {
  components: {
    // "pg-section": Section
  },
  props: {
    labAttributes: {
      type: Array,
      required: true
    }
  },
  beforeCreate() {
    if (typeof this.$store.getters.LabRecord === 'undefined') {
      // create module
      this.$store.registerModule("RequestLab", storeRequestLab);
    }
  },
  data() {
    return {
      labSetUnsubscribe: null,
      authorizeLabResults: false,
      patientConsent: false,
      ripple: false
    };
  },
  computed: {
    ...mapGetters({
        labRecord: "LabRecord",
        isLabRecordAvailable: "IsLabRecordAvailable",
        isLabRecordRequest: "IsLabRecordRequest"
    })
  },
  mounted() {
    // fire off
    this.labSetUnsubscribe = this.$store.subscribe((mutation, state) => {
      if(mutation.type == 'SET_LAB_RESULTS'){
        if (this.isLabRecordAvailable) {
          this.$emit('labresults', this.labRecord.attributes);
        }
      }
    });
  },
  destroyed(){
    this.labSetUnsubscribe();
  },
  methods: {
    async requestLabResults() {
      const lab = await this.$store.dispatch("requestLabResults", this.labAttributes);

      await this.$store.dispatch("setProcess", {
          labID: lab._id,
          labRequested: true,
          patientConsent: true,
          pharmacyAgreement: true,
      });
    },
  }
};
</script>

<style scoped>
</style>
const medicationQuestions = [
    {
        textTitle:"Ezetimibe",
        inputField: "text",
        model: "statindose1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Dose",
            },
    },
    {
        inputField: "text",
        model: "daysontherapy1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Duration",
            },
    },
    {
        questionLabel:"In association with evolocumab:",
        inputField: "radio",
        model: "EVOLOCUMAB1",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes19"
            },
            {
                label:"No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"If Discontinued, please indicate reason:",
        inputField: "radio",
        model: "MED TREATMENT RESPONSE1",
        childrenModels:['MEDICATION TREATMENT SPECIFY1'],
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Inefficient",
                value:"INEFFICIENT"
            },
            {
                label:"Intolerance",
                value:"INTOLERANCE"
            },
            {
                label:"Contraindication",
                value:"CONTRAINDICATION"
            },
            {
                label:"Other",
                value:"OTHER"
            },
        ],
        nodes:[
            {
                expectedValue:"OTHER",
                inputField: "text",
                model: "MEDICATION TREATMENT SPECIFY1",
                hasInsurance:['BC','OB'],
                componentParams: 
                    {
                        label: "Specify...",
                    },
            }
        ]
    },
    {
        textTitle:"Fibrates",
        inputField: "text",
        model: "REGIMEN FIBRATE DOSE1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Dose",
            },
    },
    {
        inputField: "text",
        model: "REGIMEN FIBRATE DURATION1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Duration",
            },
    },
    {
        questionLabel:"In association with evolocumab:",
        inputField: "radio",
        model: "EVOLOCUMAB2",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes20"
            },
            {
                label:"No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"If Discontinued, please indicate reason:",
        inputField: "radio",
        model: "MED TREATMENT RESPONSE2",
        childrenModels:[
            'MEDICATION TREATMENT SPECIFY2'
        ],
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Inefficient",
                value:"INEFFICIENT"
            },
            {
                label:"Intolerance",
                value:"INTOLERANCE"
            },
            {
                label:"Contraindication",
                value:"CONTRAINDICATION"
            },
            {
                label:"Other",
                value:"OTHER"
            },
        ],
        nodes:[
            {
                expectedValue:"OTHER",
                inputField: "text",
                model: "MEDICATION TREATMENT SPECIFY2",
                hasInsurance:['BC','OB'],
                componentParams: 
                    {
                        label: "Specify...",
                    }, 
            }
        ]
    },

    // sequestrant
    {
        textTitle:"Other Sequestrant Name",
        inputField: "text",
        model: "REGIMEN OTHER SEQUESTRANT NAME1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Nane",
            },
    },
    {
        inputField: "text",
        model: "REGIMEN OTHER SEQUESTRANT DOSE1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Dose",
            },
    },
    {
        inputField: "text",
        model: "REGIMEN OTHER SEQUESTRANT DURATION1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Duration",
            },
    },
    {
        questionLabel:"In association with evolocumab:",
        inputField: "radio",
        model: "EVOLOCUMAB3",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes21"
            },
            {
                label:"No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"If Discontinued, please indicate reason:",
        inputField: "radio",
        model: "MED TREATMENT RESPONSE3",
        childrenModels:['MEDICATION TREATMENT SPECIFY3'],
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Inefficient",
                value:"INEFFICIENT"
            },
            {
                label:"Intolerance",
                value:"INTOLERANCE"
            },
            {
                label:"Contraindication",
                value:"CONTRAINDICATION"
            },
            {
                label:"Other",
                value:"OTHER"
            },
        ],
        nodes:[
            {
                expectedValue:"OTHER",
                inputField: "text",
                model: "MEDICATION TREATMENT SPECIFY3",
                hasInsurance:['BC','OB'],
                componentParams: 
                    {
                        label: "Specify...",
                    },
            }
        ]
    },
    // other med1
    {
        textTitle:"Other Medication",
        inputField: "text",
        model: "REGIMEN OTHER NAME OF MEDICATION1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Name",
            },

        
    },
    {
        inputField: "text",
        model: "REGIMEN OTHER DOSE OF MEDICATION1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Dose",
            },

        
    },
    {
        inputField: "text",
        model: "REGIMEN OTHER DURATION OF MEDICATION1",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Duration",
            },

        
    },
    {
        questionLabel:"In association with evolocumab:",
        inputField: "radio",
        model: "EVOLOCUMAB4",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes22"
            },
            {
                label:"No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"If Discontinued, please indicate reason:",
        inputField: "radio",
        model: "MED TREATMENT RESPONSE4",
        childrenModels:['MEDICATION TREATMENT SPECIFY4'],
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Inefficient",
                value:"INEFFICIENT"
            },
            {
                label:"Intolerance",
                value:"INTOLERANCE"
            },
            {
                label:"Contraindication",
                value:"CONTRAINDICATION"
            },
            {
                label:"Other",
                value:"OTHER"
            },
        ],
        nodes:[
            {
                expectedValue:"OTHER",
                inputField: "text",
                model: "MEDICATION TREATMENT SPECIFY4",
                hasInsurance:['BC','OB'],
                componentParams: 
                    {
                        label: "Specify...",
                    },
    
                
            }
        ]
    },
    // other med2
    {
        textTitle:"Other Medication",
        inputField: "text",
        model: "REGIMEN OTHER NAME OF MEDICATION2",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Name",
            },

        
    },
    {
        inputField: "text",
        model: "REGIMEN OTHER DOSE OF MEDICATION2",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Dose",
            },
        
    },
    {
        inputField: "text",
        model: "REGIMEN OTHER DURATION OF MEDICATION2",
        hasInsurance:['BC','OB'],
        componentParams: 
            {
                label: "Duration",
            },

        
    },
    {
        questionLabel:"In association with evolocumab:",
        inputField: "radio",
        model: "EVOLOCUMAB5",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes23"
            },
            {
                label:"No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"If Discontinued, please indicate reason:",
        inputField: "radio",
        model: "MED TREATMENT RESPONSE5",
        childrenModels:['MEDICATION TREATMENT SPECIFY5'],
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Inefficient",
                value:"INEFFICIENT"
            },
            {
                label:"Intolerance",
                value:"INTOLERANCE"
            },
            {
                label:"Contraindication",
                value:"CONTRAINDICATION"
            },
            {
                label:"Other",
                value:"OTHER"
            },
        ],
        nodes:[
            {
                expectedValue:"OTHER",
                inputField: "text",
                model: "MEDICATION TREATMENT SPECIFY5",
                hasInsurance:['BC','OB'],
                componentParams: 
                    {
                        label: "Specify...",
                    },
        
                
            }
        ]
    },
    {
        textTitle:"If the patient shows signs of statin intolerance, please confirm that the following conditions have been ruled out as possible predisposing factors:",
        questionLabel:"Untreated hypothyroidism",
        inputField: "radio",
        model: "HYPOTHYROID RADIO",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes24"
            },
            {
                label: "No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"Acute renal failure",
        inputField: "radio",
        model: "ACUTE RENAL FAILURE RADIO",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes25"
            },
            {
                label: "No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"Drug Interactions",
        inputField: "radio",
        model: "DRUG INTERACTIONS RADIO",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes26"
            },
            {
                label: "No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"Bilary Obstruction",
        inputField: "radio",
        model: "BILARY OBSTRUCTION RADIO",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes27"
            },
            {
                label: "No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"Febrile illness",
        inputField: "radio",
        model: "FEBRILE ILLNESS RADIO",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes28"
            },
            {
                label: "No",
                value:"no"
            },
        ],
    },
    {
        questionLabel:"Alcoholism",
        inputField: "radio",
        model: "ALCOHOLISM RADIO",
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Yes",
                value:"medicationsYes29"
            },
            {
                label: "No",
                value:"no"
            },
        ],
    },

    // express
    {
        inputField: "text",
        model: "DETAIL ON CLINICAL RESPONSE",
        hasInsurance:['ESI','EP'],
        componentParams: 
            {
                label: "Details on clinical response to requested drug",
            },
        
    },
    // greenshield
    {
        textTitle:"If the patient was non-compliant to regimens or if there are circumstances that may have prevented adherence, please explain why:",
        inputField: "text",
        model: "REGIMEN NON COMPLIANT",
        hasInsurance:['GS'],
        componentParams: 
            {
                label: "Explain...",
            },
    },
    // ssq
    {
        inputField: "text",
        model: "TOTAL HDL-C VALUE",
        hasInsurance:['SSQ','SQ'],
        componentParams: 
            {
                label: "TOTAL HDL-C VALUE",
            },
        
    },
    {
        inputField: "text",
        model: "TOTAL APO-B VALUE",
        hasInsurance:['SSQ','SQ'],
        componentParams: 
            {
                label: "TOTAL APO-B VALUE",
            },
        
    },
    // desjardins
    {
        textTitle:"Intolerance to two or more statins as shown by the following:(check all applicable)",
        inputField: "checkbox",
        model: "PRIOR MED CHECKBOX",
        hasInsurance:['ESI12','EP12'],
        componentParams: [
            {
                label: "Myopathy or myalgia(muscle pain, pain or weakness without CK elevation)",
            },
        ],
    },
    {
        inputField: "checkbox",
        model: "PRIOR MED CHECKBOX2",
        hasInsurance:['ESI12','EP12'],
        componentParams: [
            {
                label: "Myositis",
            },
        ],
    },
    {
        inputField: "checkbox",
        model: "PRIOR MED CHECKBOX3",
        hasInsurance:['ESI12','EP12'],
        componentParams: [
            {
                label: "Rhabdomyolysis",
            },
        ],
    },
    // manulife
    {
        questionLabel:"If no previous therapies have been tried for the selected diagnosis, please specify the rationale",
        inputField: "radio",
        model: "MANULIFE DRUG HISTORY",
        childrenModels:['MANULIFE RATIONALE TEXT'],
        hasInsurance:['ESI02','EP02'],
        componentParams: [
          {
            label: "Risk of drug interaction",
            value:"R"
          },
          {
            label:"Patient has contraindication",
            value:"C"
          },
          {
            label:"Other",
            value:"O"
          }
        ],
        nodes:[
          {
            expectedValue:"O",
            inputField: "text",
            model: "MANULIFE RATIONALE TEXT",
            hasInsurance:['ESI02','EP02'],
            componentParams: 
              {
                label: "Explain",
              },
            
          },
        ]
    },
    // gwl questions
    {
        questionLabel:"Has patient been taking a maximally-tolerated dose of statin therapy for at least 4 weeks and is expected to continue on statin therapy?",
        inputField: "radio",
        model: "GWL MED HISTORY",
        childrenModels:[
            'STATIN INTOLERANCE1',
            'STATIN INTOLERANCE2',
            'STATIN INTOLERANCE3'
        ],
        hasInsurance:['AS11'],
        componentParams: [
          {
            label: "Yes, Complete Statin Medication History",
            value:"medicationsYesGwl"
          },
          {
            label:"No, Complete Statin Medication History and Statin Contraindications/Intolerance sections below",
            value:"no"
          },
        ],
        nodes:[
            {
                textTitle:"Statin Intolerance",
                inputField: "checkbox",
                expectedValue:'no',
                model: "STATIN INTOLERANCE1",
                hasInsurance:['AS11'],
                componentParams: [
                    {
                        label: "At least one trial must be a rechallenge at a lower dose or lower intensity statin",
                    },
                ],
            },
            {
                inputField: "checkbox",
                expectedValue:'no',
                model: "STATIN INTOLERANCE2",
                hasInsurance:['AS11'],
                componentParams: [
                    {
                        label: "Resolution or improvement of symptoms when the statin dose was decreased or discontinued",
                    },
                ],
            },
            {
                inputField: "checkbox",
                expectedValue:'no',
                model: "STATIN INTOLERANCE3",
                hasInsurance:['AS11'],
                componentParams: [
                    {
                        label: "Reason for discontinuation, details of intolerance or failure at maximum dose must be provided",
                    },
                ],
            },
        ]
    },
    {
        textTitle:"Contraindications",
        inputField: "checkbox",
        model: "GWL PREGNANCY1",
        hasInsurance:['AS11'],
        componentParams: [
            {
                label: "Pregnant or may become pregnant",
            },
        ],
    },
    {
        inputField: "checkbox",
        model: "GWL NURSING1",
        hasInsurance:['AS11'],
        componentParams: [
            {
                label: "Nursing mother",
            },
        ],
    },
    {
        inputField: "checkbox",
        model: "GWL ACTIVE LIVER DISEASE1",
        hasInsurance:['AS11'],
        componentParams: [
            {
                label: "Active liver disease or unexplained persistent elevations of serum transaminases exceeding 3 times the upper limit of normal",
            },
        ],
    },
    {
        inputField: "checkbox",
        model: "CONTRAINDICATIONS OTHER",
        childrenModels:['INTOLERANCE SPECIFY TEXT1'],
        hasInsurance:['AS11'],
        componentParams: [
            {
                label: "Other",
            },
        ],
        nodes:[
            {
                expectedValue:"yes",
                inputField: "text",
                model: "INTOLERANCE SPECIFY TEXT1",
                hasInsurance:['AS11'],
                componentParams: 
                    {
                        label: "Other contraindications",
                    },
            }
        ]
    },  
];

export {
    medicationQuestions
}



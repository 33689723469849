<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <v-row class="mb-3">
          <h2>Drug Requested:</h2>
        </v-row>
        <v-row class="mb-9"  v-if="!specificInsurance(['ODB','DB'])">
          <v-dialog v-model="dialogInfo" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="12" xs="6" sm="auto" md="5">
                  <v-btn color="#B30000"
              class="white--text" v-bind="attrs" v-on="on" min-width="200px">
                    Additional Info
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-card-title>
                <span class="h4">Additional Questions</span>
              </v-card-title>
              <v-card-text>
                <v-form @submit.prevent>
                  <v-container>
                    <v-row v-if="specificInsurance(['ESI12','EP12','NEX','NX','GS','SSQ','SQ','AS16','ESI','EP','BC','OB','RXP','CS','AS','AS11','ESI11','ESI02','EP02'])">
                      <v-col cols="auto" xs="12" sm="12" md="5">
                        <div class="h5">Drug Requested Questionnaire:</div>
                      </v-col>
                      <v-col cols="auto" xs="12" sm="12" md="7">
                        <recursive-tree
                          v-for="(field, index) in drugRequestedSub"
                          :key="index"
                          :questionLabel="field.questionLabel"
                          :nodes="field.nodes"
                          :inputField="field.inputField"
                          :model="field.model"
                          :componentParams="field.componentParams"
                          :expectedValue="field.expectedValue"
                          :previousValue="field.previousValue"
                          :rules="field.rules"
                          :hasInsurance="field.hasInsurance"
                          :depth="0"
                          :items="field.items"
                        >
                        </recursive-tree>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dialogInfo = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-col cols="auto" xs="12" sm="12" md="9">
        <v-row v-if="specificInsurance(['ESI12','EP12','NEX','NX','GS','SSQ','SQ','AS16','ESI','EP','BC','OB','RXP','CS','AS','ESI11','ESI02','EP02','AS11','ODB','DB'])">
          <recursive-tree-multiple
            v-for="(field, index) in drugRequestedMain"
            :key="index"
            :questionLabel="field.questionLabel"
            :nodes="field.nodes"
            :inputField="field.inputField"
            :model="field.model"
            :componentParams="field.componentParams"
            :expectedValue="field.expectedValue"
            :previousValue="field.previousValue"
            :rules="field.rules"
            :hasInsurance="field.hasInsurance"
            :depth="0"
            :items="field.items"
          >
          </recursive-tree-multiple>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { drugRequestedMain } from "./praluentData/drugRequestedMain";
import { drugRequestedSub } from "./praluentData/drugRequestedSub";
import Section from "../../Layout/Section/Section";
import RecursiveTree from "@/components/common/ui/Layout/RecursiveTree/RecursiveTree";
import RecursiveTreeMultiple from "@/components/common/ui/Layout/RecursiveTree/RecursiveTreeMultiple";

export default {
  components: {
    "pg-section": Section,
    "recursive-tree": RecursiveTree,
    "recursive-tree-multiple": RecursiveTreeMultiple,
  },
  data() {
    return {
      dialogInfo: false,
      filteredArr: null,
      drugRequestedMain: drugRequestedMain,
      drugRequestedSub: drugRequestedSub,
    };
  },
  computed: {
    ...mapGetters([
      "TrackProcess"
    ]),
    drugDose:{
      get(){
        return this.$store.getters.TrackProcess.praluentPatient['DRUG DOSE']
      }
    }
  },
  watch:{
    drugDose(newVal){
      if(newVal === '75mg'){
        this.$store.commit("SET_PRALUENT_SINGLE_STATE", {
          key: `DIN`,
          value: '02453819',
        });
      }else if(newVal === '150mg'){
        this.$store.commit("SET_PRALUENT_SINGLE_STATE", {
        key: `DIN`,
        value: '02453835',
      });
      }
    },
  },
  methods: {
    specificInsurance(insuranceCompanies) {
      let flag = false;
      let privateInsurance;
      if(Object.entries(this.$store.getters.TrackProcess.praluentPatient).length === 0 ){
        return flag;
      }else{
        if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb' && this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider.length < 2){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        else if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb'){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[1]
        }
        else{
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        insuranceCompanies.forEach(insuranceCompany => {
          if(privateInsurance.apikey === 'odb'){
            flag = true;
          }
          if (privateInsurance.code === insuranceCompany) {
            flag = true;
          }
        });
        return flag;
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <v-btn
      type="submit"
      :disabled="disabled"
      color="primary"
      large
      block
      @click.native="submit"
    >
      <slot></slot>
      <v-icon
        right
        dark
      >
        cloud_upload
      </v-icon>
    </v-btn>
    <v-alert
      v-if="status.text"
      :value="status.text"
      :type="status.type"
    >
      {{ status.text }}
    </v-alert>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">
          Submitting...
        </v-card-title>
        <v-card-text class="text-xs-center">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { userService } from '@/render/api/zarya'

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        // formType: {
        //   required: true,
        //   type: String
        // },
        baseFilename: {
            require: true,
            type: String,
        },
        patientData: {
            required: true,
            type: Object,
        },
        typeOfMedscheck: {
            required: false,
            type: String,
        },
        patientDataDecorator: {
            require: false,
            type: Function,
        },
    },
    data() {
        return {
            dialog: false,
            submittedPatientData: this.patientData,
            status: {
                type: '',
                text: '',
            },
        }
    },
    created() {
        this.$store.subscribeAction((action, state) => {
            switch (action.type) {
            case 'submitPatientData':
                this.dialog = true
                break
            case 'pdfProcessSuccess':
                this.dialog = false
                this.status = {
                    type: 'success',
                    success: 'Successful submission',
                }
                break
            case 'pdfProcessFail':
                this.dialog = false
                this.status = {
                    type: 'error',
                    success: 'Failed to submit',
                }
                break
            }
        })
    },
    methods: {
        async submit() {
            // will go through this if statement if there is additional work being done on submit
            // NOTE: this is for medscheck
            if (this.patientDataDecorator) {
                this.submittedPatientData = this.patientDataDecorator(this.patientData)
                this.dialog = true
                await this.$store
                    .dispatch('pdfSubmitData', {
                        filename: `${this.baseFilename}-${this.submittedPatientData['LAST NAME']}`,
                        formType: this.$store.getters.TrackProcess.praluentPatient.formName,
                        pdfData: this.submittedPatientData,
                    })
                    .then(
                        async pdfRef => {
                            this.dialog = false
                            this.status = {
                                type: 'success',
                                success: 'Successful submission',
                            }
                            const token = await this.$auth.getTokenSilently()
                            this.$store.dispatch('pdfDownload', {
                                token,
                                id: pdfRef.id,
                                filename: `${pdfRef.filename.toLowerCase()}`,
                            })
                            await this.$emit('postaction', this.submittedPatientData)
                            window.scrollTo(0, 0)
                            this.savePdfInfo(pdfRef)
                        },
                        err => {
                            // FIXME finally isn't kicking in
                            this.dialog = false
                            this.status = {
                                type: 'error',
                                success: err,
                            }
                            window.scrollTo(0, 0)
                        },
                    )
            } else {
                const medData = this.$store.getters.GET_PRALUENT_PATIENT_DATA_WITH_MEDICATION_TABLE
                this.$store.commit('SET_PRALUENT_OVERLAY_DATA', medData)

                // NOTE:This will run for praluent
                await this.$emit('preaction', this.patientData)

                // if no addditional work on submit is needed, will follow through with this
                this.dialog = true
                await this.$store
                    .dispatch('pdfSubmitData', {
                        filename: `${this.baseFilename}-${this.$store.getters.TrackProcess.praluentPatient['LAST NAME']}`,
                        formType: this.$store.getters.TrackProcess.praluentPatient.formName,
                        pdfData: this.$store.getters.TrackProcess.praluentPatient,
                    })
                    .then(
                        async pdfRef => {
                            this.dialog = false
                            this.status = {
                                type: 'success',
                                success: 'Successful submission',
                            }
                            const token = await this.$auth.getTokenSilently()

                            if (this.$store.getters.TrackProcess.praluentPatient.PatientID) {
                                const token = await this.$auth.getTokenSilently()
                                const id = this.$store.getters.UserProfile._id

                                // token, points, id
                                await userService.addPoints(token, 10, id)
                            }
                            this.$store.dispatch('pdfDownload', {
                                token,
                                id: pdfRef.id,
                                filename: `${pdfRef.filename.toLowerCase()}`,
                            })
                            await this.$emit('postaction', this.patientData)
                            window.scrollTo(0, 0)
                            this.savePdfInfo(pdfRef)
                        },
                        err => {
                            // FIXME finally isn't kicking in
                            this.dialog = false
                            this.status = {
                                type: 'error',
                                success: err,
                            }
                            window.scrollTo(0, 0)
                        },
                    )
            }
        },
        savePdfInfo(pdfRef) {
            this.$emit('saveInfo', pdfRef)
        },
    },
}
</script>
<style scoped>
</style>

export default {
    candidates: {
        fillware: {
            patientsOnReptha:
                `
                WITH summary AS (
                    SELECT 
                    Rx.PatientID,
                    Patient.FirstName AS FirstName,
                    Patient.LastName AS LastName,
                    CAST (Rx.RxDate as date) as RxDate,
                    Rx.Days,
                     ROW_NUMBER() OVER(PARTITION BY Rx.PatientID 
                                                 ORDER BY Rx.RxDate DESC) AS rk
                FROM Fillware.dbo.Rx as Rx
                    INNER JOIN
                        Fillware.dbo.DrugRoot AS DrugRoot ON DrugRoot.DIN = Rx.DIN
                    INNER JOIN 
                        Fillware.dbo.Patient AS Patient ON Rx.PatientID = Patient.PatientID
                WHERE
                    DrugRoot.GenericName = 'ALIROCUMAB'
                    AND  
                    Rx.RxDate > DATEADD(month, -6, GetDate()) 
                    AND
                    ( Rx.Status <> 'D' OR Rx.Status <> 'I' OR Rx.Status is null )
                GROUP BY 
                    Rx.PatientID,
                    Patient.FirstName,
                    Patient.LastName,
                    Rx.RxDate,
                    Rx.Days
                )
                SELECT s.*
                  FROM summary s
                 WHERE s.rk = 1
                
                    `
            ,
//             patientsOnEZ:  // And not on Repatha 
//                 `
                   
// SELECT
// Rx.PatientID
// FROM Fillware.dbo.Rx as Rx
// INNER JOIN Fillware.dbo.DrugRoot AS DrugRoot ON DrugRoot.DIN = Rx.DIN
// INNER JOIN Fillware.dbo.Patient AS Patient ON Rx.PatientID IN(   
// SELECT
//     Rx.PatientID
// FROM Fillware.dbo.Rx as Rx
//     INNER JOIN Fillware.dbo.DrugRoot AS DrugRoot ON DrugRoot.DIN = Rx.DIN
// WHERE
//                         CONCAT(DrugRoot.GenericName, '-',DrugRoot.Strength) = 'EZETIMIBE-10MG'
//     AND
//     ( Rx.Status <> 'D' OR Rx.Status <> 'I' OR Rx.Status is null ) AND Rx.RxDate > DATEADD(month, -6, GetDate())
// GROUP BY Rx.PatientID
// )

// WHERE
// CONCAT
// (DrugRoot.GenericName, '-',DrugRoot.Strength) IN
// (
//                     'ROSUVASTATIN CALCIUM-40MG',
//                     'ATORVASTATIN CALCIUM-80MG',
//                     'FLUVASTATIN-40MG',
//                     'LOVASTATIN-40MG',
//                     'PRAVASTATIN-40MG',
//                     'SIMVASTATIN-80MG'
//                 ) AND
// ( Rx.Status <> 'D' OR Rx.Status <> 'I' OR Rx.Status is null ) AND Rx.RxDate > DATEADD
// (month, -6, GetDate
// ())
// GROUP BY Rx.PatientID
//                     `
//             ,
            patientsOnStatins:  // and not on repatha but on EZETIMIBE-10MG
                `
                    
SELECT
Rx.PatientID
FROM Fillware.dbo.Rx as Rx
INNER JOIN Fillware.dbo.DrugRoot AS DrugRoot ON DrugRoot.DIN = Rx.DIN
INNER JOIN Fillware.dbo.Patient AS Patient ON Rx.PatientID IN(   
SELECT
    Rx.PatientID
FROM Fillware.dbo.Rx as Rx
    INNER JOIN Fillware.dbo.DrugRoot AS DrugRoot ON DrugRoot.DIN = Rx.DIN
WHERE
                        CONCAT(DrugRoot.GenericName, '-',DrugRoot.Strength) = 'EZETIMIBE-10MG'
    AND
    ( Rx.Status <> 'D' OR Rx.Status <> 'I' OR Rx.Status is null ) AND Rx.RxDate > DATEADD(month, -6, GetDate())
GROUP BY Rx.PatientID
)

WHERE
CONCAT
(DrugRoot.GenericName, '-',DrugRoot.Strength) IN
(
                    'ROSUVASTATIN CALCIUM-40MG',
                    'ATORVASTATIN CALCIUM-80MG',
                    'FLUVASTATIN-40MG',
                    'LOVASTATIN-40MG',
                    'PRAVASTATIN-40MG',
                    'SIMVASTATIN-80MG'
                ) AND
( Rx.Status <> 'D' OR Rx.Status <> 'I' OR Rx.Status is null ) AND Rx.RxDate > DATEADD
(month, -6, GetDate
())
GROUP BY Rx.PatientID
                    `
            ,
            fullHistoryPatientsOnStatins: // and not on repatha but on EZETIMIBE-10MG
                `
                    SELECT DISTINCT
                        Rx.PatientID AS PatientID,
                        Rx.RxDate as RxDate,
                        CONCAT(DrugRoot.GenericName, '-',DrugRoot.Strength, ',', 'EZETIMIBE-10MG') AS Medication
                    FROM [Fillware].[dbo].[Rx] AS Rx
                    INNER JOIN Fillware.dbo.Patient AS Patient ON Rx.PatientID IN($PatientIDs)
                    INNER JOIN Fillware.dbo.DrugRoot AS DrugRoot ON DrugRoot.DIN = Rx.DIN
                    WHERE CONCAT(DrugRoot.GenericName, '-',DrugRoot.Strength) IN (
                        'ROSUVASTATIN CALCIUM-40MG',
                        'ATORVASTATIN CALCIUM-80MG',
                        'FLUVASTATIN-40MG',
                        'LOVASTATIN-40MG',
                        'PRAVASTATIN-40MG',
                        'SIMVASTATIN-80MG'
                    ) AND ( Rx.Status <> 'D' OR Rx.Status <> 'I' OR Rx.Status is null ) AND Rx.RxDate > DATEADD(month, -4, GetDate())
                    GROUP BY 
                        Rx.PatientID,
                        DrugRoot.GenericName,
                        DrugRoot.Strength,
                        Rx.RxDate

                    ORDER BY PatientID DESC
                    `,
            fullHistoryPatientsOnEZ: // And not on Repatha
                `
                        SELECT 
                            Rx.PatientID
                        FROM Fillware.dbo.Rx as Rx
                            INNER JOIN Fillware.dbo.DrugRoot AS DrugRoot ON DrugRoot.DIN = Rx.DIN
                        WHERE
                            CONCAT(DrugRoot.GenericName, '-',DrugRoot.Strength) = 'EZETIMIBE-10MG'
                        AND
                            Rx.PatientID NOT IN ($PatientIDs) 
                        GROUP BY Rx.PatientID
                    `,
            patientsInfo:
                `
                    SELECT
                    Patient.PatientID AS PatientID,
                        Patient.FirstName AS FirstName,
                        Patient.LastName AS LastName,
                        Patient.OHIP,
                        Plans.Name as InsuranceProvider,
                        LTRIM(RTRIM(PatientPlans.PlanCode)) as PlanCode,
                        PatientPlans.CarrierID as CarrierID,
                        PatientPlans.GroupID as GroupID,
                        PatientPlans.PlanID as PlanID,
                        PatientPlans.RelationShip as Relationship,
                        PatientPlans.PatientCode as PatientCode,
                        PatientPlans.PlanOrder as PlanOrder 
                    FROM Fillware.dbo.Patient as Patient
                    LEFT JOIN Fillware.dbo.PatientPlans as PatientPlans ON PatientPlans.PatientID = Patient.PatientID
                    LEFT JOIN Fillware.dbo.Plans as Plans ON Plans.Code = PatientPlans.PlanCode
                    WHERE
                    Patient.PatientID IN($PatientIDs)
                    ORDER BY Patient.PatientID, PatientPlans.PlanOrder ASC
                `
        },
        nexxsys: {
            patientsOnReptha:
                `
                WITH summary AS (
                    SELECT 
                    "DBA"."Prescription"."PatientId" AS PatientID,
                    "DBA"."Patient"."FirstName" AS FirstName,
                    "DBA"."Patient"."LastName" AS LastName,
                    CAST ("DBA"."Prescription"."FillDate" as date) AS RxDate,
                    "DBA"."Prescription"."DaysSupply" AS Days,
                         ROW_NUMBER() OVER(PARTITION BY "DBA"."Prescription"."PatientId"
                                                     ORDER BY "DBA"."Prescription"."FillDate" DESC) AS rk
                    FROM (
                        "DBA"."Prescription"
                        JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
                    )
                    JOIN "DBA"."Patient" ON "DBA"."Prescription"."PatientId" = "DBA"."Patient"."Id"
                    WHERE "DBA"."Drug"."GenericName" = 'ALIROCUMAB'
                    AND Prescription.FillDate > DATEADD(month, -6, GetDate())
                    AND "DBA"."Prescription"."Status" <> 'HOL'
                    GROUP BY 
                    "DBA"."Prescription"."PatientId",
                    "DBA"."Patient"."FirstName",
                    "DBA"."Patient"."LastName",
                    "DBA"."Prescription"."FillDate",
                    "DBA"."Prescription"."DaysSupply"
                    )
                    SELECT s.*
                      FROM summary s
                     WHERE s.rk = 1
                `
            ,
            patientsOnEZ:  // And not on Repatha 
                `
                SELECT "DBA"."Prescription"."PatientId" AS PatientID
FROM (
        "DBA"."Prescription"
        JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
    )
    JOIN "DBA"."Patient" ON "DBA"."Prescription"."PatientId" IN(
        SELECT "DBA"."Prescription"."PatientId"
        FROM (
                "DBA"."Prescription"
                JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
            )
        WHERE Drug.GenericName = 'EZETIMIBE'
//            WHERE ("DBA"."Prescription"."Status" <> 'HOL')
            AND Prescription.FillDate > DATEADD(month, -6, GetDate())
        GROUP BY "DBA"."Prescription"."PatientId"
    )
WHERE 
STRING(Drug.GenericName, '-', RTRIM(DBA.Drug.Strength)) IN (
                    'ROSUVASTATIN-40MG',
'ROSUVASTATIN-20MG',
                    'ATORVASTATIN-80MG',
 'ATORVASTATIN-40MG',
                    'FLUVASTATIN-40MG',
                    'LOVASTATIN-40MG',
                    'PRAVASTATIN-40MG',
                    'SIMVASTATIN-80MG'
//'SIMVASTATIN-40MG'
                )
    
//    AND "DBA"."Prescription"."Status" <> 'HOL'
    AND Prescription.FillDate > DATEADD(month, -6, GetDate())
GROUP BY "DBA"."Prescription"."PatientId"
                `
            ,
            patientsOnStatins:  // and not on repatha but on EZETIMIBE-10MG
                `
                SELECT "DBA"."Prescription"."PatientId" AS PatientID
FROM (
        "DBA"."Prescription"
        JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
    )
    JOIN "DBA"."Patient" ON "DBA"."Prescription"."PatientId" IN(
        SELECT "DBA"."Prescription"."PatientId"
        FROM (
                "DBA"."Prescription"
                JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
            )
        WHERE Drug.GenericName = 'EZETIMIBE'
//            WHERE ("DBA"."Prescription"."Status" <> 'HOL')
            AND Prescription.FillDate > DATEADD(month, -6, GetDate())
        GROUP BY "DBA"."Prescription"."PatientId"
    )
WHERE 
STRING(Drug.GenericName, '-', RTRIM(DBA.Drug.Strength)) IN (
                    'ROSUVASTATIN-40MG',
'ROSUVASTATIN-20MG',
                    'ATORVASTATIN-80MG',
 'ATORVASTATIN-40MG',
                    'FLUVASTATIN-40MG',
                    'LOVASTATIN-40MG',
                    'PRAVASTATIN-40MG',
                    'SIMVASTATIN-80MG'
//'SIMVASTATIN-40MG'
                )
    
//    AND "DBA"."Prescription"."Status" <> 'HOL'
    AND Prescription.FillDate > DATEADD(month, -6, GetDate())
GROUP BY "DBA"."Prescription"."PatientId"
                `
            ,
            fullHistoryPatientsOnStatins: // and not on repatha but on EZETIMIBE-10MG
                `
                SELECT "DBA"."Prescription"."PatientId" AS PatientID
                FROM (
                        "DBA"."Prescription"
                        JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
                    )
                    JOIN "DBA"."Patient" ON "DBA"."Prescription"."PatientId" IN(
                        SELECT "DBA"."Prescription"."PatientId"
                        FROM (
                                "DBA"."Prescription"
                                JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
                            )
                        WHERE Drug.GenericName = 'EZETIMIBE'
                            AND ("DBA"."Prescription"."Status" <> 'HOL')
                            AND Prescription.FillDate > DATEADD(month, -6, GetDate())
                        GROUP BY "DBA"."Prescription"."PatientId"
                    )
                WHERE STRING(Drug.GenericName, '-', RTRIM(DBA.Drug.Strength)) IN (
                        'ROSUVASTATIN-40MG',
                        'ATORVASTATIN-80MG',
                        'FLUVASTATIN-40MG',
                        'LOVASTATIN-40MG',
                        'PRAVASTATIN-40MG',
                        'SIMVASTATIN-80MG'
                    )
                    AND "DBA"."Prescription"."Status" <> 'HOL'
                    AND Prescription.FillDate > DATEADD(month, -6, GetDate())
                GROUP BY "DBA"."Prescription"."PatientId"
                `,
            fullHistoryPatientsOnEZ: // And not on Repatha
                `
                SELECT 
                "DBA"."Prescription"."PatientId" AS PatientID
            FROM (
                    "DBA"."Prescription"
                    JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
                )
                JOIN "DBA"."Patient" ON "DBA"."Prescription"."PatientId" = "DBA"."Patient"."Id" IN($PatientIDs)
            WHERE STRING(Drug.GenericName, '-', Drug.Strength) = 'EZETIMIBE-10MG      '
                AND Prescription.PatientId NOT IN ($PatientIDs)
                AND Prescription.FillDate > DATEADD(month, -1, GetDate())
            GROUP BY "DBA"."Prescription"."PatientId"
                `,
            patientsInfo:
                `
                SELECT "DBA"."Patient"."Id" AS PatientID,
                "DBA"."Patient"."FirstName" AS FirstName,
                "DBA"."Patient"."LastName" AS LastName,
                "DBA"."Patient"."ProvincialHealthNumber" AS OHIP,
                "DBA"."Subscriber"."Sequence",
                "DBA"."Subscriber"."Relationship" as Relationship,
                "DBA"."Subscriber"."SubscriptionId",
                "DBA"."Subscriber"."Rank" as PlanOrder,
                "DBA"."Subscription"."CarrierId" as CarrierID,
                "DBA"."Subscription"."GroupId" as GroupID,
                "DBA"."Subscription"."ClientId" as PatientCode,
                "DBA"."ThirdParty"."Name" AS InsuranceProvider,
                "DBA"."thirdpartydefaultvalues"."billingcode" as PlanCode,
                "DBA"."thirdpartydefaultvalues"."billingcode" as PlanID
                FROM ( ( ( "DBA"."Patient" JOIN "DBA"."Subscriber" ON "DBA"."Patient"."Id" = "DBA"."Subscriber"."PatientId" ) JOIN "DBA"."Subscription" ON "DBA"."Subscriber"."SubscriptionId" = "DBA"."Subscription"."SubscriptionId" ) INNER JOIN "DBA"."ThirdParty" ON "DBA"."Subscriber"."Plan_ThirdPartyId" = "DBA"."ThirdParty"."Id" ) INNER JOIN "DBA"."thirdpartydefaultvalues" ON STRING("DBA"."thirdpartydefaultvalues"."thirdpartyid","DBA"."thirdpartydefaultvalues"."planid") = STRING("DBA"."Subscription"."Plan_ThirdPartyId","DBA"."Subscription"."PlanId")
                WHERE "DBA"."Patient"."Id" IN($PatientIDs)
                GROUP BY ( "DBA"."Patient"."Id", "DBA"."Patient"."ProvincialHealthNumber", "DBA"."Patient"."FirstName", "DBA"."Patient"."LastName", "DBA"."Subscriber"."PatientId", "DBA"."Subscriber"."Sequence", "DBA"."Subscriber"."Relationship", "DBA"."Subscriber"."SubscriptionId", "DBA"."Subscriber"."Rank", "DBA"."Subscription"."CarrierId", "DBA"."Subscription"."GroupId", "DBA"."Subscription"."ClientId", "DBA"."ThirdParty"."Name", "DBA"."thirdpartydefaultvalues"."billingcode" )
                ORDER BY "DBA"."Patient"."Id", "DBA"."Subscriber"."Rank" ASC    
            `
        },
        kroll: {
            patientsOnReptha:
                `
                SELECT 
                Rx.PatID as PatientID,
                Patient.FirstName AS FirstName,
                Patient.LastName AS LastName,
                Rx.FillDate as RxDate,
                Rx.DaysSupply AS Days
            FROM Pharmacy.dbo.Rx
                INNER JOIN
                 Pharmacy.dbo.Drg as drug ON Pharmacy.dbo.Rx.DrgID = drug.ID
                INNER JOIN 
                    Pharmacy.dbo.Pat AS Patient ON Pharmacy.dbo.Rx.PatID = Patient.ID
            WHERE
                drug.GenericName LIKE '%Alircocumab%' or drug.GenericName LIKE '%evolocumab%'
                AND  Rx.FillDate > DATEADD(month, -12, GetDate())
            ORDER BY Rx.FillDate DESC
                    `
            ,
            patientsOnEZ:  // And not on Repatha 
                `
                SELECT 
                Rx.PatID as PatientID
                 FROM Pharmacy.dbo.Rx as Rx
                INNER JOIN
                    Pharmacy.dbo.Drg as drug ON Rx.DrgID = drug.ID
                WHERE
                CONCAT(drug.GenericName, '-',drug.Strength) = 'Ezetimibe-10mg'
               AND  Rx.FillDate > DATEADD(month, -12, GetDate())
                GROUP BY Rx.PatID
                    `
            ,
            patientsOnStatins:  // and not on repatha but on EZETIMIBE-10MG
                `
                SELECT DISTINCT
                Rx.PatID as PatientID,
                Rx.FillDate as RxDate,
                CONCAT(drug.GenericName, '-',drug.Strength, ',', 'Ezetimibe-10mg') AS Medication
            FROM Pharmacy.dbo.Rx
            INNER JOIN Pharmacy.dbo.Pat AS Patient ON Pharmacy.dbo.Rx.PatID IN ($PatientIDs) 
            INNER JOIN Pharmacy.dbo.Drg as drug ON Pharmacy.dbo.Rx.DrgID = drug.ID
            WHERE CONCAT(drug.GenericName, '-',drug.Strength) IN (
                'Rosuvastatin Calcium-40mg',
                'Atorvastatin Calcium-80mg',
                'Simvastatin-80mg',
                'Fluvastatin Sodium-40mg',
                'Lovastatin-40mg',
                'Pravastatin Sodium-40mg'
            )
            GROUP BY 
                Rx.PatID ,
                drug.GenericName,
                drug.Strength,
                Rx.FillDate
            ORDER BY PatientID DESC
                    `
            ,
            fullHistoryPatientsOnStatins: // and not on repatha but on EZETIMIBE-10MG
                `
                SELECT DISTINCT
                Rx.PatID as PatientID,
                Rx.FillDate as RxDate,
                CONCAT(drug.GenericName, '-',drug.Strength, ',', 'Ezetimibe-10mg') AS Medication
            FROM Pharmacy.dbo.Rx
            INNER JOIN Pharmacy.dbo.Pat AS Patient ON Pharmacy.dbo.Rx.PatID IN($PatientIDs) 
            INNER JOIN Pharmacy.dbo.Drg as drug ON Pharmacy.dbo.Rx.DrgID = drug.ID
            WHERE CONCAT(drug.GenericName, '-',drug.Strength) IN (
                'Rosuvastatin Calcium-40mg',
                'Atorvastatin Calcium-80mg',
                'Simvastatin-80mg',
                'Fluvastatin Sodium-40mg',
                'Lovastatin-40mg',
                'Pravastatin Sodium-40mg'
            )
            GROUP BY 
                Rx.PatID ,
                drug.GenericName,
                drug.Strength,
                Rx.FillDate
            ORDER BY PatientID DESC
                    `,
            fullHistoryPatientsOnEZ: // And not on Repatha
                `
                SELECT 
                Rx.PatID as PatientID
             FROM Pharmacy.dbo.Rx as Rx
INNER JOIN Pharmacy.dbo.Drg as drug ON Rx.DrgID = drug.ID
            WHERE
                CONCAT(drug.GenericName, '-',drug.Strength) = 'Ezetimibe-10mg'
            AND
                Rx.PatID NOT IN ($PatientIDs)
            GROUP BY Rx.PatID
                    `,
            patientsInfo:
                `
                SELECT
                patient.ID as PatientID,
                    Patient.FirstName AS FirstName,
                    Patient.LastName AS LastName,
                    -- Patient.OHIP,
                    Plans.Description as InsuranceProvider,
                    PatientPlans.CarrierID as CarrierID,
                    PatientPlans.GroupID as GroupID,
                    PlnSub.SubPlanCode as PlanID,
                    PlnSub.SubPlanCode as PlanCode,
                    PatientPlans.Rel as Relationship,
                    PatientPlans.ClientID as PatientCode,
                    PatientPlans.Sequence as PlanOrder 
                FROM Pharmacy.dbo.Pat as patient
                LEFT JOIN Pharmacy.dbo.PatPln as PatientPlans ON PatientPlans.PatID = patient.ID
                LEFT JOIN Pharmacy.dbo.PlnSub as PlnSub ON PlnSub.ID = PatientPlans.SubPlanID
                LEFT JOIN Pharmacy.dbo.Pln as Plans ON Plans.ID = PlnSub.PlanID
                WHERE patient.ID IN($PatientIDs)
                ORDER BY patient.ID, PatientPlans.Sequence ASC
                `
        }
    }
}
<template>
  <!-- <div class="ml-4"> -->
  <v-container fluid>
    <v-row>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <v-row class="mb-3">
          <h2>Member Information:</h2>
        </v-row>
        <v-row v-if="!specificInsurance(['RXP','CS','GS','AS','AS11','SSQ','SQ'])">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="#B30000"
              class="white--text"
              min-width="200px"
                v-bind="attrs"
                v-on="on"
                @click="dialogInfo = true"
              >
                Additional Info
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="ml-3"
                >
                  {{

                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{

              }}
            </span>
            <v-dialog v-model="dialogInfo" max-width="900px">
              <v-card>
                <v-card-title>
                  <span class="h4">Additional Member Info</span>
                </v-card-title>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-container>
                      <v-row v-if="specificInsurance(['ESI12','EP12','NEX','NX','AS16','ESI','EP','BC','OB','ESI02','EP02','ESI11'])">
                        <v-col cols="auto" xs="12" sm="12" md="3">
                          <div class="h5">Member Questionnaire:</div>
                        </v-col>
                        <v-col cols="auto" xs="12" sm="12" md="9">
                          <recursive-tree
                            v-for="(field, index) in memberRelationship"
                            :key="index"
                            :questionLabel="field.questionLabel"
                            :nodes="field.nodes"
                            :inputField="field.inputField"
                            :model="field.model"
                            :componentParams="field.componentParams"
                            :expectedValue="field.expectedValue"
                            :previousValue="field.previousValue"
                            :rules="field.rules"
                            :hasInsurance="field.hasInsurance"
                            :depth="0"
                          >
                          </recursive-tree>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="dialogInfo = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tooltip>
        </v-row>
      </v-col>

      <v-col cols="auto" xs="12" sm="12" md="9">
        <v-row v-if="specificInsurance(['ESI12','EP12','NEX','NX','GS','SSQ','SQ','AS16','ESI02','EP02','ESI','EP','BC','OB','RXP','CS','AS11','AS','ESI11'])">
          <recursive-tree-multiple
            v-for="(field, index) in memberInfoMain"
            :key="index"
            :questionLabel="field.questionLabel"
            :nodes="field.nodes"
            :inputField="field.inputField"
            :model="field.model"
            :componentParams="field.componentParams"
            :expectedValue="field.expectedValue"
            :previousValue="field.previousValue"
            :rules="field.rules"
            :hasInsurance="field.hasInsurance"
            :depth="0"
          >
          </recursive-tree-multiple>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecursiveTree from "@/components/common/ui/Layout/RecursiveTree/RecursiveTree";
import RecursiveTreeMultiple from "@/components/common/ui/Layout/RecursiveTree/RecursiveTreeMultiple";
import { mapGetters } from "vuex";

import { memberInfoMain } from "./praluentData/memberInfoMain";
import { memberRelationship } from "./praluentData/memberInfoSub";

export default {
  components: {
    "recursive-tree": RecursiveTree,
    "recursive-tree-multiple": RecursiveTreeMultiple,
  },
  name: "MemberInfo",
  data() {
    return {
      filteredArr: null,
      dialogInfo: false,
      memberInfoMain: memberInfoMain,
      memberRelationship: memberRelationship,
    };
  },
  computed: {
    ...mapGetters([
      "TrackProcess"
    ]),
    //   valid:function(){
    //   this.filter();
    //   if(this.MEMBERFIRSTNAME === ""){
    //     return 'required';
    //   }else if(this.MEMBERFIRSTNAME.length > 0 && this.filteredArr === false){
    //     return 'optional';
    //   }else{
    //     return 'valid';
    //   }
    // }
  },
  methods: {
    specificInsurance(insuranceCompanies) {
      let flag = false;
      let privateInsurance;
      if(Object.entries(this.$store.getters.TrackProcess.praluentPatient).length === 0 ){
        return flag;
      }else{
        if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb' && this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider.length < 2){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        else if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb'){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[1]
        }
        else{
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        insuranceCompanies.forEach(insuranceCompany => {
          if(privateInsurance.apikey === 'odb'){
            flag = true;
          }
          if (privateInsurance.code === insuranceCompany) {
            flag = true;
          }
        });
        return flag;
      }
    },
  },
};
</script>

<style scoped>

</style>
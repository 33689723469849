<template>
    <div>
        <!-- <v-btn color="primary" class="lighten-1" @click.native="loadStateInfo">
            <v-icon class="pr-2">folder_open</v-icon>
            load {{getFormClass}}<small class="px-3" v-show="lastDateSaveTime">(  {{lastDateSaveTime}}  )</small>
        </v-btn>  -->
        <!-- <v-btn color="primary" @click.native="savePatientInfo">
            <v-icon class="pr-2">save</v-icon>
            save {{getFormClass}}
        </v-btn> -->
        <v-btn color="primary" @click.native="saveStateInfo">
            <v-icon class="pr-2" id="v-step-5">save</v-icon>
            Save Progress
        </v-btn>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="1000">{{snackbar.text}}</v-snackbar>
    </div>
</template>

<script>
import { stateService } from '@/render/api/zarya'
import { mapGetters } from 'vuex'

export default {
  props: ['patientProfile', 'stateStatus'],
  data() {
    return {
      personalInfo: this.patientProfile,
      lastDateSaveTime: '',
      // stateID: this.patientProfile.stateId,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    }
  },
  computed: {
    ...mapGetters(['FormClass', 'PatientProfile', 'UserProfile', 'PharmacyProfile', 'TrackProcess', 'PraluentPatient']),
    getFormClass: function () {
      return this.FormClass
    },
  },
  methods: {
    savePatientInfo() {
      // save file
      this.$store.dispatch('saveFilePatientData')
      this.lastDateSaveTime = this.$store.state.Patient.when
    },
    async saveStateInfo() {
      // FIXME remove sensitive customer information
      // this.saveCurrentDate();
      let promise
      let data = {
        formType: this.$store.getters.TrackProcess.formClass,
        state: {
          ...this.$store.getters.TrackProcess,
          lastSavedDate: this.$moment().format('YYYY/MM/DD'),
        },
        patientID: this.$store.getters.PraluentPatient.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }
      const token = await this.$auth.getTokenSilently()
      if (this.personalInfo.praluentPatient.stateId) {
        // update it
        promise = stateService.updateState(token, this.$store.getters.TrackProcess.praluentPatient.stateId, {
          ...data,
          status: this.stateStatus || 'in-progress',
        })
      } else {
        // create it
        promise = stateService.createState(token, data)
      }
      return promise.then(this.saveResponseSuccess, this.saveResponseReject)
    },
    // loadStateInfo() {
    //   if(this.stateID){
    //     return stateService.getStateById(this.stateID).then((state) => {
    //       this.showMsg('Successfully loaded your form');
    //       this.$emit('onload', state);
    //     })
    //   }
    // },
    saveResponseSuccess(res) {
      this.showMsg('Successfully saved your form')
      this.$store.getters.TrackProcess.praluentPatient.stateId = res.state._id
      // this.stateID = res.state._id;
    },
    saveResponseReject(res) {
      this.showMsg('Failed to saved your form', 'error')
      throw new Error(res)
    },
    showMsg(text, color = 'success') {
      this.snackbar.show = true
      this.snackbar.text = text
      this.snackbar.color = color
    },
    // loadPatientInfo(){
    //   this.$store.dispatch("loadFilePatientData").then( (state) => {
    //     this.$emit('onload', state);
    //   });
    // }
  },
}
</script>

<style scoped>
</style>

const assessmentGeneral = [
    {
        questionLabel:"The patient has severe renal impairment",
        inputField: "radio",
        model: "GENERAL RENAL IMPAREMENT",
        hasInsurance: ['GS'],
        componentParams: [
            {
                label: "Yes",
                value:"generalYes0"
            }, 
            {
                label: "No",
                value:"NO"
            }, 
        ],
    },
    {
        questionLabel:"The patient has sever hepatic impairment",
        inputField: "radio",
        model: "GENERAL HEPATIC IMPAREMENT",
        hasInsurance: ['GS'],
        componentParams: [
            {
                label: "Yes",
                value:"generalYes1"
            }, 
            {
                label: "No",
                value:"NO"
            }, 
        ],
    },
    {
        questionLabel:"The patient is diagnosed with Familial Hypercholesterolemia",
        inputField: "radio",
        model: "GENERAL DIAGNOSE HYPER",
        childrenModels:['GENERAL DIAGNOSE HYPER TYPE'],
        hasInsurance: ['GS'],
        componentParams: [
            {
                label: "Yes",
                value:"generalYes2"
            }, 
            {
                label: "No",
                value:"NO"
            }, 
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"generalYes2",
                model: "GENERAL DIAGNOSE HYPER TYPE",
                hasInsurance:['GS'],
                componentParams: 
                    {
                        label: "Type",
                    }
            }
        ]
    },
    {
        questionLabel:"Has the patient attained LDL-C targets following treatment with 2 or more statins (one being a high-intensity statin at the maximum tolerate dose1) in combination with ezetimibe for ≥4 months, despite optimal compliance?",
        inputField: "radio",
        model: "GENERAL MULTI STATIN",
        childrenModels:['GENERAL MULTI STATIN TARGET'],
        hasInsurance: ['GS'],
        componentParams: [
            {
                label: "Yes",
                value:"generalYes3"
            }, 
            {
                label: "No",
                value:"NO"
            }, 
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"NO",
                model: "GENERAL MULTI STATIN TARGET",
                hasInsurance:['GS'],
                componentParams: 
                    {
                        label: "LDL-C Target",
                    }
            }
        ]
    },
    {
        questionLabel:"Specify the following information",
        inputField: "radio",
        model: "GENERAL FRAM CARDIO",
        childrenModels:[
            'GENERAL FRAM CARDIO SCORE',
            'GENERAL FRAM CARDIO RISK'
        ],
        hasInsurance: ['SSQ','SQ'],
        componentParams: [
            {
                label: "Framingham Score",
                value:"SCORE"
            }, 
            {
                label: "Cardiovascular Risk",
                value:"RISK"
            }, 
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"SCORE",
                model: "GENERAL FRAM CARDIO SCORE",
                hasInsurance:['SSQ','SQ'],
                componentParams: 
                    {
                        label: "LDL-C Target",
                    }
            },
            {
                inputField:"radio",
                expectedValue:"RISK",
                model: "GENERAL FRAM CARDIO RISK",
                hasInsurance:['SSQ','SQ'],
                componentParams: [
                    {
                        label: "Low",
                        value:"LOW"
                    },
                    {
                        label: "Medium",
                        value:"MEDIUM"
                    },
                    {
                        label: "High",
                        value:"HIGH"
                    },
                ],
            },
        ]
    },
    {
        questionLabel:"Administration of the prescription drug covered by the authorization",
        inputField: "radio",
        model: "GENERAL ADMINISTRATION COVERED",
        hasInsurance: ['SSQ','SQ'],
        componentParams: [
            {
                label: "Monotherapy",
                value:"SINGLE"
            }, 
            {
                label: "In conjunction with other treatment",
                value:"MULTI"
            }, 
        ],
    },
    {
        inputField: "text",
        model: "GENERAL ADMINISTRATION COVERED SPECIFICATION",
        hasInsurance: ['SSQ','SQ'],
        componentParams: 
            {
                label: "Please specify",
            }, 
    },
    {
        questionLabel:"Eligibility for drug coverage is dependent upon the patient meeting at least one of the qualifying criteria listed below:",
        inputField: "radio",
        model: "GENERAL ELEGIBILITY",
        hasInsurance: ['NEX','NX'],
        componentParams: [
            {
                label: "Primary Hyperlipidemia",
                value: "GENERALPRIMARYHYPERLIPIDEMIA"
            }, 
            {
                label: "Homozygous Familial Hypercholesterolemia",
                value: "GENERALHOMOZYGOUS"
            }, 
        ],
    },
];

export{
    assessmentGeneral
}
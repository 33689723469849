const assessmentRenewal = [
    // renewal
    {
        questionLabel:"If the patient's statin use has changed or been discontinued in the past 12 months, please detain rationale:",
        inputField: "radio",
        model: "RENEWAL STATIN CHANGE",
        hasInsurance:['AS11',],
        componentParams: [
            {
                label: "Statin has been discontinued",
                value:"discontinued"
            },
            {
                label: "Statin has been reduced",
                value:"reduced"
            }
        ],
    },
    {
        inputField: "text",
        model: "RENEWAL STATIN CHANGE RATIONAL",
        hasInsurance:['AS11',],
        componentParams: 
            {
                label: "Rationale",
            }
    },
    {
        textTitle:"Patient's LCL prior to PCSK-9(Praluent treatment):",
        inputField:"text",
        model: "PRIOR LDL VALUE",
        hasInsurance:['AS11',],
        componentParams: 
            {
                label: "Prior LDL Value",
            }
    },
    {
        // dateoicker
        inputField:"datepicker",
        model: "RENEWAL PRIOR TREATMENT DATE",
        hasInsurance:['AS11',],
        componentParams: 
            {
                label: "Date of Test results",
            }
    },
    {
        questionLabel:"Has patient been compliant with a low cholesterol diet while taking Praluent?",
        inputField:"radio",
        model: "RENEWAL PATIENT COMPLIANCE",
        hasInsurance:['AS11',],
        componentParams: [
            {
                label: "Yes",
                value:"renewalYes0"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    //SSQ
    {
        // datepicker
        textTitle:"The drug covered by the present authorization request was first taken on:",
        inputField:"datepicker",
        model: "RENEWAL DATE TAKEN",
        hasInsurance:['SSQ','SQ',],
        componentParams: 
            {
                label: "Date of Test results",
            }
    },
    //ESI02
    {
        textTitle:"Indicate patient's LDL-C test results prior to starting Praluent",
        inputField:"text",
        model: "RENEWAL RESULTS PRIOR",
        hasInsurance:['ESI02','EP02',],
        componentParams: 
            {
                label: "Prior Results",
            }
    },
    {
        questionLabel:"The patient experienced a 15% or greater reduction in LDL-C level from a baseline if patient suffers from Homozygous Familial Hypercholesterolemia?",
        inputField:"radio",
        model: "RENEWAL GREATER REDUCTION",
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Yes",
                value:"renewalYes1"
            },
            {
                label: "No",
                value:"NO"
            }
        ],
    },
    // AS/AS16
    {
        questionLabel:"Renewal criteria",
        inputField:"radio",
        model: "RENEWAL CRITERIA RADIO",
        hasInsurance:['AS', 'AS16',],
        componentParams: [
            {
                label: "Patient as demonstrated LDL reduction to target",
                value:"renewalYes2"
            },
            {
                label: "None of the above criteria applies, Relevant addition information",
                value:"NO"
            }
        ],
    },
    {
        inputField:"text",
        model: "RENEWAL CRITERIA",
        hasInsurance:['AS',],
        componentParams: 
            {
                label: "Renewal Criteria",
            }
    },
    // BC/OB
    {
        // FIXME: needs to be under section of Renewal Request as per older renewal form
        // datepicker
        textTitle:'Date of initial evaluation(pre-treatment)',
        inputField:"datepicker",
        model: "RENEWAL INITIAL EVALUATION",
        hasInsurance:['BC','OB',],
        componentParams: 
            {
                label: "Date of Initial Evaluation",
            }
    },
    {
        // FIXME: needs to be under section of Renewal Request as per older renewal form
        // datepicker
        textTitle:'Date of most recent evaluation',
        inputField:"datepicker",
        model: "RENEWAL RECENT EVALUATION",
        hasInsurance:['BC','OB',],
        componentParams: 
            {
                label: "Date of Recent Evaluation",
            }
    },
    // desjardins
    {
        // datepicker
        textTitle:'Baseline date',
        inputField:"datepicker",
        model: "RENEWAL BASE LINE DATE",
        hasInsurance:['ESI12','EP12',],
        componentParams: 
            {
                label: "Date of Recent Evaluation",
            }
    },
    {
        textTitle:'Baseline measurement before starting requested drug',
        inputField:"text",
        model: "RENEWAL BASE LINE MEASUREMENT",
        hasInsurance:['ESI12','EP12',],
        componentParams: 
            {
                label: "Baseline Measurement",
            }
    },
    {
        // datepicker
        textTitle:'First Follow-up Date',
        inputField:"datepicker",
        model: "RENEWAL FIRST FOLLOWUP DATE",
        hasInsurance:['ESI12','EP12',],
        componentParams: 
            {
                label: "First follow-up",
            }
    },
    {
        textTitle:'First Follow-up measure',
        inputField:"text",
        model: "RENEWAL FIRST MEASUREMENT",
        hasInsurance:['ESI12','EP12',],
        componentParams: 
            {
                label: "First Follow-up measure",
            }
    },
    {
        // datepicker
        textTitle:'Second Follow-up Date',
        inputField:"datepicker",
        model: "RENEWAL SECOND FOLLOWUP DATE",
        hasInsurance:['ESI12','EP12',],
        componentParams: 
            {
                label: "Second follow-up",
            }
    },
    {
        textTitle:'Second Follow-up measure',
        inputField:"text",
        model: "RENEWAL SECOND MEASUREMENT",
        hasInsurance:['ESI12','EP12',],
        componentParams: 
            {
                label: "Second Follow-up measure",
            }
    },
    // ESI
    {
        textTitle:'Renewal Information',
        inputField:"datepicker",
        model: "RENEWAL TREATMENT INITIATION DATE",
        hasInsurance:['ESI','EP',],
        componentParams: 
            {
                label: "Date of Test results",
            }
    },
    {
        inputField:"text",
        model: "RENEWAL CLINICAL DETAIL",
        hasInsurance:['ESI','EP',],
        componentParams: 
            {
                label: "Details on clinical response to requested drug - PASI/BASDAI, laboratory tests, etc",
            }
    },
];

export{
    assessmentRenewal
}
const memberInfoMain = [
    {
        inputField: "text",
        model: "MEMBER FIRST NAME",
        expectedValue: null,
        previousValue: null,
        componentParams: 
            {
                label: "Member First Name",
            },
        
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "text",
        model: "MEMBER LAST NAME",
        expectedValue: null,
        previousValue: null,
        componentParams: 
            {
                label: "Member Last Name",
            },
        
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "datepicker",
        model: "MEMBER DATE OF BIRTH",
        expectedValue: null,
        previousValue: null,
        componentParams: 
            {
                label: "Date of Birth",
            },
        
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "text",
        model: "INSURANCE ID",
        hasInsurance: ['ESI', 'EP', 'AS11', 'SSQ', 'SQ', 'ESI02','EP02', 'AS', 'BC', 'OB', 'NEX', 'NX', 'ESI11', 'RXP', 'CS'],
        componentParams: {
            label: "Insurance ID #",
        },
    }, {
        inputField: "text",
        model: "INSURANCE CERTIFICATE",
        hasInsurance: ['ESI12','EP12', 'GS', 'SSQ', 'SQ', 'AS16'],
        componentParams: {
            label: "Insurance Certificate #",
        }
    }, {
        inputField: "text",
        model: "INSURANCE NAME",
        hasInsurance: ['AS11'],
        componentParams: {
            label: "Insurance Name",
        },
    }, {
        inputField: "text",
        model: "INSURANCE GROUP",
        hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS11', 'ESI02','EP02', 'AS', 'AS16', 'BC', 'OB', 'ESI11', 'RXP', 'CS'],
        componentParams: {
            label: "Insurance Group #",
        },
    }, {
        inputField: "text",
        model: "INSURANCE CARRIER",
        hasInsurance: ['ESI', 'EP', 'ESI02','EP02', 'AS', 'AS16', 'BC', 'OB'],
        componentParams: {
            label: "Insurance Carrier #",
        },
    },
];

export { memberInfoMain }
<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="auto"
        xs="12"
        sm="12"
        md="3"
      >
        <v-row class="mb-3">
          <h2>Patient Information:</h2>
        </v-row>
        <v-row class="mb-7">
          <v-dialog
            v-model="dialogSearch"
            max-width="900px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                min-width="200px"
                v-on="on"
              >
                Patient search
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Search Patient</span>
              </v-card-title>
              <patient-name-search
                @selected-patient="selectedPatient()"
                @search-patient="searchPatient()"
              ></patient-name-search>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="dialogSearch = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <v-row v-if="!specificInsurance(['AS','ESI11','ODB','DB'])">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#B30000"
                class="white--text"
                min-width="200px"
                v-bind="attrs"
                v-on="on"
                @click="dialogInfo = true"
              >
                Additional Info
                <v-icon
                  v-bind="attrs"
                  class="ml-3"
                  v-on="on"
                >
                  {{
                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{

              }}
            </span>
            <v-dialog
              v-model="dialogInfo"
              max-width="900px"
            >
              <v-card>
                <v-card-title>
                  <span class="h4">Additional Patient Info</span>
                </v-card-title>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-container>
                      <v-row v-if="specificInsurance(['ODB','ESI12','EP12','NEX','NX','GS','SSQ','SQ','AS16','ESI','EP','BC','OB','RXP','CS','AS11','AS'])">
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="3"
                        >
                          <div class="h5 mb-4">
                            Communication Preferences:
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="9"
                        >
                          <recursive-tree
                            v-for="(field, index) in patientQuestionnaire"
                            :key="index"
                            :question-label="field.questionLabel"
                            :nodes="field.nodes"
                            :input-field="field.inputField"
                            :model="field.model"
                            :component-params="field.componentParams"
                            :expected-value="field.expectedValue"
                            :previous-value="field.previousValue"
                            :rules="field.rules"
                            :depth="0"
                            :has-insurance="field.hasInsurance"
                          >
                          </recursive-tree>
                        </v-col>
                      </v-row>
                      <v-divider v-if="specificInsurance(['ODB','ESI12','EP12','GS','SSQ','SQ','AS16','ESI','EP','BC','OB','RXP','CS','AS11'])"></v-divider>

                      <v-row v-if="specificInsurance(['ODB','ESI12','EP12','GS','SSQ','SQ','AS16','ESI','EP','BC','OB','RXP','CS','AS11','ESI02','EP02'])">
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="3"
                        >
                          <div class="h5 mb-4">
                            Coverage Questionnaire:
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="9"
                        >
                          <recursive-tree
                            v-for="(field, index) in patientCoverage"
                            :key="index"
                            :question-label="field.questionLabel"
                            :nodes="field.nodes"
                            :input-field="field.inputField"
                            :model="field.model"
                            :component-params="field.componentParams"
                            :expected-value="field.expectedValue"
                            :previous-value="field.previousValue"
                            :rules="field.rules"
                            :depth="0"
                            :has-insurance="field.hasInsurance"
                            :child-render-condition="field.childRenderCondition"
                            :children-models="field.childrenModels"
                          >
                          </recursive-tree>
                        </v-col>
                      </v-row>
                      <v-divider v-if="specificInsurance(['ODB','ESI12','EP12','NEX','NX','GS','SSQ','SQ','AS16','ESI','EP','BC','OB','RXP','CS','AS11','AS','ESI02','EP02'])"></v-divider>
                      <v-row v-if="specificInsurance(['AS16'])">
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="3"
                        >
                          <div class="h5 mb-4">
                            Education Information:
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="9"
                        >
                          <recursive-tree
                            v-for="(field, index) in educationInfoSub"
                            :key="index"
                            :question-label="field.questionLabel"
                            :nodes="field.nodes"
                            :input-field="field.inputField"
                            :model="field.model"
                            :component-params="field.componentParams"
                            :expected-value="field.expectedValue"
                            :previous-value="field.previousValue"
                            :rules="field.rules"
                            :depth="0"
                            :has-insurance="field.hasInsurance"
                          >
                          </recursive-tree>
                        </v-col>
                      </v-row>
                      <v-divider v-if="specificInsurance(['AS16'])"></v-divider>
                      <v-row v-if="specificInsurance(['AS16','RXP','CS'])">
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="3"
                        >
                          <div class="h5 mb-4">
                            Spouse Information:
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="9"
                        >
                          <recursive-tree
                            v-for="(field, index) in spouseInfoSub"
                            :key="index"
                            :question-label="field.questionLabel"
                            :nodes="field.nodes"
                            :input-field="field.inputField"
                            :model="field.model"
                            :component-params="field.componentParams"
                            :expected-value="field.expectedValue"
                            :previous-value="field.previousValue"
                            :rules="field.rules"
                            :depth="0"
                            :has-insurance="field.hasInsurance"
                          >
                          </recursive-tree>
                        </v-col>
                      </v-row>
                      <v-divider v-if="specificInsurance(['AS16','RXP','CS'])"></v-divider>
                      <v-row v-if="specificInsurance(['ODB','ESI12','EP12','GS','AS16','ESI','EP','BC','OB','RXP','CS','AS11','ESI02','EP02'])">
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="3"
                        >
                          <div class="h5 mb-4">
                            Patient Support:
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          xs="12"
                          sm="12"
                          md="9"
                        >
                          <recursive-tree
                            v-for="(field, index) in patientSupport"
                            :key="index"
                            :question-label="field.questionLabel"
                            :nodes="field.nodes"
                            :input-field="field.inputField"
                            :model="field.model"
                            :component-params="field.componentParams"
                            :expected-value="field.expectedValue"
                            :previous-value="field.previousValue"
                            :rules="field.rules"
                            :depth="0"
                            :has-insurance="field.hasInsurance"
                          >
                          </recursive-tree>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="dialogInfo = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tooltip>
        </v-row>
      </v-col>

      <v-col
        xs="12"
        sm="12"
        md="9"
      >
        <v-row>
          <recursive-tree-multiple
            v-for="(field, index) in patientInfoMain"
            :key="index"
            :question-label="field.questionLabel"
            :nodes="field.nodes"
            :input-field="field.inputField"
            :model="field.model"
            :component-params="field.componentParams"
            :expected-value="field.expectedValue"
            :previous-value="field.previousValue"
            :rules="field.rules"
            :depth="0"
            :has-insurance="field.hasInsurance"
          >
          </recursive-tree-multiple>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalPatientNameSearch from '@/components/PatientNameSearch/GlobalPatientNameSearch/GlobalPatientNameSearch'
import RecursiveTree from '@/components/common/ui/Layout/RecursiveTree/RecursiveTree'
import RecursiveTreeMultiple from '@/components/common/ui/Layout/RecursiveTree/RecursiveTreeMultiple'

import query from '@/views/praluent/query/query'
import { mapGetters } from 'vuex'

import { patientInfoMain } from './praluentData/patientInfoMain'
import { patientCoverage, patientQuestionnaire } from './praluentData/patientInfoSub'

import { educationInfoSub } from './praluentData/educationInfoSub'
import { spouseInfoSub } from './praluentData/spouseInfoSub'
import { patientSupport } from './praluentData/patientSupportSub'

export default {
    name: 'PatientInfo',
    components: {
        'patient-name-search': GlobalPatientNameSearch,
        'recursive-tree': RecursiveTree,
        'recursive-tree-multiple': RecursiveTreeMultiple,
    },
    data() {
        return {
            // tree testing
            dialogSearch: false,
            dialogInfo: false,
            PatientProfile: null,
            patientInfoMain,
            patientCoverage,
            patientQuestionnaire,
            educationInfoSub,
            spouseInfoSub,
            patientSupport,
            trackId: '',
        }
    },
    watch: {
        patientSupportName(newVal) {
            if (newVal === 'patientAssistanceYes') {
                this.$store.commit('SET_PRALUENT_SINGLE_STATE', {
                    key: 'PATIENT ASSISTANCE PROGRAM NAME',
                    value: 'MyPraluent Coach',
                })
            } else {
                this.$store.commit('SET_PRALUENT_SINGLE_STATE', {
                    key: 'PATIENT ASSISTANCE PROGRAM NAME',
                    value: '',
                })
            }
        },
    },
    computed: {
        ...mapGetters(['TrackProcess']),
        patientSupportName: {
            get() {
                return this.$store.getters.TrackProcess.praluentPatient['PATIENT ASSISTANCE RADIO']
            },
        },
    },
    mounted() {},
    created() {
        if (this.$store.state.Praluent.praluentPatient['FIRST NAME']) {
            this.selectedPatient()
        }
    },
    methods: {
        searchPatient() {
            this.dialogSearch = false
        },
        async selectedPatient() {
            await this.getNewPatientData()
            await this.getMedHistory()

            // let lab;
            // get Process
            await this.$store.dispatch('getProcessV2')
            this.trackId = this.$store.getters.TrackProcess.trackID

            // fill PDF if any exists
            if (this.$store.getters.TrackProcess.payload.pdfReferences) {
                await this.$store.dispatch('setPdfData', this.$store.getters.TrackProcess.payload.pdfReferences)
            }

            // get lab results if labID is set
            // await this.$store
            //   .dispatch("getLabByPatientAttributes", {
            //     attributes: {},
            //     patientID: this.$store.getters.PatientProfile.PatientID,
            //     pharmacyID: this.$store.getters.PharmacyProfile._id,
            //   })
            //   .then(async () => {

            //     await this.$store.commit("SET_PRALUENT_SINGLE_STATE", {
            //       key: `TOTAL CHOLESTEROL VALUE`,
            //       value: this.$store.getters.LabRecord.attributes.TC,
            //     });
            //     await this.$store.commit("SET_PRALUENT_SINGLE_STATE", {
            //       key: `TOTAL LDL-C VALUE`,
            //       value: this.$store.getters.LabRecord.attributes.LDL,
            //     });
            //     // this.refresh++; // force re-rendering
            //   });
            // FIXME we will use carrier plan
            const insuranceProvider = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider || []
            if (insuranceProvider.length && insuranceProvider.includes('ONTARIO DRUG BENEFIT')) {
                this.isPatientAlreadyTrillium = true
            }
            if (this.$store.getters.TrackProcess) {
                // if step is completed, then move to the last page
                if (this.$store.getters.TrackProcess.status === 'completed') {
                    this.step = 1

                    return
                }

                // trackStateOrderbyProgression
                let stepCounter = 0;
                [
                    'startedDateTime',
                    'labRequestedDateTime',
                    'questionnaireDateTime',
                    'trilliumDateTime',
                    'eligibilityDateTime',
                    'coverageDateTime',
                ].forEach((key, index) => {
                    if (this.$store.getters.TrackProcess.payload[key]) {
                        stepCounter++
                    } else {
                        return false
                    }
                })

                // add one for wizard pagination
                // this.step = stepCounter + 1;
            }
        },

        async getNewPatientData() {
            await this.$store.dispatch('loadInsuranceProviders')
            const { DBType } = this.$store.getters.PharmacyProfile
            if (!this.$store.getters.TrackProcess.praluentPatient.stateId) {
                const patientId = this.$route.query.patientID || this.$store.getters.PatientProfile.PatientID

                try {
                    return await this.$root.$system.DBAdapterConn.DBQuery(
                        query.candidates[DBType].patientsInfo.replace('$PatientIDs', patientId),
                    )
                        .then(
                            results => {
                                results = results.reduce((rows, patient, index) => {
                                    const foundIndex = rows.findIndex(row => row.PatientID === patient.PatientID)

                                    // FIXME this isn't pulling insurances correctly
                                    const patientsInsuranceProvider = this.$store.state.Insurance.providers.find(insurance => {
                                        let carrierIdCond = true
                                        if (insurance.carrierId) {
                                            carrierIdCond = patient.CarrierID == insurance.carrierId
                                        }

                                        return patient.PlanCode == insurance.planCode && carrierIdCond
                                    })
                                    let insuranceProvider
                                    if (patientsInsuranceProvider) {
                                        insuranceProvider = {
                                            apikey: patientsInsuranceProvider.id,
                                            code: patientsInsuranceProvider.code,
                                            name: patientsInsuranceProvider.name,
                                            PlanCode: patient.PlanCode,
                                            CarrierID: patient.CarrierID,
                                            GroupID: patient.GroupID,
                                            PlanID: patient.PlanID,
                                            Relationship: patient.Relationship,
                                            PatientCode: patient.PatientCode,
                                        }
                                    }

                                    // FIXME should be part of the insurance condition or black list filter callbacks
                                    const isBlackListed = patient.CarrierID == 'S'
                    || (patient.CarrierID == '32' && patient.PlanCode == 'ESI')
                    || (patient.CarrierID == '80' && patient.PlanCode == 'AS')

                                    // FIXME yeah...........long night
                                    if (foundIndex == -1) {
                                        const insuranceArray = []

                                        // we only want ppl with no CarriesID = S
                                        if (!isBlackListed && insuranceProvider) {
                                            insuranceArray.push(insuranceProvider)
                                        }

                                        // switch it to an array
                                        patient.InsuranceProvider = insuranceArray
                                        rows.push(patient)
                                    } else if (!isBlackListed && insuranceProvider) {
                                        // add to the array
                                        rows[foundIndex].InsuranceProvider.push(insuranceProvider)
                                    }

                                    return rows
                                }, [])
                                // eslint-disable-next-line no-unused-vars
                                results = results.map((patientInfo, index) => ({
                                    ...patientInfo,
                                    name: `${patientInfo['FIRST NAME']} ${patientInfo['LAST NAME']}`,
                                    Medication: patientInfo.Medication,
                                }))

                                return results.pop() // get the single patient record
                            },
                            reject => {
                                console.log('Immediately rejected')
                            },
                        )
                        .then(InsuranceProvider => {
                            console.log(InsuranceProvider)

                            return this.$root.$system.DBAdapterConn.getPraluentData(patientId).then(patientData => {
                                this.$store.dispatch('savePatientData', {
                                    ...patientData,

                                    // add insurance
                                    ...InsuranceProvider,
                                })

                                // FIXME: don't want to set PatientProfile like this, using getters did not work -- didnt have enough time;
                                this.$store.commit('SET_PRALUENT_PATIENT_WHOLE', {
                                    ...this.PatientProfile,
                                    ...patientData,
                                    ...InsuranceProvider,
                                })
                                this.$store.dispatch('setPrefillData', {
                                    ...patientData,
                                    ...InsuranceProvider,
                                })

                                this.isPatientLoaded = true
                            })
                        })
                } catch (err) {
                    // console.log(err);
                }
            }
        },

        async getMedHistory() {
            const { PatientProfile } = this.$store.getters

            if (!this.$store.getters.TrackProcess.praluentPatient.stateId) {
                this.medicationTableData = Promise.all([
                    this.$root.$system.DBAdapterConn.getPatientMedicationHistory(
                        PatientProfile.PatientID,
                        'EZETIMIBE',

                        // "10MG",
                        // false
                    ),

                    this.$root.$system.DBAdapterConn.getPatientMedicationHistory(
                        PatientProfile.PatientID,
                        'ROSUVASTATIN',

                        // "40MG",
                        // false
                    ),
                    this.$root.$system.DBAdapterConn.getPatientMedicationHistory(
                        PatientProfile.PatientID,
                        'ATORVASTATIN CALCIUM',

                        // "80MG",
                        // false
                    ),
                    this.$root.$system.DBAdapterConn.getPatientMedicationHistory(
                        PatientProfile.PatientID,
                        'FLUVASTATIN',

                        // "40MG",
                        // false
                    ),
                    this.$root.$system.DBAdapterConn.getPatientMedicationHistory(
                        PatientProfile.PatientID,
                        'LOVASTATIN',

                        // "40MG",
                        // false
                    ),
                    this.$root.$system.DBAdapterConn.getPatientMedicationHistory(
                        PatientProfile.PatientID,
                        'PRAVASTATIN',

                        // "40MG",
                        // false
                    ),
                    this.$root.$system.DBAdapterConn.getPatientMedicationHistory(
                        PatientProfile.PatientID,
                        'SIMVASTATIN',

                        // "40MG",
                        // false
                    ),
                ]).then(medicationResults => {
                    if (medicationResults.length == 0) {
                        return {}
                    }
                    const medicationTable = medicationResults.reduce((cur, medicationData) => {
                        if (medicationData.length < 1) {
                            return cur
                        }
                        cur.push(this.medicationCompliance(medicationData))

                        return cur
                    }, [])
                    this.$store.commit('SET_PRALUENT_OVERLAY_DATA', {
                        medicationTable,
                    })

                    return {
                        ...medicationTable,
                    }
                })
            } else {
            }
        },
        addStatin() {
            this.statinsArr.push({})
        },
        removeStatin(index) {
            this.statinsArr.splice(index, 1)
        },
        medicationCompliance(dbResults) {
            const historyStart = 0
            const historyLookup = 3
            let nonCompliant = false

            if (dbResults.length == 0) {
                return {}
            }

            try {
                const todaysDate = this.$moment()
                nonCompliant = dbResults.some(record => todaysDate.diff(this.$moment(record.RxDate), 'days') >= 90) === false
            } catch (e) {
                console.error(e)
                nonCompliant = true
            }

            if (nonCompliant) {
                return this.handleMedicationNonCompliance(dbResults, historyStart, historyLookup)
            }

            return this.createRow(dbResults, historyStart, historyLookup)
        },
        handleMedicationNonCompliance(dbResults, historyStart, historyLookup) {
            const data = {
                ...this.createRow(dbResults, historyStart, historyLookup),
            }

            // patient cannot take 2 statin's medications at the same time
            if (this.rowIndex > 1) {
                data[`statinoutcome${this.rowIndex}`] = 'Discontinued'
            }

            return data
        },
        createRow(dbResults, firstRowIndex, historyLookbackIndex) {
            const firstRow = dbResults[firstRowIndex]

            const historyLookbackRow = dbResults[historyLookbackIndex]
            const lastRow = dbResults[dbResults.length - 1]

            const daysOnTherapy = parseFloat(
                this.$moment(firstRow.RxDate)
                    .add(firstRow.RxDays, 'days')
                    .diff(this.$moment(lastRow.RxDate), 'days'),
            )
            let complaint = false
            const complianceCount = dbResults.reduce((total, record) => total + record.RxDays, 0)
            const ratioOfCompliance = (daysOnTherapy - complianceCount) / daysOnTherapy

            // FIXME spaghetti code
            // if(complianceCount > daysOnTherapy){
            //     complianceCount = daysOnTherapy;
            //     complaint = true
            // }else
            if (complianceCount >= daysOnTherapy || ratioOfCompliance < 0.2) {
                complaint = true
            } else {
                complaint = false
            }

            this.rowIndex++

            return {
                statin: firstRow.GenericName,
                statindose: firstRow.RxStrength,
                statinstart: this.$moment(lastRow.RxDate).format('YYYY-MM-DD'),
                statinend: this.$moment(firstRow.RxDate).format('YYYY-MM-DD'),
                daysontherapy: complianceCount,
                statincompliance: complaint,
                statinoutcome: '',
            }
        },
        specificInsurance(insuranceCompanies) {
            let flag = false
            let privateInsurance
            if (Object.entries(this.$store.getters.TrackProcess.praluentPatient).length === 0) {
                return flag
            }
            if (
                this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb'
          && this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider.length < 2
            ) {
                privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
            } else if (this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb') {
                privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[1]
            } else {
                privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
            }
            insuranceCompanies.forEach(insuranceCompany => {
                if (privateInsurance.apikey === 'odb') {
                    flag = true
                }
                if (privateInsurance.code === insuranceCompany) {
                    flag = true
                }
            })

            return flag
        },
    },
}
</script>

<style scoped>
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.theme--light.v-card > .v-card__text {
  color: rgba(0, 0, 0, 1);
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 1);
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <v-row class="mb-3">
          <h2>Medication Info:</h2>
        </v-row>
        <v-row class="mb-7">
          <v-btn min-width="200px" color="success" @click="addStatin">Add a statin</v-btn>
        </v-row>

        <v-row v-if="!specificInsurance(['RXP','CS','ESI11','AS','AS16','NEX','NX','ODB','DB'])">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn min-width="200px" color="#B30000"
              class="white--text" v-bind="attrs" v-on="on" @click="dialogInfo = true">
                Additional Info
                <v-icon v-on="on" v-bind="attrs" class="ml-3"> </v-icon>
              </v-btn>
            </template>
            <span> {{}} </span>
          </v-tooltip>
        </v-row>

        <v-dialog v-model="dialogInfo" max-width="900px" >
          <v-card>
            <v-card-title>
              <span class="h4">Additional Medication Info</span>
            </v-card-title>
            <v-card-text>
              <v-form 
                @submit.prevent
              >
                <v-container>
                  <v-row v-if="specificInsurance(['BC','OB','ESI','EP','GS','SSQ','SQ','ESI12','EP12','ESI02','EP02','AS11'])">
                    <v-col cols="auto" xs="12" sm="12" md="3">
                      <div class="h5">Medication Info:</div>
                    </v-col>
                    <v-col cols="auto" xs="12" sm="12" md="9">
                      <recursive-tree
                        v-for="(field, index) in medicationQuestions"
                        :key="index"
                        :textTitle="field.textTitle"
                        :questionLabel="field.questionLabel"
                        :nodes="field.nodes"
                        :inputField="field.inputField"
                        :model="field.model"
                        :componentParams="field.componentParams"
                        :expectedValue="field.expectedValue"
                        :previousValue="field.previousValue"
                        :rules="field.rules"
                        :depth="0"
                        :hasInsurance="field.hasInsurance"
                      >
                      </recursive-tree>
                    </v-col>
                  </v-row>
                  <v-divider v-if="specificInsurance(['BC','OB','ESI','EP','GS','SSQ','SQ','ESI12','EP12','ESI02','EP02','AS11'])"></v-divider>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogInfo = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col xs="12" sm="12" md="9">
        <v-row
          v-for="(statin, statinIndex) in medicationTable"
          :key="statinIndex"
        >
          <v-card style="margin-right: 0px;margin-left: 0px;margin-bottom: 70px;">

            <v-card-title>
              <h2 v-if="statinIndex === 0 && medicationTable[0].statin === 'EZETIMIBE'">{{ezetimibeTitle}}</h2>
              <h2 v-else-if="medicationTable[0].statin !== 'EZETIMIBE'">Statin {{ statinIndex + 1}}</h2>
              <h2 v-else-if="statinIndex >= 1 && medicationTable[0].statin === 'EZETIMIBE'">Statin {{ statinIndex + 1}}</h2>
            </v-card-title>
            <v-card-text>
              <v-row>
                <recursive-tree-array
                  v-for="(field, questionIndex1) in statinQuestions"
                  :key="questionIndex1"
                  :additionalIndex="statinIndex"
                  :questionLabel="field.questionLabel"
                  :nodes="field.nodes"
                  :inputField="field.inputField"
                  :model="field.model"
                  :componentParams="field.componentParams"
                  :expectedValue="field.expectedValue"
                  :previousValue="field.previousValue"
                  :rules="field.rules"
                  :hasInsurance="field.hasInsurance"
                  :depth="0"
                >
                </recursive-tree-array>
              </v-row>
              <v-row v-if="specificInsurance(['ESI12','ESI','EP','BC','OB','AS11','ESI02','EP02','SSQ','SQ'])">
                <recursive-tree-array-double
                  v-for="(field, questionIndex2) in statinQuestionExtension"
                  :key="questionIndex2"
                  :additionalIndex="statinIndex"
                  :questionLabel="field.questionLabel"
                  :nodes="field.nodes"
                  :inputField="field.inputField"
                  :model="field.model"
                  :componentParams="field.componentParams"
                  :expectedValue="field.expectedValue"
                  :previousValue="field.previousValue"
                  :rules="field.rules"
                  :hasInsurance="field.hasInsurance"
                  :depth="0"
                >
                </recursive-tree-array-double>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
              
              <v-btn style="margin-bottom: 10px;" color="error" @click="removeStatin(statinIndex)">
                Remove Statin
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import RecursiveTree from "@/components/common/ui/Layout/RecursiveTree/RecursiveTree";
import RecursiveTreeArray from "@/components/common/ui/Layout/RecursiveTree/RecursiveTreeArray";
import RecursiveTreeArrayDouble from "@/components/common/ui/Layout/RecursiveTree/RecursiveTreeArrayDouble";

import { medicationQuestions } from "./praluentData/medicationHistorySub";

import { statinQuestions } from "./praluentData/statinQuestions";
import { statinQuestionExtension } from "./praluentData/statinQuestionExtension";
import { statinQuestionsTemplate } from "./praluentData/statinQuestionsTemplate";
//Made modifications to PatientInfo to obtain and modify medication history data to correct format
//able to extract medication history information from patient search
/*TODO: PCUF29:
        - write medication Information component to add or subtract statins as users are expecting
        - update questions model accordingly
*/
export default {
  components: {
    "recursive-tree": RecursiveTree,
    "recursive-tree-array": RecursiveTreeArray,
    "recursive-tree-array-double": RecursiveTreeArrayDouble,
    // statin: Statin,
  },
  name: "Medications",
  created() {},
  data() {
    return {
      statinQuestions: statinQuestions,
      statinQuestionsTemplate: statinQuestionsTemplate,
      statinQuestionExtension: statinQuestionExtension,
      rowIndex: -1,

      medicationQuestions: medicationQuestions,
      dialogInfo: false,
      statinsArr: [],
      noPreviousTreatment: "no",
    };
  },
  computed: {
    ...mapGetters([
      "TrackProcess"
    ]),
    medicationTable() {
      return this.$store.state.Praluent.praluentPatient.medicationTable;
    },
    ezetimibeTitle(){
      if(this.medicationTable[0].statin === 'EZETIMIBE'){
        return 'Ezetimibe'
      }
    }
  },
  methods: {
    addStatin() {
      this.$store.commit("SET_PRALUENT_ADD_ARRAY_INDEX", {
        arrayName: "medicationTable",
        template: this.statinQuestionsTemplate,
      });
    },
    removeStatin(statinIndex) {
      this.$store.commit("SET_PRALUENT_REMOVE_ARRAY_INDEX", {
        arrayName: "medicationTable",
        statinIndex: statinIndex,
      });
    },
    specificInsurance(insuranceCompanies) {
      let flag = false;
      let privateInsurance;
      if(Object.entries(this.$store.getters.TrackProcess.praluentPatient).length === 0 ){
        return flag;
      }else{
        if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb' && this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider.length < 2){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        else if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb'){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[1]
        }
        else{
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        insuranceCompanies.forEach(insuranceCompany => {
          if(privateInsurance.apikey === 'odb'){
            flag = true;
          }
          if (privateInsurance.code === insuranceCompany) {
            flag = true;
          }
        });
        return flag;
      }
    },
  },
};
</script>

<style scoped>
.block {
  display: block;
}
</style>
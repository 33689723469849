const patientInfoMain = [{

        inputField: "text",
        model: "FIRST NAME",
        expectedValue: null,
        previousValue: null,
        componentParams: {
            label: "Patient First Name",
        },
        hasInsurance: [],
        nodes: [],
    },
    {

        inputField: "text",
        model: "LAST NAME",
        expectedValue: null,
        previousValue: null,
        componentParams: {
            label: "Patient Last Name",
        },

        hasInsurance: [],
        nodes: [],
    },
    {

        inputField: "datepicker",
        model: "DATE OF BIRTH",
        expectedValue: null,
        previousValue: null,
        componentParams: {
            label: "Patient Date Of Birth",
        },

        hasInsurance: [],
        nodes: [],
    },
    {
        inputField: "text",
        model: "UNIT NUMBER",
        componentParams: {
            label: "Unit Number",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "STREET NUMBER",
        componentParams: {
            label: "Street Number",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "STREET NAME",
        componentParams: {
            label: "Street Name",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "CITY/TOWN",
        componentParams: {
            label: "City",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "PROVINCE",
        componentParams: {
            label: "Province",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "POSTAL CODE",
        componentParams: {
            label: "Postal Code",
        },
        hasInsurance: []
    },
    {
        inputField: "text",
        model: "TELEPHONE NUMBER",
        componentParams: {
            label: "Phone Number",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "CELLPHONE NUMBER",
        componentParams: {
            label: "Secondary Phone Number",
        },
        hasInsurance: []
    }, {
        rules: [(v) => !!v || 'Email is required'],
        inputField: "text",
        model: "EMAIL ADDRESS",
        componentParams: {
            label: "E-mail",
        },
        hasInsurance: []
    },
]

export {
    patientInfoMain
}
const assessmentInitial = [
    {
        questionLabel:"Please indicate which of the following situations applies and complete the application section below",
        inputField: "radio",
        model: "INITIAL INHIBITOR",
        childrenModels:[
            'INITIAL NOT SYMPOTOMS',
            'INITIAL NOT LOW DENSITY',
            'INITIAL NOT MAX',
            'INITIAL PATIENT DECISION',
            'INITIAL TRUE TREATMENT'
        ],
        hasInsurance: ['AS16',],
        componentParams: [
            {
                label: "Patient is NOT currently being treated with a PCSK9 inhibitor",
                value:"NO"
            }, 
            {
                label: "Patient is currently being treated with a PCSK9 inhibitor",
                value:"initialYes0"
            }, 
        ],
        nodes:[
            {
                textTitle:"Patient is currently NOT being treated with a PCSK9 inhibitor",
                inputField: "checkbox",
                expectedValue:"NO",
                model: "INITIAL NOT SYMPOTOMS",
                hasInsurance:['AS16',],
                componentParams: [
                    {
                        label: "Drug is being used as per the health Canada approved indication for familial hypercholesterolemia or clinical atheroscelerotic cardiovascular disease",
                        value:"initialYes4"
                    },
                ],
            },
            {
                inputField: "checkbox",
                expectedValue:"NO",
                model: "INITIAL NOT LOW DENSITY",
                hasInsurance:['AS16',],
                componentParams: [
                    {
                        label: "Patient's low density lipoprotein cholesterol (LDL-C) is > 2 mmol/L",
                        value:"initialYes5"
                    },
                ],
            },
            {
                inputField: "checkbox",
                expectedValue:"NO",
                model: "INITIAL NOT MAX",
                hasInsurance:['AS16',],
                componentParams: [
                    {
                        label: "Patient received a statin therapy for at least 3 months at maximally tolerated dose",
                        value:"initialYes6"
                    },
                ],
            },
            {
                inputField: "radio",
                expectedValue:"NO",
                model: "INITIAL PATIENT DECISION",
                hasInsurance: ['AS16',],
                componentParams: [
                    {
                        label: "Patient is statin intolerant",
                        value:"STATIN"
                    }, 
                    {
                        label: "Patient has a documented contraindication to statins",
                        value:"CONTRAINDICATION"
                    }, 
                    {
                        label: "One or more of the criteria listed above are not satisfied",
                        value:"NOTSTATIFIED"
                    }, 
                ],
            },

            {
                questionLabel:"Patient is currently being treated with a PCSK9 inhibitor",
                inputField: "radio",
                expectedValue:"initialYes0",
                model: "INITIAL TRUE TREATMENT",
                hasInsurance:['AS16',],
                componentParams: [
                    {
                        label: "Drug is being used as per the health Canada approved indication for familial hypercholesterolemia or clinical atheroscelerotic cardiovascular disease",
                        value:"initialYes1"
                    },
                    {
                        label: "The criterion listed above is not satisfied",
                        value:"NO"
                    },
                ],
            },
        ]
    },
    // express
    {
        textTitle:"First Time Application",
        inputField: "text",
        model: "INITIAL PATIENT CONDITION",
        hasInsurance:['ESI','EP',],
        componentParams: 
            {
                label: "The severity/stage/ type of the patients condition(ex:specify monthly frequency and duration for migraines, fibrosis status for hepatitis C patients)",
            }
    },
    {
        inputField: "text",
        model: "INITIAL ADDITIONAL INFORMATION",
        hasInsurance:['ESI','EP',],
        componentParams: 
            {
                label: "Additional information relevant to the patient's condition and treatment(lab values such as LDL and IgE levels, health status assessments, BMI, symptoms)",
            }
    },
    {
        inputField: "text",
        model: "INITIAL THERAPIES",
        hasInsurance:['ESI','EP',],
        componentParams: 
            {
                label: "Therapies (pharmacological/non pharmacological) that will be used for treating the same conditions concomitantly",
            }
    },
    // AS
    {
        inputField: "checkbox",
        
        model: "INITIAL PRESCRIBER",
        hasInsurance:['AS'],
        componentParams: [
            {
                label: "Prescriber is a cardiologist or other specialist in the treatment of lipid disorders. AND",
                value: "YES"
            }
        ],
    },
    {
        inputField: "checkbox",
        
        model: "INITIAL PHYSICIAN CONFIRMATION",
        hasInsurance:['AS'],
        componentParams: [
            {
                label: "Physician confirms the patient meets the drug’s Health Canada approved indication for familial hypercholesterolemia AND 12 years of age and older",
                value: "YES"
            }
        ],
    },
    {
        inputField: "checkbox",
        
        model: "INITIAL CLINICAL DISEASE",
        hasInsurance:['AS'],
        componentParams: [
            {
                label: "For clinical atherosclerotic cardiovascular disease, such as heart attacks or strokes AND 18 years of age and older AND",
                value: "YES"
            }
        ],
    },
    {
        inputField: "radio",
        model: "INITIAL FRAMINGHAM SCORE",
        childrenModels:[
            'INITIAL INTERMEDIATE LDL',
            'INITIAL LOW LDL'
        ],
        hasInsurance:['AS'],
        componentParams: [
            {
                label: "High Framingham Score (FRS) ≥20%",
                value: "initialGreater0"
            },
            {
                label: "Intermediate FRS 10-19%:",
                value: "INTERMEDIATE"
            },
            {
                label: "Low FRS<10%:",
                value: "LOW"
            },

        ],
        nodes:[
            {
                inputField: "radio",
                expectedValue:"INTERMEDIATE",
                model: "INITIAL INTERMEDIATE LDL",
                hasInsurance:['AS'],
                componentParams: [
                    {
                        label: "LDL-C≥3.5mmol/L OR",
                        value: "initialGreater1"
                    },
                    {
                        label: "For LDL-C<3.5 consider if: Apo B ≥1.2g/L or Non-HDL-C ≥4.3mmol/L",
                        value: "initialLess0"
                    }
                ],
            },
            {
                inputField: "radio",
                expectedValue:"LOW",
                model: "INITIAL LOW LDL",
                hasInsurance:['AS'],
                componentParams: [
                    {
                        label: "LDL-C≥5mmol/L OR",
                        value: "initialGreater2"
                    },
                    {
                        label: "Familial hypercholesterolemia",
                        value: "initialLess1"
                    }
                ],
            },
        ]
    },
    {
        inputField: "checkbox",
        model: "INITIAL THERA INTOL CONTRAIN",
        childrenModels:[
            'INITIAL TWO STATIN',
            'INITIAL ATOR',
            'INITIAL RESUV',
            'INITIAL SIM',
            'INITIAL EZE'
        ],
        hasInsurance:['AS'],
        componentParams: [
            {
                label: "Therapeutic failure on, intolerance to, or contraindication to:",
                value: "YES"
            }
        ],
        nodes:[
            {
                inputField: "checkbox",
                expectedValue:"YES",
                model: "INITIAL TWO STATIN",
                hasInsurance:['AS'],
                componentParams: [
                    {
                        label: "at least two statins:",
                        value: "YES"
                    },
                ],
                nodes:[
                    {
                        inputField: "checkbox",
                        expectedValue:"YES",
                        model: "INITIAL ATOR",
                        hasInsurance:['AS'],
                        componentParams: [
                            {
                                label: "atorvastatin",
                                value: "YES"
                            },
                        ],
                    },
                    {
                        inputField: "checkbox",
                        expectedValue:"YES",
                        model: "INITIAL RESUV",
                        hasInsurance:['AS'],
                        componentParams: [
                            {
                                label: "rosuvastatin",
                                value: "YES"
                            },
                        ],
                    },
                    {
                        inputField: "checkbox",
                        expectedValue:"YES",
                        model: "INITIAL SIM",
                        hasInsurance:['AS'],
                        componentParams: [
                            {
                                label: "simvastatin",
                                value: "YES"
                            },
                        ],
                    }
                ]
            },
            {
                inputField: "checkbox",
                expectedValue:"YES",
                model: "INITIAL EZE",
                hasInsurance:['AS'],
                componentParams: [
                    {
                        label: "Ezetimibe",
                        value: "YES"
                    },
                ],
            }
        ]
    },
    // BC
    {
        questionLabel:"Is the drug being prescribed according to the Health Canada product monograph?",
        inputField: "radio",
        model: "INITIAL MONOGRAPH",
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "Yes",
                value: "initialYes2"
            },
            {
                label: "No",
                value: "NO"
            },
        ],
    },
    {
        // datepicker
        inputField: "datepicker",
        model: "INITIAL DIAGNOSIS DATE",
        hasInsurance:['BC','OB',],
        componentParams: 
            {
                label: "Date of Diagnosis",
            },
    },
    {
        inputField: "text",
        model: "INITIAL DIAGNOSIS SCORE",
        hasInsurance:['BC','OB',],
        componentParams: 
            {
                label: "Patient's LDL-C score at the moment of diagnosis:",
            }
    },
    {
        // datepicker
        inputField: "datepicker",
        model: "INITIAL BEGINNING OF TREATMENT DATE",
        hasInsurance:['BC','OB',],
        componentParams: 
            {
                label: "Date of beginning of treatment",
            },
    },
    {
        inputField: "text",
        model: "INITIAL BEGINNING OF TREATMENT SCORE",
        hasInsurance:['BC','OB',],
        componentParams: 
            {
                label: "Patient's baseline LDL-C score prior to treatment",
            }
    },
    {
        questionLabel:"Written confirmation of diagnosis from a specialist",
        inputField: "radio",
        model: "INITIAL CONFIRMATION",
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "Yes",
                value:"initialYes3"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
];

export{
    assessmentInitial
}
const assessmentHomo = [{
        questionLabel: "Initial or renewal",
        inputField: "radio",
        model: "HOMO INITIAL RENEWAL",
        childrenModels:[
            'HOMO RESULTS PRIOR',
            'HOMO RESULTS POST',
            'HOMO PATIENT REDUCTION',
            'HOMO RENEWAL CRITERIA'
        ],
        hasInsurance: ['ESI02','EP02'],
        componentParams: [{
                label: "Initial",
                value: "INITIAL"
            },
            {
                label: "Renewal",
                value: "RENEWAL"
            }
        ],
        nodes: [{
                textTitle: "Indicate patient's LSL-C test results prior to starting Praluent",
                inputField: "text",
                model: "HOMO RESULTS PRIOR",
                hasInsurance: [],
                componentParams: {
                    label: "Results Prior",
                },
            },
            {
                textTitle: "Indicate patient's LSL-C test results 4 weeks after starting Praluent",
                inputField: "text",
                model: "HOMO RESULTS POST",
                hasInsurance: [],
                componentParams: {
                    label: "Results Post",
                },
            },
            {
                questionLabel: "The patient experienced a 15% or greater reduction in LDL-C level from a baseline if patient suffers from Homozygous Familial Hypercholesterolemia?",
                inputField: "radio",
                expectedValue: "RENEWAL",
                model: "HOMO PATIENT REDUCTION",
                hasInsurance: [],
                componentParams: [{
                        label: "Yes",
                        value: "homoYes13"
                    },
                    {
                        label: "No",
                        value: "NO"
                    }
                ],
            },
            {
                textTitle: "If patient does not meet the above criteria, please explain why patient should continue treatment with Praluent?",
                inputField: "text",
                model: "HOMO RENEWAL CRITERIA",
                hasInsurance: [],
                componentParams: {
                    label: "Renewal Criteria",
                },
            },
        ]
    },
    {
        inputField: "text",
        model: "HOMO DIAGNOSIS RESULTS",
        hasInsurance: ['ESI02','EP02', ],
        componentParams: {
            label: "Please provide the LDL-C Test Results",
        },
    },
    {
        // datepicker
        inputField: "datepicker",
        model: "HOMO DIAGNOSIS DATE",
        hasInsurance: ['ESI02','EP02', ],
        componentParams: {
            label: "Date of Test results",
        },
    },
    {
        questionLabel: "Does the Patient have untreated LDL-C > 13mmol/L or treated LDL-C > 8mmol/L AND one of the following and one of the following: a. Cutaneous or tendon xanthoma before the age of 10  b. Untreated LDL-C levels consistent with heterozygous FH in both parents ie > 5mmol/L",
        inputField: "radio",
        model: "HOMO UNTREATED TREATED",
        childrenModels:[
            'HOMO FIRST TIME CONFIRM'
        ],
        hasInsurance: ['ESI02','EP02', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes0"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
        nodes: [{
            inputField: "text",
            expectedValue: "NO",
            model: "HOMO FIRST TIME CONFIRM",
            hasInsurance: ['ESI02','EP02', ],
            componentParams: {
                label: "Enter Text",
            },
        }]
    },
    // AS11
    {
        questionLabel: "Has the diagnosis been confirmed by History of untreated elevated LDL-C concentration >13 mmol/L and:",
        inputField: "radio",
        model: "HOMO UNTREATED TREATED",
        hasInsurance: ['AS11', ],
        componentParams: [{
                label: "Xanthomas before 10 years of age",
                value: "xanthomas"
            },
            {
                label: "Evidence of heterozygous familial hypercholesterolemia in both parents",
                value: "parents"
            }
        ],
    },
    //ESI12,GS 
    {
        questionLabel: "The treatment will be administered in combination with other lipid-lowering treatments?",
        inputField: "radio",
        model: "HOMO LIPID LOWERING",
        hasInsurance: ['ESI12','EP12', 'GS', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes1"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
    },
    {
        questionLabel: "Until now, the patient has been on a low-cholesterol diet",
        inputField: "radio",
        model: "HOMO LOW CHOLESTEROL DIET",
        hasInsurance: ['ESI12','EP12', 'GS', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes2"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
    },
    // ESI02
    {
        questionLabel: "Does the patient have LDL-C > 2mmol/L despite treatment with a maximally tolerated statin therapy",
        inputField: "radio",
        model: "HOMO PATIENT MAX",
        hasInsurance: ['ESI02','EP02', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes3"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
    },
    // ESI12
    {
        questionLabel: "For at least three months before the start of treatment, the patient's LDL cholesterol was above 2 mmol/L despite taking two or more statins",
        inputField: "radio",
        model: "HOMO PATIENT MAX",
        hasInsurance: ['ESI12','EP12', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes4"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
    },
    {
        questionLabel: "Will the treatment be administered in combination with a low-density lipoproteins (LDL) apheresis treatment?",
        inputField: "radio",
        model: "HOMO LOW DENSITY LIPO PROTINE",
        hasInsurance: ['ESI12','EP12', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes6"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
    },
    // AS11,ESI02
    {
        questionLabel: "Is the patient taking another LDL-lowering therapy?",
        inputField: "radio",
        model: "HOMO LDL LOWERING",
        hasInsurance: ['AS11', 'ESI02','EP02', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes5"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
    },
    //ESI12
    {
        questionLabel: "The patient has one or more functional mutations in both LDL receptor alleles or alleles known to affect LDL receptor functionality:",
        inputField: "radio",
        model: "HOMO ONE OR MORE FUNCTIONAL MUTATIONS",
        hasInsurance: ['ESI12','EP12', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes7"
            },
            {
                label: "No",
                value: "NO"
            }
        ],
    },
    // ESI02
    {
        questionLabel: "Has the patient demonstrated adherence to statin therapy for the previous 12 weeks?",
        inputField: "radio",
        model: "HOMO MANU CHOICE",
        childrenModels:[
            'HOMO FIRST TIME LOWERING',
            'HOMO STATIN INTOLERANT',
            'HOMO FIRST TIME CONTRAINDICATION'
        ],
        hasInsurance: ['ESI02','EP02', ],
        componentParams: [{
                label: "Has the patient demonstrated adherence to statin therapy for the previous 12 weeks?",
                value: "A"
            },
            {
                label: "Is the patient statin intolerant",
                value: "B"
            },
            {
                label: "Does the patient have an contraindication to statin therapy?",
                value: "C"
            },
        ],
        nodes: [{
                questionLabel:"Has the patient demonstrated adherence to statin therapy for the previous 12 weeks?",
                inputField: "radio",
                expectedValue: "A",
                model: "HOMO FIRST TIME LOWERING",
                hasInsurance: ['ESI02','EP02', ],
                componentParams: [{
                        label: "Yes",
                        value: "homoYes8"
                    },
                    {
                        label: "No",
                        value: "NO"
                    },
                ]
            },
            {
                questionLabel:"Is the patient statin intolerant?",
                inputField: "radio",
                expectedValue: "B",
                model: "HOMO STATIN INTOLERANT",
                hasInsurance: ['ESI02','EP02', ],
                componentParams: [{
                        label: "Yes",
                        value: "homoYes9"
                    },
                    {
                        label: "No",
                        value: "NO"
                    },
                ]
            },
            {
                questionLabel:"Does the patient have an contraindication to statin therapy?",
                inputField: "radio",
                expectedValue: "C",
                model: "HOMO FIRST TIME CONTRAINDICATION",
                hasInsurance: ['ESI02','EP02', ],
                componentParams: [{
                        label: "Yes",
                        value: "homoYes10"
                    },
                    {
                        label: "No",
                        value: "NO"
                    },
                ]
            },
        ]
    },

    {
        questionLabel: "Has the patient enrolled in My Praluent Coach Patient Support Program?",
        inputField: "radio",
        model: "HOMO FIRST TIME SUPPORT",
        hasInsurance: ['ESI02','EP02', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes11"
            },
            {
                label: "No",
                value: "NO"
            },
        ]
    },
    {
        questionLabel: "Has the My Praluent Coach Patient Support Program Reviewed this form?",
        inputField: "radio",
        model: "HOMO FIRST TIME SUPPORT REVIEW",
        hasInsurance: ['ESI02','EP02', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes12"
            },
            {
                label: "No",
                value: "NO"
            },
        ]
    },

    // ESI12
    {
        questionLabel: "The patient's LDL cholesterol was above 13 mmol/L before treatment",
        inputField: "radio",
        model: "HOMO ABOVE THIRTEEN",
        hasInsurance: ['ESI12','EP12', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes14"
            },
            {
                label: "No",
                value: "NO"
            },

        ],
    },
    {
        questionLabel: "The patient had xanthomas before age 10:",
        inputField: "radio",
        model: "HOMO XANTHOMAS TEN",
        hasInsurance: ['ESI12','EP12', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes15"
            },
            {
                label: "No",
                value: "NO"
            },
        ],
    },
    // BC, OB
    {
        questionLabel: "Is tendon xanthomas present in patient?",
        inputField: "radio",
        model: "HOMO XANTHOMAS PATIENT",
        hasInsurance: ['BC', 'OB', ],
        componentParams: [{
                label: "Yes, before age of 10 (attach evidence)",
                value: "YESBEFORE"
            },
            {
                label: "Yes, at the age of 10 or after (attach evidence)",
                value: "YESAFTER"
            },
            {
                label: "No",
                value: "NO"
            },
        ],
    },
    // ESI12
    {
        questionLabel: "Both biological parents have been diagnosed with homozygous familial hypercholesterolemia (HOFH)",
        inputField: "radio",
        model: "HOMO BOTH PARENTS",
        hasInsurance: ['ESI12','EP12', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes16"
            },
            {
                label: "No",
                value: "NO"
            },

        ],
    },
    {
        questionLabel: "Both biological parents have been diagnosed with heterozygous familial hypercholesterolemia (HEFH)",
        inputField: "radio",
        model: "HOMO HETERO BOTH PARENTS",
        hasInsurance: ['BC', 'OB', ],
        componentParams: [{
                label: "Yes",
                value: "homoYes17"
            },
            {
                label: "No",
                value: "NO"
            },

        ],
    },
];

export{
    assessmentHomo,
}
<template>
  <v-card flat>
    <v-form
      v-model="valid"
      @submit.native="updateList"
    >
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="patient.lastName"
              label="Patient Last Name"
              name="patientLastName"

              color="blue darken-2"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="patient.firstName"
              label="Patient First Name"
              name="patientFirstName"

              color="blue darken-2"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn
          text
          @click="newPatient"
        >
          Reset
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          text
          color="primary"
          type="submit"
        >
          Search
        </v-btn>
      </v-card-actions>

      <template v-if="loadingPatient">
        <v-overlay
          :opacity="0.75"
          :value="loadingPatient"
          style="z-index: 30"
        >
          <h2> Loading Data... </h2>
          <v-progress-circular
            class="centerButtons"
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </template>
    </v-form>

    <v-list v-show="isLoading">
      <v-list-item-content ripple>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-list-item-content>
    </v-list>

    <v-list
      v-show="!isLoading && list.length"
      class="overflow-y-auto"
      max-height="300"
    >
      <v-divider inset></v-divider>

      <v-list-item-content>
        <v-list-item
          v-for="(paitent, index) in list"
          :key="index"
          @click="processPatientInfo(paitent)"
        >
          <v-list-item-avatar>
            <v-icon
              medium
              color="primary"
              filled
              dark
            >
              folder_open
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ paitent.FirstName }} {{ paitent.LastName }}</v-list-item-title>
          <v-list-item-title>
            DOB:
            {{ $moment(paitent.DOB).format("DD-MM-YYYY") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-content>
    </v-list>

    <v-list v-show="!isLoading && hasSearched && !list.length && msg">
      <v-list-item ripple>
        {{ msg }}
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { Promise, timers } from 'bluebird'
import { debounce } from 'lodash'

export default {
  name: 'Patientnamesearch',
  data() {
    return {
      loadProfile: false,
      loadingPatient: false,

      progress: 0,
      list: [],
      search: {},
      msg: '',
      hasSearched: false,
      isLoading: false,
      patient: {
        firstName: '',
        lastName: '',
        dob: '',
      },
      valid: false,
    }
  },
  methods: {
    updateList(e) {
      e.preventDefault()

      this.hasSearched = false
      this.msg = ''
      this.list = [] // always reset the list

      if (this.patient.firstName.length < 2 && this.patient.lastName.length < 2) {
        return
      }

      this.debounceFetchPatientsSearch()

      return false
    },
    debounceFetchPatientsSearch() {
      // already initialized ? then use it
      if (this.debounceFunc) {
        this.debounceFunc()
      } else {
        // initialize it
        this.debounceFunc = debounce(() => {
          this.isLoading = true
          this.$root.$system.DBAdapterConn.getSearchPatientsByName({
            firstName: this.patient.firstName,
            lastName: this.patient.lastName,
          }).then(
            results => {
              this.isLoading = false
              if (results.length) {
                this.list = results
              } else {
                this.hasSearched = true
                this.msg = 'No data found'
              }
            },

            // (err)=>{
            //   this.msg = 'Error: please try again.';
            // }
          )
        }, 200)
        this.debounceFunc()
      }
    },
    processPatientInfo(patient) {
      this.loadingPatient = true

      this.loadProfile = true
      this.$emit('search-patient')

      Promise.delay(20)
        .then(() => {
          this.progress = 75
          this.$store.dispatch('resetPatientData')
          this.resetState()

          // do the request
          return this.$root.$system.DBAdapterConn.getPatientData(patient.PatientID)
        })
        .then(async patientData => {
          this.progress = 100
          await this.$store.dispatch('savePatientData', patientData)

          return patientData.PatientID
        })
        .then(PatientID => {
          this.routePatient(PatientID)
        })
    },
    routePatient(PatientID) {
      return Promise.delay(20)
        .then(() => {
          this.progress = 100
        })
        .delay(20)
        .then(() => {
          this.resetState()
          this.$emit('selected-patient', PatientID)
          this.loadingPatient = false

          // this.$router.push({
          //   name: this.$route.query.next,
          //   query: {
          //     patient: this.$route.query.options?.patient || false,
          //     patientID: PatientID,
          //     c: Math.random()
          //   }
          // }).catch(err => {
          //   // Ignore the vuex err regarding  navigating to the page they are already on.
          //   if (
          //     err.name !== 'NavigationDuplicated' &&
          //     !err.message.includes('Avoided redundant navigation to current location')
          //   ) {
          //     // But print any other errors to the console
          //     console.error(err);
          //   }
          // });
        })
    },
    newPatient() {
      this.$store.dispatch('resetPatientData')
      this.resetState()
    },
    resetState() {
      this.progress = 0
      this.loadProfile = false
      this.list = []
      this.patient.firstName = ''
      this.patient.lastName = ''
    },
  },
}
</script>

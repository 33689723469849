<template>
  <v-col
    v-if="
      (expectedValue === null || previousValue === expectedValue) &&
        questionStatus
    "
    xs="12"
    sm="12"
    md="12"
  >
    <!-- {{ previousChange }} -->
    <!-- {{ childRenderCondition }}
    {{ questionStatus }} -->
    <div :style="indent">
      <!-- for prefilled/readonly -->
      <h3 v-if="inputField === 'checkbox' || 'text' || 'datepicker'">
        {{ textTitle }}
      </h3>
      <v-text-field
        v-if="inputField === 'readonly'"
        :label="componentParams.label"
        :value="componentParams.value"
        :rules="rules"
        dense
        readonly
        @input="updateMessage"
      ></v-text-field>

      <v-text-field
        v-if="inputField === 'text'"
        :label="componentParams.label"
        :value="message"
        :rules="rules"
        type="text"
        dense
        outlined
        @input="updateMessage"
      ></v-text-field>

      <v-radio-group
        v-if="inputField === 'radio'"
        :value="message"
        @change="updateMessage"
      >
        <div class="subtitle-1 mb-4">
          {{ questionLabel }}
        </div>
        <v-radio
          v-for="(componentParam, index) in componentParams"
          :key="index"
          :label="componentParam.label"
          :value="componentParam.value"
        ></v-radio>
      </v-radio-group>

      <v-checkbox
        v-if="inputField === 'checkbox'"
        :label="componentParams[0].label"
        :value="message"
        :true-value="componentParams[0].value"
        false-value
        @change="updateMessage"
      >
      </v-checkbox>

      <v-select
        v-if="inputField === 'select'"
        :label="componentParams[0].label"
        :items="items"
        :value="message"
        @change="updateMessage"
      ></v-select>

      <template v-if="inputField === 'datepicker'">
        <v-menu
          :value="!updateMessage"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="message ? $moment(message).format('YYYY-MM-DD') : message"
              :label="componentParams.label"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              @input="updateMessage"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :value="message ? $moment(message).format('YYYY-MM-DD') : message"
            show-current
            reactive
            @input="updateMessage"
          ></v-date-picker>
        </v-menu>
      </template>
    </div>
    <template>
      <tree-menu
        v-for="(node, index) in nodes"
        :key="index"
        :nodes="node.nodes"
        :question-label="node.questionLabel"
        :component-params="node.componentParams"
        :input-field="node.inputField"
        :model="node.model"
        :expected-value="node.expectedValue"
        :previous-value="currentValue"
        :rules="node.rules"
        :has-insurance="node.hasInsurance"
        :depth="depth + 1"
        :items="node.items"
        :children-models="node.childrenModels"
        :text-title="node.textTitle"
      >
      </tree-menu>
    </template>
  </v-col>
</template>
<script>
import { mapState } from 'vuex'

/*
TODO:
- make a checkbox field in recursive tree
- make a date picker component in recursive tree
- add v-select component from Jamies code
*/
export default {
    name: 'TreeMenu',
    props: [
    // "key",
        'questionLabel',
        'additionalIndex',
        'inputField',
        'model',
        'nodes',
        'componentParams',
        'expectedValue',
        'previousValue',
        'depth',
        'rules',
        'hasInsurance',
        'items',
        'childRenderCondition',
        'childrenModels',
        'textTitle'
    ],
    data() {
        return {
            currentValue: '',
        }
    },
    computed: {
        message() {
            return this.$store.state.Praluent.praluentPatient[this.model]
        },

        displayQ() {
            return this.$store.getters.GET_PATIENTCODE
        },
        questionStatus() {
            return this.verifyInsurancePresence()
        },
        indent() {
            return { transform: `translate(${this.depth * 25}px)` }
        },
    },
    mounted() {
        if (this.message) {
            this.currentValue = this.message
        }
    },

    updated() {},
    methods: {
        updateMessage(e) {
            this.currentValue = e
            this.$store.commit('SET_PRALUENT_SINGLE_STATE', {
                key: `${this.model}`,
                value: e,
            })
            this.resetChildrenValues()
        },
        verifyInsurancePresence() {
            if (this.hasInsurance) {
                if (this.hasInsurance.length > 0) {
                    return this.hasInsurance.includes(
                        this.$store.getters.GET_PATIENTCODE
                    )
                } if (this.hasInsurance <= 0 || this.hasInsurance == false) {
                    return true
                }
                console.log('Recursive Tree Multiple', 'There are problems ')
            } else {
                return true
            }
        },
        resetChildrenValues() {
            if (
                (this.inputField === 'radio' || this.inputField === 'check')
        && this.childrenModels
            ) {
                const resetModels = {}
                console.log('ChildModel exists', this.childrenModels)
                for (const model of this.childrenModels) {
                    // console.log(model);
                    Object.assign(resetModels, { [`${model}`]: '' })
                }
                console.log(resetModels)
                this.$store.commit('SET_PRALUENT_OVERLAY_DATA', resetModels)
            }
        },
    },
}
</script>

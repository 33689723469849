const assessmentHetero = [
    {
        questionLabel:"Initial or renewal",
        inputField:"radio",
        model: "HETERO INITIAL RENEWAL",
        childrenModels:[
            'HETERO RESULTS PRIOR',
            'HETERO RESULTS POST',
            'HETERO PATIENT REDUCTION',
            'HETERO RENEWAL CRITERIA'
        ],
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Initial",
                value:"INITIAL"
            },
            {
                label: "Renewal",
                value:"RENEWAL"
            }
        ],
        nodes:[
            {
                textTitle:"Indicate patient's LDL-C test results prior to starting Praluent",
                inputField:"text",
                expectedValue:"RENEWAL",
                model: "HETERO RESULTS PRIOR",
                hasInsurance:[],
                componentParams: 
                    {
                        label: "Results Prior",
                    }
            },
            {
                textTitle:"Indicate patient's LDL-C test results 4 weeks after starting Praluent",
                inputField:"text",
                expectedValue:"RENEWAL",
                model: "HETERO RESULTS POST",
                hasInsurance:[],
                componentParams: 
                    {
                        label: "Results Post",
                    }
            },
            {
                questionLabel:"The patient experienced a 15% or greater reduction in LDL-C level from a baseline if patient suffers from Homozygous Familial Hypercholesterolemia?",
                inputField:"radio",
                expectedValue:"RENEWAL",
                model: "HETERO PATIENT REDUCTION",
                hasInsurance:['ESI02','EP02'],
                componentParams: [
                    {
                        label: "Yes",
                        value:"heteroYes23"
                    },
                    {
                        label: "No",
                        value:"NO"
                    }
                ],
            },
            {
                textTitle:"If patient does not meet the above criteria, please explain why patient should continue treatment with Praluent?",
                inputField:"text",
                expectedValue:"RENEWAL",
                model: "HETERO RENEWAL CRITERIA",
                hasInsurance:[],
                componentParams: 
                    {
                        label: "Renewal Criteria",
                    }
            },
        ]
    },
    {
        questionLabel:"The treatment will be administered in combination with the maximum dose of statin tolerated",
        inputField:"radio",
        model: "HETERO COMBO MAX STATIN",
        hasInsurance:['ESI12','EP12',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes0"
            },
            {
                label: "No",
                value:"NO"
            }
        ]
    },
    {
        inputField:"text",
        model: "HETERO DIAGNOSIS RESULTS",
        hasInsurance:['ESI02','EP02',],
        componentParams: 
            {
                label: "Please provide the LDL-C Test Results",
            }
    },
    {
        // datepicker
        inputField:"datepicker",
        model: "HETERO DIAGNOSIS DATE",
        hasInsurance:['ESI02','EP02',],
        componentParams: 
            {
                label: "Date of Test results",
            }
    },
    // GS
    {
        questionLabel:"Will PCSK9 inhibitor will be used with Statin Therapy",
        inputField:"radio",
        model: "HETERO STATIN THERAPY",
        childrenModels:[
            'HETERO STATIN THERAPY DIAGNOSTIC',
            'HETERO STATIN THERAPY RESULTS'
        ],
        hasInsurance:['ESI12','EP12',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes1"
            },
            {
                label: "No",
                value:"NO"
            }
        ],
        nodes:[
            {
                inputField: "select",
                expectedValue: 'heteroYes1',
                model: "HETERO STATIN THERAPY DIAGNOSTIC",
                hasInsurance: ['GS'],
                items: [
                    {
                        text: "Simon Broome",
                        value: "Simon Broome"
                    },
                    {
                        text: "Dutch Lipid Profile",
                        value: "Dutch Lipid Profile"
                    }
                ],
                componentParams:[
                        {
                            label: "Diagnositc Criteria Used",
                        }
                    ],    
            },
            {
                inputField:"text",
                expectedValue:"heteroYes1",
                model: "HETERO STATIN THERAPY RESULTS",
                hasInsurance:['GS',],
                componentParams: 
                    {
                        label: "Results",
                    }
            }
        ]
    },
    {
        questionLabel:"Until now, the patient has been on a low-cholesterol diet",
        inputField:"radio",
        model: "HETERO LOW CHOLESTEROL DIET",
        hasInsurance:['ESI12','EP12', 'AS11',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes2"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"For at least three months before the start of treatment, the patient's LDL cholesterol was above 2 mmol/L despite taking two or more statins",
        inputField:"radio",
        model: "HETERO PATIENT MAX",
        hasInsurance:['ESI12','EP12',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes3"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Does the patient have LDL-C > 2mmol/L despite treatment with a maximally tolerated statin therapy",
        inputField:"radio",
        model: "HETERO PATIENT MAX",
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes4"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"The patient's total cholesterol is above 7.5 mmol/L or their LDL cholesterol is above 4.9 mmol/L:",
        inputField:"radio",
        model: "HETERO ABOVE SEVEN BELOW FIVE",
        hasInsurance:['ESI12','EP12', 'AS11',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes5"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"The patient, an immediate family member or a second degree relative (parent, brother, sister, grandparent, aunt or uncle) has been diagnosed with tendon xanthomas",
        inputField:"radio",
        model: "HETERO IMMEDIATE FAMILY GENERAL",
        hasInsurance:['ESI12','EP12', 'AS11',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes6"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    // BC/OB
    {
        questionLabel:"HeFh associated with atherosclerotic cardiovascular disease:",
        inputField:"radio",
        model: "HETERO CARDIO ASSOCIATION",
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes7"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Is tendon xanthomas present?",
        inputField:"radio",
        model: "HETERO XANTHOMAS PRESENT",
        childrenModels:[
          'HETERO XANTHOMAS PRESENT SPECIFICATION'  
        ],
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "In patient",
                value:"heteroYes8"
            },
            {
                label: "In 1st degree relative",
                value:"heteroFirst0"
            },
            {
                label: "In 2nd degree relative",
                value:"heteroSecond0"
            },
            {
                label: "Not present",
                value:"NO"
            },
            {
                label: "Other",
                value:"heteroOther0"
            },
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"heteroOther0",
                model: "HETERO XANTHOMAS PRESENT SPECIFICATION",
                hasInsurance:['BC','OB',],
                componentParams: 
                    {
                        label: "Specify",
                    }
            }
        ]
    },
    // ESI12
    {
        questionLabel:"DNA-based evidence of a gene mutation in LDL receptors or another FH-related gene found:",
        inputField:"radio",
        model: "HETERO GENE MUTATION EVIDENCE",
        hasInsurance:['ESI12','EP12',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes9"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    // ESI12/AS11
    {
        questionLabel:"The patient has a family history of myocardial infarction before age 50 in a second degree relative, or before age 60 in an immediate family member:",
        inputField:"radio",
        model: "HETERO FAMILY HISTORY BEFORE FIFTY BEFORE SIXTY",
        hasInsurance:['ESI12','EP12','AS11',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes10"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    // BC/OB
    {
        questionLabel:"Presence of family history of myocardial infarction (MI)?",
        inputField:"radio",
        model: "HETERO FAMILY HISTORY MI",
        childrenModels:[
            'HETERO FAMILY HISTORY MI SPECIFY'
        ],
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "Before the age of 60 in 1st degree relative",
                value:"BEFORESIXTY"
            },
            {
                label: "Before the age of 50 in a 2nd degree relative",
                value:"BEFOREFIFTY"
            },
            {
                label: "No family history of Mi",
                value:"NO"
            },
            {
                label: "Other",
                value:"heteroOther1"
            },
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"heteroOther1",
                model: "HETERO FAMILY HISTORY MI SPECIFY",
                hasInsurance:['BC','OB',],
                componentParams: 
                    {
                        label: "Specify",
                    }
            }
        ]
    },
    {
        questionLabel:"Presence of family history of total cholesterol concentration",
        inputField:"radio",
        model: "HETERO FAMILY HISTORY CONCENTRATION",
        childrenModels:[
            'HETERO FAMILY HISTORY CONC SEVEN',
            'HETERO FAMILY HISTORY CONC SEVEN SPECIFY',
            'HETERO FAMILY HISTORY CONC SIX',
            'HETERO FAMILY HISTORY CONC SIX SPECIFY'
        ],
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "> 7.5 mmol/L in an adult relative:",
                value:"SEVEN"
            },
            {
                label: "6.7 mmol/L in a relative of the less than 16 years of age:",
                value:"SIX"
            },
        ],
        nodes:[
            {
                questionLabel:"",
                inputField:"radio",
                expectedValue:"SEVEN",
                model: "HETERO FAMILY HISTORY CONC SEVEN",
                hasInsurance:['BC','OB',],
                componentParams: [
                    {
                        label: "of 1st degree",
                        value:"heteroFirst1"
                    },
                    {
                        label: "of 2nd degree",
                        value:"heteroSecond1"
                    },
                    {
                        label: "Other",
                        value:"heteroOther2"
                    },
                ],
                nodes:[
                    {
                        inputField:"text",
                        expectedValue:"heteroOther2",
                        model: "HETERO FAMILY HISTORY CONC SEVEN SPECIFY",
                        hasInsurance:['BC','OB',],
                        componentParams: 
                            {
                                label: "Specify",
                            }
                    }
                ]
            },
            {
                questionLabel:"",
                inputField:"radio",
                expectedValue:"SIX",
                model: "HETERO FAMILY HISTORY CONC SIX",
                hasInsurance:['BC','OB',],
                componentParams: [
                    {
                        label: "of 1st degree",
                        value:"heteroFirst2"
                    },
                    {
                        label: "of 2nd degree",
                        value:"heteroSecond2"
                    },
                    {
                        label: "Other",
                        value:"heteroOther3"
                    },
                ],
                nodes:[
                    {
                        inputField:"text",
                        expectedValue:"heteroOther3",
                        model: "HETERO FAMILY HISTORY CONC SIX SPECIFY",
                        hasInsurance:['BC','OB',],
                        componentParams: 
                            {
                                label: "Specify",
                            }
                    }
                ]
            }
        ]
    },
    {
        questionLabel:"Presence of familiy history of LDL-C concentration: of",
        inputField:"radio",
        model: "HETERO FAMILY HISTORY LDL CONCENTRATION",
        childrenModels:[
            'HETERO FAMILY HISTORY LDL CONC FIVE',
            'HETERO FAMILY HISTORY LDL CONC FIVE SPECIFY',
            'HETERO FAMILY HISTORY LDL CONC FOUR',
            'HETERO FAMILY HISTORY LDL CONC FOUR SPECIFY'
        ],
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "> 4.9 mmol/L in an adult relative:",
                value:"FIVE"
            },
            {
                label: "> 4.0 mmol/L in a relative of the less than 18 years of age:",
                value:"FOUR"
            },

        ],
        nodes:[
            {
                questionLabel:"",
                inputField:"radio",
                expectedValue:"FIVE",
                model: "HETERO FAMILY HISTORY LDL CONC FIVE",
                hasInsurance:['BC','OB',],
                componentParams: [
                    {
                        label: "of 1st degree",
                        value:"heteroFirst3"
                    },
                    {
                        label: "of 2nd degree",
                        value:"heteroSecond3"
                    },
                    {
                        label: "Other",
                        value:"heteroOther4"
                    },

                ],
                nodes:[
                    {
                        inputField:"text",
                        expectedValue:"heteroOther4",
                        model: "HETERO FAMILY HISTORY LDL CONC FIVE SPECIFY",
                        hasInsurance:['BC','OB',],
                        componentParams: 
                            {
                                label: "Specify",
                            }
                    }
                ]
            },
            {
                questionLabel:"",
                inputField:"radio",
                expectedValue:"FOUR",
                model: "HETERO FAMILY HISTORY LDL CONC FOUR",
                hasInsurance:['BC','OB',],
                componentParams: [
                    {
                        label: "of 1st degree",
                        value:"heteroFirst4"
                    },
                    {
                        label: "of 2nd degree",
                        value:"heteroSecond4"
                    },
                    {
                        label: "Other",
                        value:"heteroOther5"
                    },
                ],
                nodes:[
                    {
                        inputField:"text",
                        expectedValue:"heteroOther5",
                        model: "HETERO FAMILY HISTORY LDL CONC FOUR SPECIFY",
                        hasInsurance:['BC','OB',],
                        componentParams: 
                            {
                                label: "Specify",
                            }
                    }
                ]
            },
        ]
    },
    {
        questionLabel:"Presence of a corneal arcus before age 45:",
        inputField:"radio",
        model: "HETERO PRESENCE OF CORNEAL",
        childrenModels:[
            'HETERO PRESENCE OF CORNEAL SPECIFY'
        ],
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "In 1st degree relative",
                value:"heteroFirst5"
            },
            {
                label: "In 2nd degree relative",
                value:"heteroSecond5"
            },
            {
                label: "Not present",
                value:"NO"
            },
            {
                label: "Other",
                value:"heteroOther6"
            },
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"heteroOther6",
                model: "HETERO PRESENCE OF CORNEAL SPECIFY",
                hasInsurance:['BC','OB',],
                componentParams: 
                    {
                        label: "Specify",
                    }
            }
        ]
    },
    {
        questionLabel:"Presence of a mutation, causing a familial hypercholesterolemia, of the LDLR, ApoB or PCSK9 genes*:",
        inputField:"radio",
        model: "HETERO PRESENCE OF MUTATION",
        childrenModels:[
            'HETERO PRESENCE OF MUTATION SPECIFY'
        ],
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "In 1st degree relative",
                value:"heteroFirst6"
            },
            {
                label: "In 2nd degree relative",
                value:"heteroSecond6"
            },
            {
                label: "Not present",
                value:"NO"
            },
            {
                label: "Other",
                value:"heteroOther7"
            },
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"heteroOther7",
                model: "HETERO PRESENCE OF MUTATION SPECIFY",
                hasInsurance:['BC','OB',],
                componentParams: 
                    {
                        label: "Specify",
                    }
            }
        ]
    },
    {
        questionLabel:"Presence of family history of heterozygous familial hypercholesterolemia (HeFH):",
        inputField:"radio",
        model: "HETERO PRESENCE OF HETERO",
        childrenModels:['HETERO PRESENCE OF HETERO SPECIFY'],
        hasInsurance:['BC','OB',],
        componentParams: [
            {
                label: "In 1st degree relative",
                value:"heteroFirst7"
            },
            {
                label: "In 2nd degree relative",
                value:"heteroSecond7"
            },
            {
                label: "Not present",
                value:"NO"
            },
            {
                label: "Other",
                value:"heteroOther8"
            },
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"heteroOther8",
                model: "HETERO PRESENCE OF HETERO SPECIFY",
                hasInsurance:['BC','OB',],
                componentParams: 
                    {
                        label: "Specify",
                    }
            }
        ]
    },
    // ESi12/AS11
    {
        questionLabel:"The patient has a family history of total cholesterol above 7.5 mmol/L in an immediate family member or a second degree relative:",
        inputField:"radio",
        model: "HETERO HIGH CHOLESTEROL",
        hasInsurance:['ESI12','EP12', 'AS11',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes14"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    //ESI02
    {
        questionLabel:"Does the patient meet the Simon-Broome Diagnostic Criteria for deinite Familial hypercholesterolemia",
        inputField:"radio",
        model: "HETERO SIMON BROOME",
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes15"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Does the Patient have a Dutch Lipid Clinic Network Criteria Score > 8",
        inputField:"radio",
        model: "HETERO DUTCH LIPID SCORE",
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes16"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        textTitle:"How was the diagnosis confirmed?",
        inputField:"text",
        model: "HETERO FIRST TIME CONFIRM",
        hasInsurance:['ESI02','EP02',],
        componentParams: 
            {
                label: "Diagnosis Confirmation",
            }
    },
    {
        questionLabel:"Is the patient taking another LDL-lowering therapy?",
        inputField:"radio",
        model: "HETERO LDL LOWERING",
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes17"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Has the patient demonstrated adherence to statin therapy for the previous 12 weeks?",
        inputField:"radio",
        model: "HETERO ADHERENCE STATIN",
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes18"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Is the patient statin intolerant?",
        inputField:"radio",
        model: "HETERO STATIN INTOLERANT",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes19"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Does the patient have an contraindication to statin therapy?",
        inputField:"radio",
        model: "HETERO FIRST TIME CONTRAINDICATION",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes20"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Has the patient enrolled in My Praluent Coach Patient Support Program?",
        inputField:"radio",
        model: "HETERO FIRST TIME SUPPORT",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes21"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Has the My Praluent Coach Patient Support Program Reviewed this form?",
        inputField:"radio",
        model: "HETERO FIRST TIME SUPPORT REVIEW",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"heteroYes22"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
];

export{
    assessmentHetero
}
const patientSupport = [{
            questionLabel: "Is the patient enrolled in a Patient Assistance Program",
            inputField: "radio",
            model: "PATIENT ASSISTANCE RADIO",
            childrenModels:[
                'PATIENT ASSISTANCE PROGRAM NAME',
                'PATIENT ASSISTANCE ID',
                'PATIENT ASSISTANCE CONTACT NAME',
                'PATIENT ASSISTANCE TELEPHONE',
                'PATIENT ASSISTANCE FAX'
            ],
            hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'GS', 'ESI02','EP02', 'BC', 'OB', 'RXP', 'CS', 'AS16'],
            componentParams: [{
                    label: "Yes",
                    value: "patientAssistanceYes"
                },
                {
                    label: "No",
                    value: "patientAssistanceNo"
                },
            ],
            nodes: [{
                    inputField: "readonly",
                    model: "PATIENT ASSISTANCE PROGRAM NAME",
                    expectedValue: "patientAssistanceYes",
                    hasInsurance: ['ESI12','EP12', 'AS11', 'GS', 'BC', 'OB', 'AS16'],
                    componentParams: {
                        label: "Patient Assistance Program Name",
                        value: "MyPraluent Coach"
                    }
                },
                {
                    inputField: "text",
                    model: "PATIENT ASSISTANCE ID",
                    expectedValue: "patientAssistanceYes",
                    hasInsurance: ['ESI12','EP12', 'AS11', 'GS', 'ESI02','EP02', 'BC', 'OB', 'RXP', 'CS'],
                    componentParams: {
                        label: "Patient Assistance ID"
                    }
                },
                {
                    inputField: "text",
                    model: "PATIENT ASSISTANCE CONTACT NAME",
                    expectedValue: "patientAssistanceYes",
                    hasInsurance: ['ESI12','EP12','ESI', 'AS11', 'GS', 'ESI02','EP02', 'RXP', 'CS'],
                    componentParams: {
                        label: "Patient Assistance Contact Name"
                    }
                },
                {
                    inputField: "text",
                    model: "PATIENT ASSISTANCE TELEPHONE",
                    expectedValue: "patientAssistanceYes",
                    hasInsurance: ['ESI12','EP12','ESI', 'AS11', 'GS', 'ESI02','EP02', 'BC', 'OB', 'RXP', 'CS'],
                    componentParams: {
                        label: "Patient Assistance Telephone #"
                    }
                },
                {
                    inputField: "text",
                    model: "PATIENT ASSISTANCE FAX",
                    expectedValue: "patientAssistanceYes",
                    hasInsurance: ['BC', 'OB', 'ESI02','EP02'],
                    componentParams: {
                        label: "Patient Assistance Fax #"
                    }
                },
                {   
                    questionLabel:"Do you provide consent to allow Express Scripts Canada to contact the patient support program for obtaining addiotional information for purpose of completing this prior authorization evaluation, if required?",
                    inputField: "radio",
                    model: "expressScriptsConsent",
                    expectedValue: "patientAssistanceYes",
                    hasInsurance: ['ESI','EP'],
                    componentParams: [
                        {
                            label: "Yes",
                            value:"expressConsentYes"
                        },
                        {
                            label: "No",
                            value:"expressConsentNo"
                        },
                    ]
                },
            ]
        },
       
        {
            questionLabel: "Has patient enrolled in a PAP for Praluent?",
            inputField: "radio",
            model: "PREVIOUSREPATHARADIO",
            childrenModels:[
                'PATIENT ASSISTANCE PROGRAM NAME',
                'PATIENT ASSISTANCE ID',
                'PATIENT ASSISTANCE CONTACT NAME',
                'PATIENT ASSISTANCE TELEPHONE',
                'PREVIOUSREPATHARADIOCALL'
            ],
            hasInsurance: ['AS11'],
            componentParams: [{
                    label: "Yes",
                    value: "gwlPAPyes"
                },
                {
                    label: "No",
                    value: "gwlPAPno"
                },
            ],
            nodes: [{
                    inputField: "text",
                    model: "PATIENT ASSISTANCE ID",
                    expectedValue: "gwlPAPyes",
                    hasInsurance: ['AS11'],
                    componentParams: {
                        label: "Patient Assistance ID"
                    }
                },
                {
                    inputField: "text",
                    model: "PATIENT ASSISTANCE CONTACT NAME",
                    expectedValue: "gwlPAPyes",
                    hasInsurance: ['AS11'],
                    componentParams: {
                        label: "Patient Assistance Contact Name"
                    }
                },
                {
                    inputField: "text",
                    model: "PATIENT ASSISTANCE TELEPHONE",
                    expectedValue: "gwlPAPyes",
                    hasInsurance: ['AS11'],
                    componentParams: {
                        label: "Patient Assistance Telephone #"
                    }
                },
                {
                    questionLabel: "Has a phone call between the patient assistance program, the plan member and Great-West Life occured regarding coverage available through your group benefit plan?",
                    inputField: "radio",
                    expectedValue: "gwlPAPyes",
                    model: "PREVIOUSREPATHARADIOCALL",
                    hasInsurance: ['AS11'],
                    componentParams: [{
                            label: "Yes",
                            value: "gwlPhoneCallYes"
                        },
                        {
                            label: "No",
                            value: "gwlPhoneCallNo"
                        },
                    ],
                    nodes: [{
                            inputField: "text",
                            expectedValue: "gwlPhoneCallYes",
                            model: "PATIENT ASSISTANCE CONTACT NAME",
                            hasInsurance: ['AS11'],
                            componentParams: {
                                label: "Patient Assistance Contact Name",
                            }, 
                        },
                        {
                            inputField: "text",
                            expectedValue: "gwlPhoneCallYes",
                            model: "PATIENT ASSISTANCE TELEPHONE",
                            hasInsurance: ['AS11'],
                            componentParams: {
                                label: "Patient Assistance Telephone #",
                            }, 
                        }
                    ]
                }
            ]
        },
        {
            questionLabel: "Are you currently receiving disability benefits for the condition for which this drug has been prescribed?",
            inputField: "radio",
            model: "DISABILITY BENEFITS",
            hasInsurance: ['AS11'],
            componentParams: [{
                    label: "Yes",
                    value: "gwlDisabilityYes"
                },
                {
                    label: "No",
                    value: "gwlDisabilityNo"
                },
            ],
        }
    ]

    export {
        patientSupport
    }
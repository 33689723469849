const educationInfoSub = [
    {
        questionLabel:"Is patient a full-time student?",
        inputField:"radio",
        model:"studentRadio",
        childrenModels:[
            'EDUCATION NAME',
            'EDUCATION START',
            'EDUCATION END',
            'EDUCATION UNIT NUMBER',
            'EDUCATION STREET NUMBER',
            'EDUCATION STREET NAME',
            'EDUCATION CITY',
            'EDUCATION PROVINCE',
            'EDUCATION POSTAL',
            'EDUCATION ENROLLMENT HOURS'
            ],
        hasInsurance:['AS16'],
        componentParams:[
          {
            label:"Yes",
            value:"studentYes"
          },
          {
            label:"No",
            value:"studentNo"
          },
        ],
        nodes:[
            {
                inputField:"text",
                model:"EDUCATION NAME",
                expectedValue:"studentYes",
                hasInsurance:['AS16'],
                componentParams: 
                    {
                        label: "Educational Institution Name",
                    },
                
            },
            {
                inputField:"datepicker",
                model:"EDUCATION START",
                expectedValue:"studentYes",
                hasInsurance:['AS16'],
                componentParams: 
                    {
                        label: "Indicate Start Date",
                    },
                
            },
            {
                inputField:"datepicker",
                model:"EDUCATION END",
                expectedValue:"studentYes",
                hasInsurance:['AS16'],
                componentParams: 
                    {
                        label: "Indicate End Date",
                    },
                
            },
            {
                inputField: "text",
                model: "EDUCATION UNIT NUMBER",
                expectedValue:"studentYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Unit Number",
                    },
                
            },
            {
                inputField: "text",
                model: "EDUCATION STREET NUMBER",
                expectedValue:"studentYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Street Number",
                    },
                
            },
            {
                inputField: "text",
                model: "EDUCATION STREET NAME",
                expectedValue:"studentYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Street Name",
                    },
                
            },
            {
                inputField: "text",
                model: "EDUCATION CITY",
                expectedValue:"studentYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "City",
                    },
                
            },
            {
                inputField: "text",
                model: "EDUCATION PROVINCE",
                expectedValue:"studentYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Province",
                    },
                
            },
            {
                inputField: "text",
                model: "EDUCATION POSTAL",
                expectedValue:"studentYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Postal Code",
                    },
                
            },
            {
                inputField: "text",
                model: "EDUCATION ENROLLMENT HOURS",
                expectedValue:"studentYes",
                hasInsurance: ['AS16'],
                componentParams:
                    {
                        label: "Number of hours enrolled per week",
                    },
                
            },
        ]
    },
    
];


export {
    educationInfoSub
}
const docInfoSub = [{
        questionLabel: "Do you want to be informed of the decision?",
        inputField: "radio",
        model: "expressInformDecisionRadio",
        hasInsurance: ['ESI', 'EP'],
        componentParams: [{
                label: "Yes",
                value: "expressInformYes"
            },
            {
                label: "No",
                value: "expressInformNo"
            },
        ]
    }, {
        questionLabel: "Was a specialist involved in this treatment?",
        inputField: "radio",
        model: "IAspecialistInvolved",
        hasInsurance: ['ESI11'],
        componentParams: [{
                label: "Yes",
                value: "IASpecialistYes"
            },
            {
                label: "No",
                value: "IASpecialistNo"
            },
        ]
    }, {
        questionLabel: "Physician choice",
        inputField: "radio",
        model: "IASpecialistChoice",
        childrenModels:[
            'SPECIALTY TEXT'
        ],
        hasInsurance: ['ESI11'],
        componentParams: [{
                label: "General",
                value: "IASpecialistGeneral"
            },
            {
                label: "Specialist",
                value: "IASpecialistS"
            },
            {
                label: "Other",
                value: "IASpecialistOther"
            },
        ],
        nodes: [{
            inputField: "text",
            model: "SPECIALTY TEXT",
            expectedValue: "IASpecialistOther",
            hasInsurance: ['ESI11'],
            componentParams: {
                label: "Specify specialty"
            }
        }]
    }, {
        questionLabel: "Is patient receiving or will receive treatment in a hospital setting?",
        inputField: "radio",
        model: "MED IN HOSPITAL",
        hasInsurance: ['ESI12','EP12', 'BC', 'OB'],
        componentParams: [{
                label: "Yes",
                value: "medInHospitalYes"
            },
            {
                label: "No",
                value: "medInHospitalNo"
            }
        ],
    },

]
export {
    docInfoSub
}

import {
    labService
} from '@/render/api/zarya/index';

const initialState = {
    labRecord: {
        pharmacyID: '',
        userID: '',
        patientID: '',
        attributes: {},
        consent: false,
        status: null,
    }
}

const email = {
    to: 'info@pharmaguide.ca', // "faddy@pharmaguide.ca",//  // faddy@pharmaguide.ca;
    from: 'lab@pharmaguide.ca',
}

const state = Object.assign({}, initialState)

const mutations = {
    SET_LAB_RESULTS(state, payload) {
        state.labRecord = {
            ...state.labRecord,
            ...payload
        };
    },
    RESET_LAB_RESULTS(state) {
        Object.assign(state, initialState);
    }
};

const actions = {
    async requestLabResults({
        commit,
        state,
        dispatch,
        rootGetters // FIXME only for email
    }, data) {

        // transform array of attributes into object properties
        const attributes = data.reduce((cur, key) => {
            return {
                ...cur,
                [key]: state.labRecord.attributes[key] || '' // if it exists, use it
            };
        }, {})
        // );

        const lab = await labService.requestLab({
            userID: rootGetters.UserProfile._id,
            pharmacyID: rootGetters.PharmacyProfile._id,
            patientID: rootGetters.PatientProfile.PatientID,
            attributes: attributes
        });

        // FIXME move this to its own actions and store
        // send an email
        const profile = rootGetters.PatientProfile;
        await dispatch('sendMail', {
            ...email,
            subject: `Lab results: ${profile['PHARMACY NAME']} requesting for ${profile['FIRST NAME']} ${profile['FIRST NAME']}` ,
            html: `
                <p>The following lab results are consented for request:</p>
                <dl>
                    <dt><strong>Lab ID</strong></dt>
                    <dd>${lab['_id']}</dd>
                    <dt><strong>Patient ID</strong></dt>
                    <dd>${profile['PatientID']}</dd>
                    <dt><strong>Name</strong></dt>
                    <dd>${profile['FIRST NAME']} ${profile['LAST NAME']}</dd>
                    <dt><strong>Date of birth</strong></dt>
                    <dd>${(new Date(profile['DATE OF BIRTH'])).toISOString().split('T')[0]}</dd>
                    <dt><strong>Lab Data</strong></dt>
                    <dd>${Object.keys(attributes).join(',')} </dd>
                    <dt><strong>OHIP</strong></dt>
                    <dd>${profile['HEALTH CARD NUMBER']}</dd>
                    <dt><strong>Phone</strong></dt>
                    <dd>${profile['TELEPHONE NUMBER']}</dd>
                    <dt><strong>email</strong></dt>
                    <dd>${profile['EMAIL ADDRESS']}</dd>
                </dl>

                <p>The <b>pharmacy info</b> as follows:</p>
                <dl>
                    <dt><strong>Name</strong></dt>
                    <dd>${profile['PHARMACY NAME']}</dd>
                    <dt><strong>Phone</strong></dt>
                    <dd>${profile['PHARMACY TELEPHONE NUMBER']}</dd>
                </dl>

                <p>The <b>Doctor info</b> as follows:</p>
                <dl>
                    <dt><strong>Name</strong></dt>
                    <dd>${profile['PRIMARY FIRST NAME']} ${profile['PRIMARY LAST NAME']}</dd>
                    <dt><strong>Phone</strong></dt>
                    <dd>${profile['PRIMARY PHONE #']}</dd>
                    <dt><strong>Fax</strong></dt>
                    <dd>${profile['PRIMARY FAX #']}</dd>
                </dl>
            `
        });


        await commit('SET_LAB_RESULTS', lab);

        // save functionality with server side
        return lab;
    },
    async getLabResults({
        commit,
        state
    }, labID) {
        let lab;
        try{
            lab = await labService.getLabByID(labID);
        }catch(err){}

        commit('SET_LAB_RESULTS', lab);

        return state;
    },
    async getLabByPatientAttributes({
        commit,
        state
    }, {attributes, patientID, pharmacyID}) {
        let lab;
        try{
            lab = await labService.getLabByPatientAttributes({attributes, patientID, pharmacyID});
        }catch(err){}

        commit('SET_LAB_RESULTS', lab);

        return state;
    },
    setLab({
        commit
    }) {
        commit('SET_LAB_RESULTS', lab);
    },
    resetLab({
        commit
    }) {
        commit('RESET_LAB_RESULTS');
    }
}

const getters = {
    LabRecord(state) {
        return state.labRecord;
    },
    IsLabRecordRequest(state) {
        return state.labRecord.status;
    },
    IsLabRecordAvailable(state) {
        return state.labRecord.status === 'completed';
    }
}


export default {
    state,
    mutations,
    actions,
    getters
};
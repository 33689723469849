<template>
  <div>
    <h2>Praluent Process Form</h2>
    <div>
      <v-stepper
        v-model="step"
        non-linear
      >
        <v-stepper-header class="">
          <v-stepper-step
            id="v-step-0"
            edit-icon="check"
            step="1"
            :complete="step > 1"
          >
            Personal Information
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            edit-icon="check"
            step="2"
            :complete="step > 2"
          >
            Drug Requested
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            edit-icon="check"
            step="3"
            :complete="step > 3"
          >
            Medication Information
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            edit-icon="check"
            step="4"
            :complete="step > 4"
          >
            Assessment
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  right
                  @click="nextStep()"
                >
                  Continue
                </v-btn>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <patientInfo></patientInfo>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <memberInfo></memberInfo>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  right
                  @click="nextStep()"
                >
                  Continue
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-container>
              <v-row>
                <v-btn
                  color="primary"
                  @click="previousStep()"
                >
                  Previous
                </v-btn>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="nextStep()"
                >
                  Continue
                </v-btn>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <pharmacistInfo></pharmacistInfo>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <doctorInfo></doctorInfo>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <drugRequested></drugRequested>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-btn
                  color="primary"
                  @click="previousStep()"
                >
                  Previous
                </v-btn>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="nextStep()"
                >
                  Continue
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container>
              <v-row>
                <v-btn
                  color="primary"
                  @click="previousStep()"
                >
                  Previous
                </v-btn>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="nextStepMeds()"
                >
                  Continue
                </v-btn>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <labResults></labResults>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <medicationHistory></medicationHistory>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-btn
                  color="primary"
                  @click="previousStep()"
                >
                  Previous
                </v-btn>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="nextStepMeds()"
                >
                  Continue
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-container>
              <v-row>
                <v-btn
                  color="primary"
                  @click="previousStep()"
                >
                  Previous
                </v-btn>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <v-spacer></v-spacer>
                <submitPatient
                  class="ml-5"
                  :base-filename="$store.getters.TrackProcess.praluentPatient.insuranceCompany"
                  :patient-data="$store.getters.TrackProcess"
                  @saveInfo="savePdfInfo($event)"
                >
                  Submit
                </submitPatient>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <assessment></assessment>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-btn
                  color="primary"
                  @click="previousStep()"
                >
                  Previous
                </v-btn>
                <v-spacer></v-spacer>
                <savePatient
                  :patient-profile="$store.getters.TrackProcess"
                  @onload="OnLoad($event)"
                ></savePatient>
                <!-- :formType="formName" -->
                <v-spacer></v-spacer>
                <submitPatient
                  class="ml-5"
                  :base-filename="$store.getters.TrackProcess.praluentPatient.insuranceCompany"
                  :patient-data="$store.getters.TrackProcess"
                  @saveInfo="savePdfInfo($event)"
                >
                  Submit
                </submitPatient>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>

/*
TODO:
- add v-ifs for additional information
- convert date in to year/month/date
- write a function that clears vuex Praluent patient state when users leave the page or closees component

Jamie:
- Submit patient
- loading modal
*/
import axios from 'axios'
import { mapGetters } from 'vuex'

import { stateService } from '@/render/api/zarya'

import Next from '@/components/common/ui/Form/ButtonCluster/Directional/NextV2'
import Previous from '@/components/common/ui/Form/ButtonCluster/Directional/PreviousV2'
import SavePatient from '@/components/common/ui/Form/ButtonCluster/Save/SavePatientStateV2'
import SubmitPatient from '@/components/common/ui/Form//ButtonCluster/Submit/SubmitPatientInfoV2'

import PatientInfoV2 from '@/components/common/ui/Form/PatientInfo/PatientInfoV2'
import MemberInfoV2 from '@/components/common/ui/Form/MemberInfo/MemberInfoV2'
import PharmacistInfoV2 from '@/components/common/ui/Form/PharmacistInfo/PharmacistInfoV2'
import DoctorInfoV2 from '@/components/common/ui/Form/DoctorInfo/DocInfoV2'
import DrugRequestedV2 from '@/components/common/ui/Form/DrugRequested/DrugRequestedV2'
import LabResultsV2 from '@/components/common/ui/Form/LabResultsV2/LabResultsV2'
import MedicationHistoryV2 from '@/components/common/ui/Form/MedicationHistory/MedicationHistoryV2'
import AssessmentV2 from '@/components/common/ui/Form/HomoHetroCardio/AssessmentV2'
import { signatureCoordinates } from '@/views/praluent/data/signatureCoordinates'
import { insuranceFormNames } from '@/views/praluent/data/insuranceFormNames'

export default {
    name: 'Praluent',
    components: {
        savePatient: SavePatient,
        submitPatient: SubmitPatient,
        nextButton: Next,
        previousButton: Previous,
        patientInfo: PatientInfoV2,
        memberInfo: MemberInfoV2,
        pharmacistInfo: PharmacistInfoV2,
        doctorInfo: DoctorInfoV2,
        drugRequested: DrugRequestedV2,
        labResults: LabResultsV2,
        medicationHistory: MedicationHistoryV2,
        assessment: AssessmentV2,
    },
    data() {
        return {
            step: 1,

            // insuranceCompany:"",
            signatureCoordinates,
            patientData: {
                pdfRef: {},
            },
            insuranceFormNames,
            snackbar: {
                show: false,
                text: '',
                color: '',
            },
        }
    },
    updated() {},
    computed: {
        ...mapGetters([
            'token',
            'FormClass',
            'UserProfile',
            'PharmacyProfile',
            'TrackProcess',
            'PraluentPatient',
            'GET_PATIENTCODE',
            'GET_PRALUENT_PATIENT_DATA_WITH_MEDICATION_TABLE',
        ]),

    // TODO:
    // - Update data with childRenderCondition to enable destroy method to envoke when changing choices
    },
    created() {
        this.$store.dispatch('setFormClass', 'praluentInsurance')
    },
    async mounted() {},
    beforeDestroy() {
        this.$store.dispatch('resetPatientData')
        this.$store.commit('RESET_PRALUENT_STATE', {})
    },
    methods: {
        nextStep() {
            this.saveStateInfo()
            this.step++
        },
        nextStepMeds() {
            this.$store.commit(
                'SET_PRALUENT_OVERLAY_DATA',
                this.$store.getters.GET_PRALUENT_PATIENT_DATA_WITH_MEDICATION_TABLE,
            )
            this.saveStateInfo()
            this.step++
        },
        previousStep() {
            this.saveStateInfo()
            this.step--
        },
        async zohoPost() {
            const payload = {
                file: this.patientData.pdfRef,
                sendForSignData: {
                    data: {
                        field_name: `${this.$store.getters.TrackProcess.praluentPatient.signatureCoords.name}PtSign`,
                        x_coord: this.$store.getters.TrackProcess.praluentPatient.signatureCoords.xValue,
                        y_coord: this.$store.getters.TrackProcess.praluentPatient.signatureCoords.yValue,
                        abs_width: this.$store.getters.TrackProcess.praluentPatient.signatureCoords.width,
                        ab_height: this.$store.getters.TrackProcess.praluentPatient.signatureCoords.height,
                        page_no: this.$store.getters.TrackProcess.praluentPatient.signatureCoords.pageNo,
                    },
                },
                createData: {
                    request_name: `PraluentInsurance${this.$store.getters.TrackProcess.praluentPatient['FIRST NAME']}${this.$store.getters.TrackProcess.praluentPatient['LAST NAME']}`,
                    recipient_name: `${this.$store.getters.TrackProcess.praluentPatient['FIRST NAME']} ${this.$store.getters.TrackProcess.praluentPatient['LAST NAME']}`,
                    recipient_email: this.$store.getters.TrackProcess.praluentPatient['EMAIL ADDRESS'],
                    in_person_name: `${this.$store.getters.TrackProcess.praluentPatient['FIRST NAME']} ${this.$store.getters.TrackProcess.praluentPatient['LAST NAME']}`,
                },
                formType: this.$store.getters.TrackProcess.formClass,
                doctorFax: this.$store.getters.TrackProcess.praluentPatient['PRIMARY FAX #'],
                doctorFirstName: this.$store.getters.TrackProcess.praluentPatient['PRIMARY FIRST NAME'],
                doctorLastName: this.$store.getters.TrackProcess.praluentPatient['PRIMARY LAST NAME'],
                ptFirstName: this.$store.getters.TrackProcess.praluentPatient['FIRST NAME'],
                ptLastName: this.$store.getters.TrackProcess.praluentPatient['LAST NAME'],
                insuranceCompany: this.$store.getters.TrackProcess.praluentPatient.insuranceCompany,
                insuranceCompanyFax: this.$store.getters.TrackProcess.praluentPatient.signatureCoords.faxNumber,
                zaryaToken: await this.$auth.getTokenSilently(),
                trackId: this.$store.getters.TrackProcess.trackID,
                bearer: 'Bearer ',
            }
            // eslint-disable-next-line no-undef
            // await getAuthTokenByRefresh();

            await axios.post('https://enirsovv41gw4l8.m.pipedream.net', {
                ...payload,
            })
        },
        savePdfInfo(data) {
            this.patientData.pdfRef = { ...data }
            this.zohoPost()
            this.completeTrack()

            // setTimeout(()=> {this.routeAction()}, 5000);
            this.updateStateStatus()
        },
        completeTrack() {
            this.$store.dispatch('setProcessStatus', 'completed')
        },
        async updateStateStatus() {
            // this.saveDateSubmitted();
            let res
            const accessToken = await this.$auth.getTokenSilently()
            const data = {
                formType: this.FormClass,
                state: this.$store.state.Praluent,
                patientID: this.$store.getters.TrackProcess.praluentPatient.PatientID,
                userID: this.UserProfile._id,
                pharmacyID: this.PharmacyProfile._id,
            }
            try {
                if (!this.$store.getters.TrackProcess.praluentPatient.stateId) {
                    // create it and make its status complete (this is in case they complete a process form and it doesnt have a stateid already)
                    res = await stateService.createState(accessToken, {
                        ...data,
                    })
                    this.$store.state.Praluent.praluentPatient.stateId = res.state._id
                }

                // update it - once process form is complete and it has state id, change to complete
                res = await stateService.updateState(accessToken, this.$store.getters.TrackProcess.praluentPatient.stateId, {
                    ...data,
                    status: 'complete',
                })

                return res
            } catch (err) {
                console.log(err)
            }
        },
        async saveStateInfo() {
            // FIXME remove sensitive customer information
            // this.saveCurrentDate();
            let promise
            const accessToken = await this.$auth.getTokenSilently()
            const data = {
                formType: this.$store.getters.TrackProcess.formClass,
                state: {
                    ...this.$store.getters.TrackProcess,
                    lastSavedDate: this.$moment().format('YYYY/MM/DD'),
                },
                patientID: this.$store.getters.PraluentPatient.PatientID,
                userID: this.UserProfile._id,
                pharmacyID: this.PharmacyProfile._id,
            }
            if (this.$store.getters.TrackProcess.praluentPatient.stateId) {
                // update it
                promise = stateService.updateState(accessToken, this.$store.getters.TrackProcess.praluentPatient.stateId, {
                    ...data,
                    status: this.stateStatus || 'in-progress',
                })
            } else {
                // create it
                promise = stateService.createState(accessToken, data)
            }

            return promise.then(this.saveResponseSuccess, this.saveResponseReject)
        },
        saveResponseSuccess(res) {
            this.showMsg('Successfully saved your form')
            this.$store.getters.TrackProcess.praluentPatient.stateId = res.state._id
            this.$store.commit('SET_PRALUENT_SINGLE_STATE', {
                key: 'stateId',
                value: res.state._id,
            })

            // this.stateID = res.state._id;
        },
        saveResponseReject(res) {
            this.showMsg('Failed to saved your form', 'error')
            throw new Error(res)
        },
        showMsg(text, color = 'success') {
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.color = color
        },

    // saveDateSubmitted() {
    //   this.patientData.dateSubmitted = this.moment().format("YYYY/MM/DD");
    // },
    },
}
</script>

<style scoped>
/* FIXME: this is a hack to get the form to render correctly */
.row, hr{
  margin: 20px 0px !important;
}
.row:first-child{
  margin: 0px !important;
}

.input-group--suffix input {
  text-align: right;
}
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.centerButtons button {
  margin: 0 2%;
}

h6 {
  color: rgba(0, 0, 0, 1);
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
</style>



const memberRelationship = [
    {
        questionLabel:"Member Gender",
        inputField:"radio",
        model:"GENDERSELECT",
        hasInsurance:[],
        componentParams: [
            {
                label: "Male",
                value: "memberInfoM"
            },
            {
                label: "Female",
                value: "memberInfoF"
            },
        ]
    },
    {
        questionLabel:"Is the patient the member?",
        inputField:"radio",
        model:"PATIENT IS MEMBER",
        hasInsurance:['BC','OB','ESI12','EP12'],
        componentParams: [
            {
                label: "Yes",
                value: "isPaYes"
            },
            {
                label: "No",
                value: "isPaNo"
            },
        ],
    },
    {
        questionLabel:"Preferred Language of Correspondence",
        inputField:"radio",
        model:"PREFERREDLANGUAGERADIO",
        hasInsurance:['AS16'],
        componentParams: [
            {
                label: "English",
                value: "languageEng"
            },
            {
                label: "French",
                value: "languageFr"
            },
        ],
    },
    {
        // if spouse --> load spouse component
        // if dependent --> load education component
        questionLabel: "Relationship to Member",
        inputField: "radio",
        model: "RELATIONSHIPSELECT",
        childrenModels:[
            'OTHER RELATIONSHIP',
            'SECOND INSURANCE DATE OF BIRTH'
        ],
        componentParams: [{
                label: "Self",
                value: "Self"
            },
            {
                label: "Spouse",
                value: "Spouse"
            },
            {
                label: "Dependent",
                value: "Dependent"
            },
            {
                label: "Other",
                value: "relationshipOther",
                hasInsurance: ['ESI02','EP02', 'NEX', 'NX']
            },
        ],
        hasInsurance:[],
        nodes:[
            {   
                inputField:"text",
                expectedValue:"relationshipOther",
                model:"OTHER RELATIONSHIP",
                componentParams: [
                    {
                        label: "Specify",
                    },
                ],
                hasInsurance:['ESI02','EP02','NEX','NX']
            },
            {
                // datepicker
                // I don't think this is used? 
                inputField: "text",
                expectedValue: "Dependent",
                model: "SECOND INSURANCE DATE OF BIRTH",
                componentParams: {
                    label: "Date of Birth of Dependent",
                },

                hasInsurance: ['AS16', 'BC', 'OB', 'ESI12','EP12']
            },
        ]
    },
    {
        questionLabel:"Do you or any other dependants have coverage for this drug under any other plan/program",
        inputField:"radio",
        model:"RELATIONSHIPRADIO",
        childrenModels:[
            'RELATIONSHIP POLICY NUMBER',
            'RELATIONSHIP COVERAGE'

        ],
        hasInsurance:['AS16','BC','OB','ESI12','EP12'],
        componentParams: [
            {
                label:"Yes",
                value:"depCoverageYes"
            },
            {
                label: "No",
                value: "depCoverageNo"
            },
        ],
        nodes:[
            {   
                inputField:"text",
                expectedValue:"depCoverageYes",
                model:"RELATIONSHIP POLICY NUMBER",
                hasInsurance:['ESI02','EP02','NEX','NX','BC','OB'],
                componentParams: {
                        label: "Policy #",
                    },
            },
            {   
                inputField:"text",
                expectedValue:"depCoverageYes",
                model:"RELATIONSHIP COVERAGE",
                hasInsurance:['ESI02','EP02','NEX','NX','BC','OB'],
                componentParams:{
                        label: "Carrier",
                    }
            },
        ]
    }

];

export {
    memberRelationship,
}
const statinQuestionExtension = [{
        questionLabel: "Was the dose of medication maximized",
        inputField: "radio",
        model: "DOSE MAXED",
        childrenModels:[
            'PREDISP FACTORS',
            'MYOSITIS',
            'RHABDOMYOLYSIS',
            'INTOLERANCE OTHER',
            'INTOLERANCE SPECIFY TEXT',
            'PREGNANCY',
            'NURSING',
            'ACTIVE LIVER DISEASE',
            'CONTRAIN OTHER',
            'CONTRAIN SPECIFY TEXT'
        ],
        expectedValue: null,
        previousValue: null,
        hasInsurance: ['BC', 'OB'],
        componentParams: [{
                label: "No",
                value: "no"
            },
            {
                label: "Yes",
                value: "medicationsMaxYes"
            },
        ],
        nodes: [{
            questionLabel: "Provide details of intolerance and the predisposing factors ruled out OR contraindications",
            inputField: "radio",
            model: "PREDISP FACTORS",
            expectedValue: "medicationsMaxYes",
            hasInsurance: ['BC', 'OB'],
            componentParams: [{
                    label: "Intolerance",
                    value: "INTOLERANCE"
                },
                {
                    label: "Contraindication",
                    value: "CONTRAINDICATION"
                },
            ],
            nodes: [{
                    inputField: "checkbox",
                    model: "MYOSITIS",
                    expectedValue: "INTOLERANCE",
                    hasInsurance: ['BC', 'OB'],
                    componentParams: [{
                            label: "Myositis",
                            value: "yes"
                        },
                    ],
                },
                {
                    inputField: "checkbox",
                    model: "RHABDOMYOLYSIS",
                    expectedValue: "INTOLERANCE",
                    hasInsurance: ['BC', 'OB'],
                    componentParams: [{
                            label: "Rhabdomyolysis",
                            value: "yes"
                        },
                    ],
                },
                {
                    inputField: "checkbox",
                    model: "INTOLERANCE OTHER",
                    expectedValue: "INTOLERANCE",
                    hasInsurance: ['BC', 'OB'],
                    componentParams: [{
                            label: "Other",
                            value: "yes"
                        },
                    ],
                    nodes: [{
                        inputField: "text",
                        model: "INTOLERANCE SPECIFY TEXT",
                        expectedValue: "yes",
                        hasInsurance: ['BC', 'OB'],
                        componentParams: {
                            label: "Specify"
                        }
                    }]
                },
                {
                    inputField: "checkbox",
                    model: "PREGNANCY",
                    expectedValue: "CONTRAINDICATION",
                    hasInsurance: ['BC', 'OB'],
                    componentParams: [{
                            label: "Pregnancy",
                            value: "yes"
                        },
                    ],
                },
                {
                    inputField: "checkbox",
                    model: "NURSING",
                    expectedValue: "CONTRAINDICATION",
                    hasInsurance: ['BC', 'OB'],
                    componentParams: [{
                            label: "Nursing",
                            value: "yes"
                        },
                    ],
                },
                {
                    inputField: "checkbox",
                    model: "ACTIVE LIVER DISEASE",
                    expectedValue: "CONTRAINDICATION",
                    hasInsurance: ['BC', 'OB'],
                    componentParams: [{
                            label: "Active Liver Disease",
                            value: "yes"
                        },
                    ],
                },
                {
                    inputField: "checkbox",
                    model: "CONTRAIN OTHER",
                    expectedValue: "CONTRAINDICATION",
                    hasInsurance: ['BC', 'OB'],
                    componentParams: [{
                            label: "Other",
                            value: "yes"
                        },
                    ],
                    nodes: [{
                        inputField: "text",
                        model: "CONTRAIN SPECIFY TEXT",
                        expectedValue: "yes",
                        hasInsurance: ['BC', 'OB'],
                        componentParams: {
                            label: "Specify"
                        }
                    }]
                },
            ]
        }]
    },
    // {
    //     questionLabel: "Was the medication discontinued?",
    //     inputField: "radio",
    //     model: "medicationsYesDisc",
    //     expectedValue: null,
    //     hasInsurance: ['BC', 'OB'],
    //     componentParams: [{
    //             label: "No",
    //             value: "no"
    //         },
    //         {
    //             label: "Yes",
    //             value: "medicationsYesDisc"
    //         },
    //     ],
    // },
    // ESI12/AS11
    {
        questionLabel: "Was the medication discontinued?",
        inputField: "radio",
        model: "MEDICATION DISCONTIUED RADIO",
        childrenModels:[
            'DISCONTINUATION REASON RADIO',
            'DISCONTINUATION REASON TEXT',
            'GWL MYOPATHY',
            'GWL MYOSITIS',
            'GWL OTHER',
            'GWL OTHER STATIN TEXT'

        ],
        expectedValue: null,
        hasInsurance: ['ESI', 'EP', 'AS11', 'SSQ', 'SQ'],
        componentParams: [{
                label: "No",
                value: "no"
            },
            {
                label: "Yes",
                value: "medicationsYesDisc"
            },
        ],
        nodes: [
            {
                questionLabel: "Reason for Discontinuation",
                inputField: "radio",
                model: "DISCONTINUATION REASON RADIO",
                expectedValue: "medicationsYesDisc",
                hasInsurance: ['SSQ', 'SQ'],
                componentParams: [{
                        label: "Intolerance",
                        value: "INTOLERANCE"
                    },
                    {
                        label: "Contraindication",
                        value: "CONTRAINDICATION"
                    },
                    {
                        label: "Ineffectiveness",
                        value: "INEFFECTIVENESS"
                    },
                    {
                        label: "Other",
                        value: "OTHER"
                    },
                ],
                nodes: [{
                    inputField: "text",
                    model: "DISCONTINUATION REASON TEXT",
                    expectedValue: "OTHER",
                    hasInsurance: ['SSQ', 'SQ'],
                    componentParams: {
                        label: "Discontinuation Reason"
                    }
                }]
            },
            {
                questionLabel: "Reason for Discontinuation",
                inputField: "radio",
                model: "DISCONTINUATION REASON RADIO",
                expectedValue: "medicationsYesDisc",
                hasInsurance: ['ESI', 'EP'],
                componentParams: [{
                        label: "Intolerance",
                        value: "INTOLERANCE"
                    },
                    {
                        label: "Contraindication",
                        value: "CONTRAINDICATION"
                    },
                ],
            },
            {
                inputField: "checkbox",
                model: "GWL MYOPATHY",
                expectedValue: "medicationsYesDisc",
                hasInsurance: ['AS11'],
                componentParams: [{
                        label: "Persisent myopathy or myalgia for at least 2 weeks.",
                        value: "yes"
                    },
                ],
            },
            {
                inputField: "checkbox",
                model: "GWL MYOSITIS",
                expectedValue: "medicationsYesDisc",
                hasInsurance: ['AS11'],
                componentParams: [{
                        label: "Myositis or Rhabdomyolysis. Please submit CK levels.",
                        value: "yes"
                    },
                ],
            },
            {
                inputField: "checkbox",
                model: "GWL OTHER",
                expectedValue: "medicationsYesDisc",
                hasInsurance: ['AS11'],
                componentParams: [{
                        label: "Other - please detail:",
                        value: "yes"
                    },
                ],
                nodes: [{
                    inputField: "text",
                    model: "GWL OTHER STATIN TEXT",
                    expectedValue: "yes",
                    hasInsurance: ['AS11'],
                    componentParams: {
                            label: "Other details...."
                        },
                    
                }]
            },
        ]
    },
    {
        questionLabel: "Continue the Medication/Therapy at a lower dose?",
        inputField: "radio",
        model: "STATIN RADIO",
        hasInsurance: ['ESI12','EP12', 'BC', 'OB'],
        componentParams: [{
                label: "No",
                value: "no"
            },
            {
                label: "Yes",
                value: "medicationsTherapyYes"
            },
        ]
    },
    {
        // need to update on form
        questionLabel: "Will the patient be continuing on this medication in addition to new therapy?",
        inputField: "radio",
        model: "STATIN RADIO",
        childrenModels:[
            'PREDISP FACTORS'
        ],
        hasInsurance: ['ESI02','EP02'],
        componentParams: [{
                label: "No",
                value: "no"
            },
            {
                label: "Yes",
                value: "medicationsManuYes"
            },
        ],
        nodes: [{
            // need to update on form
            questionLabel: "Please specify the outcome",
            inputField: "radio",
            model: "PREDISP FACTORS",
            expectedValue: "medicationsManuYes",
            hasInsurance: ['ESI02','EP02'],
            componentParams: [{
                    label: "Intolerance",
                    value: "INTOLERANCE"
                },
                {
                    label: "Contraindication",
                    value: "CONTRAINDICATION"
                },
            ]
        }]
    },
    
];

export { statinQuestionExtension }
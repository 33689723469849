const statinQuestions = [{
        inputField: "text",
        model: "daysontherapy",
        expectedValue: null,
        previousValue: null,
        componentParams: [{
                label: "Days on therapy"
            },

            {
                arrayName: "medicationTable"
            }
        ],
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "text",
        model: "statin",
        expectedValue: null,
        previousValue: null,
        componentParams: [{
                label: "Statin name"
            },
            {
                arrayName: "medicationTable"
            },
        ],
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "text",
        model: "statincompliance",
        expectedValue: null,
        previousValue: null,
        componentParams: [{
                label: "Statin compliance"
            },
            {
                arrayName: "medicationTable"
            },
        ],
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "text",
        model: "statindose",
        expectedValue: null,
        previousValue: null,
        componentParams: [{
                label: "Statin Dose"
            },
            {
                arrayName: "medicationTable"
            },
        ],
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "text",
        model: "statinoutcome",
        expectedValue: null,
        previousValue: null,
        componentParams: [{
                label: "Statin Outcome"
            },
            {
                arrayName: "medicationTable"
            },
        ],
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "datepicker",
        model: "statinstart",
        expectedValue: null,
        previousValue: null,
        componentParams: [{
                label: "Statin Start",
            },
            {
                arrayName: "medicationTable"
            },
        ],
        hasInsurance: [],
        nodes: []
    },
    {
        inputField: "datepicker",
        model: "statinend",
        expectedValue: null,
        previousValue: null,
        componentParams: [{
                label: "Statin End"
            },
            {
                arrayName: "medicationTable"
            },
        ],
        hasInsurance: [],
        nodes: []
    },
    
]


export {
    statinQuestions
}
const docInfo = [{
    inputField: "text",
    model: "PRIMARY FIRST NAME",
    componentParams: {
      label: "Doctor First Name",
    },

    hasInsurance: [],
  },
  {
    inputField: "text",
    model: "PRIMARY LAST NAME",
    componentParams: {
      label: "Doctor Last Name",
    },

    hasInsurance: [],
  },
  {
    inputField: "text",
    model: "CPSO",
    componentParams: {
      label: "CPSO",
    },

    hasInsurance: [],
  },
  {
    inputField: "text",
    model: "SPECIALTY",
    hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS11', 'GS', 'SSQ', 'SQ', 'ESI02','EP02', 'AS', 'AS16', 'BC', 'OB', 'RXP', 'CS'],
    componentParams: {
      label: "Doctor Specialty",
    }

  },
  {
    inputField: "text",
    model: "PRIMARY PHONE #",
    hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS11', 'GS', 'SSQ', 'SQ', 'ESI02','EP02', 'AS', 'AS16', 'BC', 'OB', 'NEX', 'NX', 'ESI11', 'RXP', 'CS'],
    componentParams: {
      label: "Telephone#",
    }
  },
  {
    inputField: "text",
    model: "PRIMARY FAX #",
    rules: [(v) => !!v || 'Fax is required'],
    componentParams: {
      label: "Fax #",
    }

  }, {
    inputField: "text",
    model: "PRIMARY EMAIL",
    hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'AS11', 'GS', 'ESI02','EP02', 'AS', 'AS16', 'NEX', 'NX', 'ESI11', 'RXP', 'CS'],
    componentParams: {
      label: "Email",
    }
  },
]
export {
  docInfo
}
const assessmentMain = [
    {
        questionLabel:"Is this the first time or a renewal",
        inputField: "radio",
        model: "INITIAL RENEWAL CHOICE",
        hasInsurance: ['ESI12','EP12','AS11','SSQ','SQ','AS16','BC','OB','AS','ESI','EP'],
        componentParams: [
            {
                label: "Initial",
                value:"initial"
            }, 
            {
                label: "Renewal",
                value:"renewal"
            }, 
        ],
    },
    {
        questionLabel:"Homo or Heterozygous Familial Hypercholesterolemia?",
        inputField: "radio",
        model: "HOMO HETERO CARDIO CHOICE",
        hasInsurance: ['ESI12','EP12','AS11','BC','OB','GS','SSQ','SQ'],
        componentParams: [
            {
                label: "Homozygous Familial Hypercholesterolemia",
                value:"homo"
            }, 
            {
                label: "Heterozygous Familial Hypercholesterolemia",
                value:"hetero"
            }, 
            {
                hasInsurance:['AS11', 'BC','OB'],
                label: "Atherosclerotic Cardiovascular Disease",
                value:"cardio"
            }, 
        ],
    },
    {
        questionLabel:"Homo or Heterozygous Familial Hypercholesterolemia?",
        inputField: "radio",
        model: "HOMO HETERO CARDIO CHOICE",
        hasInsurance: ['ESI02','EP02'],
        componentParams: [
            {
                label: "Heterozygous Familial Hypercholesterolemia",
                value:"hetero"
            }, 
            {
                label: "Atherosclerotic Cardiovascular Disease",
                value:"cardio"
            }, 
        ],
    },
];

export  {
    assessmentMain,
}
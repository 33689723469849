const assessmentCardio = [
    {
        questionLabel:"Initial or renewal",
        inputField:"radio",
        model: "CARDIO INITIAL RENEWAL",
        childrenModels:[
            'CARDIO RESULTS PRIOR',
            'CARDIO RESULTS POST',
            'CARDIO PATIENT REDUCTION',
            'CARDIO RENEWAL CRITERIA'
        ],
        hasInsurance:['ESI02','EP02',],
        componentParams: [
            {
                label: "Initial",
                value:"INITIAL"
            },
            {
                label: "Renewal",
                value:"RENEWAL"
            }
        ],
        nodes:[
            {
                textTitle:"Indicate patient's LDL-C test results prior to starting Praluent",
                inputField:"text",
                expectedValue:"RENEWAL",
                model: "CARDIO RESULTS PRIOR",
                hasInsurance:[],
                componentParams: 
                    {
                        label: "Results Prior",
                    }
            },
            {
                textTitle:"Indicate patient's LDL-C test results 4 weeks after starting Praluent",
                inputField:"text",
                expectedValue:"RENEWAL",
                model: "CARDIO RESULTS POST",
                hasInsurance:[],
                componentParams: 
                    {
                        label: "Results Post",
                    }
            },
            {
                questionLabel:"The patient experienced a 15% or greater reduction in LDL-C level from a baseline if patient suffers from Homozygous Familial Hypercholesterolemia?",
                inputField:"radio",
                expectedValue:"RENEWAL",
                model: "CARDIO PATIENT REDUCTION",
                hasInsurance:[],
                componentParams: [
                    {
                        label: "Yes",
                        value:"cardioYes18"
                    },
                    {
                        label: "No",
                        value:"NO"
                    },
                ],
            },
            {
                textTitle:"If patient does not meet the above criteria, please explain why patient should continue treatment with Praluent?",
                inputField:"text",
                expectedValue:"RENEWAL",
                model: "CARDIO RENEWAL CRITERIA",
                hasInsurance:[],
                componentParams: 
                    {
                        label: "Renewal Criteria",
                    },
            },
        ]
    },
    {
        inputField:"text",
        model: "CARDIO DIAGNOSIS RESULTS",
        hasInsurance:['ESI','EP',],
        componentParams: 
            {
                label: "Please provide the LDL-C Test Results",
            }
    },
    {
        // datepicker
        inputField:"datepicker",
        model: "CARDIO DIAGNOSIS DATE",
        hasInsurance:['ESIO2','EP02'],
        componentParams: 
            {
                label: "Date of Test results",
            }
    },
    {
        questionLabel:"Acute Coronary Syndromes",
        inputField:"radio",
        model: "CARDIO ACUTE",
        hasInsurance:['AS11','ESIO2','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes0"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Myocardinal infarction",
        inputField:"radio",
        model: "CARDIO MYO CARDINAL",
        hasInsurance:['AS11','ESIO2','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes1"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Stable or unstable angina",
        inputField:"radio",
        model: "CARDIO ANGINA",
        hasInsurance:['AS11','ESIO2','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes2"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Coronary or other arterial revasculatization",
        inputField:"radio",
        model: "CARDIO REVASCULATIZATION",
        hasInsurance:['AS11','ESIO2','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes3"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Stroke",
        inputField:"radio",
        model: "CARDIO STROKE",
        hasInsurance:['AS11','ESIO2','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes4"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        textTitle:"Clinically manifest Atherosclerotic cardiovascular disease (Select all of the following that apply)",
        inputField: "checkbox",
        model: "CARDIO ANGINA",
        hasInsurance:['SSQ','SQ'],
        componentParams: [
            {
                label: "Stable/unstable angina",
                value:"cardioYes2"
            },
        ],
    },
    {
        inputField: "checkbox",
        model: "CARDIO MYO CARDINAL",
        hasInsurance:['SSQ','SQ'],
        componentParams: [
            {
                label: "Myocardial infarction",
                value:"cardioYes1"
            },
        ],
    },
    {
        inputField: "checkbox",
        model: "CARDIO STROKE",
        hasInsurance:['SSQ','SQ'],
        componentParams: [
            {
                label: "Stroke",
                value:"cardioYes4"
            },
        ],
    },
    {
        questionLabel:"Other",
        inputField:"checkbox",
        model: "CARDIO OTHER",
        childrenModels:['CARDIO OTHER SPECIFY'],
        hasInsurance:['SSQ','SQ'],
        componentParams: [
            {
                label: "Other",
                value:"cardioYes5"
            },
        ],
        nodes:[
            {
                inputField:"text",
                expectedValue:"cardioYes5",
                model: "CARDIO OTHER SPECIFY",
                hasInsurance:['SSQ','SQ'],
                componentParams: 
                    {
                        label: "Specify",
                    },
            }
        ],
    },
    {
        questionLabel:"Peripheral arterial disease presumed to be of atherosclerotic origin",
        inputField:"radio",
        model: "CARDIO PERIPHERAL",
        hasInsurance:['AS11' ,'ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes7"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Findings from CT angiogram or catherization consistant with clinical ASCVD",
        inputField:"radio",
        model: "CARDIO ANAGRAM",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes8"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Does the patient have LDL-C > 2mmol/L despite treatment with a maximally tolerated statin therapy",
        inputField:"radio",
        model: "CARDIO LESS THAN 2",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes9"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Is the patient taking another ldl lowering therapy?",
        inputField:"radio",
        model: "CARDIO LOWERING",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes10"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Has the patient demonstrated adherence to statin therapy for the previous 12 weeks?",
        inputField:"radio",
        model: "CARDIO ADHERENCE STATIN",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes11"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Is the patient statin intolerate?",
        inputField:"radio",
        model: "CARDIO INTOLERATE",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes12"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Does the patient have a contraindication to statin therapy",
        inputField:"radio",
        model: "CARDIO CONTRAINDICATION",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes13"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Has the patient enrolled in the My Praluent Coach Patient Support Program?",
        inputField:"radio",
        model: "CARDIO REPATHA READY SUPPORT",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes14"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Has the My Praulent Coach Patient Support Program reviewed this form?",
        inputField:"radio",
        model: "CARDIO REPATHA READY REVIEW",
        hasInsurance:['ESI02','EP02'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes15"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        questionLabel:"Will the patient be following a low chelesterol diet while taking Praluent?",
        inputField:"radio",
        model: "CARDIO LOW DIET",
        hasInsurance:['AS11'],
        componentParams: [
            {
                label: "Yes",
                value:"cardioYes16"
            },
            {
                label: "No",
                value:"NO"
            },
        ],
    },
    {
        inputField:"select",
        model: "CARDIO INHIBITOR USED WITH STATIN SPECIFY",
        hasInsurance:['AS11'],
        items:[
            {
                text: "ACS",
                value:"ACS"
            },
            {
                text: "MI",
                value:"MI"
            },
            {
                text: "Stroke",
                value:"Stroke"
            },
            {
                text: "TIA",
                value:"TIA"
            },
            {
                text: "PAD",
                value:"PAD"
            },
            {
                text: "Unstable or Stable Angina",
                value:"Unstable or Stable Angina"
            },
            {
                text: "Coronary or other Arterial Revascularization",
                value:"Coronary or other Arterial Revascularization"
            },
        ],
        componentParams: [
            {
                label: "Specify the ASCVD",
            }
        ],
    },
    {
        questionLabel:"Indicate the atherosclerotic cardiovascular disease manifestations:",
        inputField:"radio",
        model: "CARDIO HEART DISEASE TYPE",
        childrenModels:['CARDIO SYMPTOMS'],
        hasInsurance:['BC','OB'],
        componentParams: [
            {
                label: "Coronary heart disease. If selected, Please also select one of the following",
                value:"CORNARY"
            },
            {
                label: "Cerebral disease – stroke or transient ischemic attack",
                value:"CEREBRAL"
            },
            {
                label: "Peripheral arterial disease (including abdominal aortic aneurysm)",
                value:"PERIPHERAL"
            },
        ],
        nodes:[
            {
                questionLabel:"",
                inputField:"radio",
                expectedValue:"CORNARY",
                model: "CARDIO SYMPTOMS",
                hasInsurance:['BC','OB'],
                componentParams: [
                    {
                        label: "Acute Coronary Symndrom",
                        value:"ACUTE"
                    },
                    {
                        label: "History of myocardinal infection",
                        value:"MI"
                    },
                    {
                        label: "Stable or unstable angina",
                        value:"ANGINA"
                    },
                    {
                        label: "Coronary or other arterial revascularisation",
                        value:"REVASCULARISATION"
                    },
                ],
            }
        ]
    },
];

export{
    assessmentCardio
}
const spouseInfoSub = [
    {
        questionLabel:"Is the claimant covered under another benefit plan?",
        inputField:"radio",
        model:"sunRelationshipRadio",
        childrenModels:[
            'SECOND INSURANCE FIRST NAME',
            'SECOND INSURANCE LAST NAME',
            'SECOND INSURANCE DATE OF BIRTH',
            'SECOND INSURANCE COMPANY',
            'SECOND INSURANCE RELATIONSHIP',
            'SECOND INSURANCE CONTRACT',
            'SECOND INSURANCE ID',
        ],
        hasInsurance:['AS16'],
        componentParams:[
          {
            label:"Yes",
            value:"sunlifeRelationYes"
          },
          {
            label:"No",
            value:"sunlifeRelationNo"
          },
        ],
        nodes:[
            {
                inputField:"text",
                model:"SECOND INSURANCE FIRST NAME",
                expectedValue:"sunlifeRelationYes",
                hasInsurance:['AS16'],
                componentParams: 
                    {
                        label: "Second Insurance First Name",
                    },
                
            },    
            {
                inputField:"text",
                model:"SECOND INSURANCE LAST NAME",
                expectedValue:"sunlifeRelationYes",
                hasInsurance:['AS16'],
                componentParams: 
                    {
                        label: "Second Insurance Last Name",
                    },
                
            },
            {
            //   datepicker
                inputField: "datepicker",
                model: "SECOND INSURANCE DATE OF BIRTH",
                expectedValue:"sunlifeRelationYes",
                hasInsurance:['AS16'],
                componentParams: 
                    {
                        label: "Date of Birth",
                    },
                
            },
            {
                inputField: "text",
                model: "SECOND INSURANCE COMPANY",
                expectedValue:"sunlifeRelationYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Second Insurance Company",
                    },
                
            },
            {
                inputField: "text",
                model: "SECOND INSURANCE RELATIONSHIP",
                expectedValue:"sunlifeRelationYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Second Insurance Relationship",
                    },
                
            },
            {
                inputField: "text",
                model: "SECOND INSURANCE CONTRACT",
                expectedValue:"sunlifeRelationYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Second Insurance Contract Number",
                    },
                
            },
            {
                inputField: "text",
                model: "SECOND INSURANCE ID",
                expectedValue:"sunlifeRelationYes",
                hasInsurance: ['AS16'],
                componentParams: 
                    {
                        label: "Second Insurance Member ID number",
                    },
                
            },
        ]
    },
    {
        questionLabel: "Is this drug covered under the Primary Plan?",
        inputField: "radio",
        model: "sunlifeSecondInsurancePrimaryCoverageRadio",
        hasInsurance: ['AS16'],
        componentParams: [
            {
                label: "Yes",
                value: "sunlifePrimaryYes"
            },
            {
                label: "No",
                value: "sunlifePrimaryNo"
            },
        ],
    },
    {
        questionLabel: "If your other benefit plan is with Sun Life Financial, do you want us to process this form through both benefit plans?",
        inputField: "radio",
        model: "sunlifeBenefitQuestion",
        hasInsurance: ['AS16'],
        componentParams: [
            {
                label: "Yes",
                value: "sunlifeBenefitYes"
            },
            {
                label: "No",
                value: "sunlifeBenefitNo"
            },
        ],
    },
    {
        questionLabel: "How is the requested drug covered under your primary drug plan?",
        inputField: "radio",
        model: "CSrequestedDrugCoverage",
        childrenModels:[
            'CSspousePriorAuthRequest',
            'CSstatusOfSpousePriorAuthRadio'
        ],
        hasInsurance: ['RXP', 'CS'],
        componentParams: [
            {
                label: "General Benefit",
                value: "CSCoverageGeneral"
            },
            {
                label: "Require Special or Prior Authorization",
                value: "CSCoverageSpecial"
            },
            {
                label: "Excluded",
                value: "CSCoverageExcluded"
            },
        ],
        nodes: [
            {
                questionLabel: "Have you applied for coverage through Special/Prior Authorization?",
                inputField: "radio",
                expectedValue: "CSCoverageSpecial",
                model: "CSspousePriorAuthRequest",
                hasInsurance: ['RXP', 'CS'],
                componentParams: [
                    {
                        label: "Yes",
                        value: "CSSpouseYes"
                    },
                    {
                        label: "No",
                        value: "CSSpouseNo"
                    },
                ],
                nodes: [
                    {
                        questionLabel: "What was the coverage decision for the drug?",
                        inputField: "radio",
                        expectedValue: "CSSpouseYes",
                        model: "CSstatusOfSpousePriorAuthRadio",
                        hasInsurance: ['RXP', 'CS'],
                        componentParams: [
                            {
                                label: "Approved",
                                value: "CSSpouseStatusYes"
                            },
                            {
                                label: "Declined",
                                value: "CSSpouseStatusNo"
                            },
                        ],
                    }
                ]
            }
        ]
    },

    {
        questionLabel: "Single Coverage or Family Coverage?",
        inputField: "radio",
        model: "sunlifesecondInsuranceCoverageTypeRadio",
        hasInsurance: ['AS16'],
        componentParams: [
            {
                label: "Single",
                value: "sunlifeSecondSingle"
            },
            {
                label: "Family",
                value: "sunlifeSecondFamily"
            },
        ],
    }
];

export {
    spouseInfoSub
}
const drugRequestedSub = [
{
    // select
    inputField: "select",
    model: "DRUG DOSE",
    childrenModels:[
        'DRUG REGIMEN',
        'DIN'
    ],
    items: [{
            text: "75mg",
            value: "75mg"
        },
        {
            text: "150mg",
            value: "150mg"
        }
    ],
    hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'GS', 'SSQ', 'SQ', 'AS', 'AS16', 'ESI02','EP02', 'BC', 'OB', 'NEX', 'NX', 'ESI11', 'RXP', 'CS'],
    componentParams: [{
        label: "Drug Dose",
    }],
    nodes: [{
            // select box
            inputField: "select",
            expectedValue: '75mg',
            model: "DRUG REGIMEN",
            items: [{
                text: "Inject the contents of 1 syringe every 2 weeks",
                value: "Inject the contents of 1 syringe every 2 weeks"
            }],
            hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'GS', 'SSQ', 'SQ', 'ESI02','EP02', 'AS', 'AS16', 'BC', 'OB', 'NEX', 'NX', 'ESI11', 'RXP', 'CS'],
            componentParams: [{
                label: "Drug Regimen",
            }],
        },
        {
            // select box
            inputField: "select",
            expectedValue: '150mg',
            model: "DRUG REGIMEN",
            items: [{
                    text: "Inject the contents of 1 syringe every 2 weeks",
                    value: "Inject the contents of 1 syringe every 2 weeks"
                },
                {
                    text: "Inject the contents of 2 syringes every 4 weeks",
                    value: "Inject the contents of 2 syringes every 4 weeks"
                }
            ],
            hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'GS', 'SSQ', 'SQ', 'ESI02','EP02', 'AS', 'AS16', 'BC', 'OB', 'NEX', 'NX', 'ESI11', 'RXP', 'CS'],
            componentParams: [{
                label: "Drug Regimen",
            }],
        },
        {
            // din should be set to 02453819
            inputField: "readonly",
            expectedValue: "75mg",
            model: "DIN",
            hasInsurance: ['AS16'],
            componentParams: {
                label: "DIN",
                value: "02453819"
            },
        },
        {
            // din should be set to 02453835
            inputField: "readonly",
            expectedValue: "150mg",
            model: "DIN",
            hasInsurance: ['AS16'],
            componentParams: {
                label: "DIN",
                value: "02453835"
            },
        },
    ]
},
{
    // select
    inputField: "select",
    model: "DRUG DIAGNOSIS",
    items: [{
            text: "Hefh",
            value: "hefh"
        },
        {
            text: "Hofh",
            value: "Hofh"
        }
    ],
    hasInsurance: ['BC', 'OB', 'RXP', 'CS'],
    componentParams: [{
        label: "Drug Diagnosis",
    }, ],
}, {
    // default to 1 year
    inputField: "readonly",
    model: "DRUG EXPECTED",
    hasInsurance: ['ESI', 'EP', 'ESI11', 'BC', 'OB', 'RXP', 'CS', 'AS11','NEX','NX'],
    componentParams: {
        label: "Expected Duration",
        value: "1 year"
    }
},
{
    // Select box
    inputField: "select",
    model: "PLACE OF ADMINISTRATION TEXT",
    items: [{
            text: "Home",
            value: "Home"
        },
        {
            text: "Physician's Office",
            value: "Physician's Office"
        }
    ],
    hasInsurance: ['BC', 'OB'],
    componentParams: [{
        label: "Where will the medication be administered",
    }, ],
},
 {
    questionLabel: "Type of Request",
    inputField: 'radio',
    model: "ssqtypeOfRequest",
    hasInsurance: ['SSQ', 'SQ'],
    componentParams: [{
            label: "First Request",
            value: 'ssqFirstRequest'
        },
        {
            label: "Continuation of Treatment",
            value: 'ssqContinue'
        },
    ],
}, {
    questionLabel: "Will this drug be used according to its Health Canada approved indication(s)?",
    inputField: 'radio',
    model: "expressapprovedIndicationRadio",
    hasInsurance: ['ESI', 'EP'],
    componentParams: [{
            label: "Yes",
            value: 'expressHCyes'
        },
        {
            label: "No",
            value: 'expressHCno'
        },
    ],
}, {
    questionLabel: "Where will the medication be administered?",
    inputField: 'radio',
    model: "PLACE OF ADMNISTRATION RADIO",
    hasInsurance: ['ESI02','EP02', 'AS11'],
    componentParams: [{
            label: "Home",
            value: 'drugRequestedHome',
            hasInsurance: ['ESI02','EP02', 'ESI11', 'AS11']
        },
        {
            label: "Doctor Office",
            value: 'drugRequestedDoc',
            hasInsurance: ['ESI02','EP02', 'AS11']
        },
        {
            label: "Hospital(In Patient)",
            value: 'drugRequestedHospitalIn',
            hasInsurance: ['ESI02','EP02', 'ESI11', 'AS11']
        },
        {
            label: "Hospital(Out Patient)",
            value: 'drugRequestedHospitalOut',
            hasInsurance: ['ESI02','EP02', 'AS11']
        },
        {
            label: "Private Clinic",
            value: 'drugRequestedPrivate',
            hasInsurance: ['ESI11', 'ESI02','EP02', 'AS11']
        },
        {
            label: "Other",
            value: 'drugRequestedOther',
            hasInsurance: ['ESI11'],

        },
    ],
},
{
    questionLabel: "Where will the medication be administered?",
    inputField: 'radio',
    model: "PLACE OF ADMNISTRATION RADIO",
    hasInsurance: ['RXP', 'CS',],
    componentParams: [{
            label: "Home",
            value: 'drugRequestedHome',
        },
        {
            label: "Doctor Office",
            value: 'drugRequestedDoc',
        },
        {
            label: "Hospital",
            value: 'drugRequestedHospitalOut',
        },
        {
            label: "Private Clinic",
            value: 'drugRequestedPrivate',
        },
        {
            label: "LTC Facility",
            value: 'drugRequestedLtc',

        },
    ],
},
{
    questionLabel: "Where will the medication be administered?",
    inputField: 'radio',
    model: "PLACE OF ADMNISTRATION RADIO",
    hasInsurance: ['ESI', 'EP'],
    componentParams: [{
            label: "Home",
            value: 'drugRequestedHome',
            hasInsurance: ['ESI', 'EP',]
        },
        {
            label: "Doctor Office",
            value: 'drugRequestedDoc',
            hasInsurance: ['ESI', 'EP',]
        },
        {
            label: "Hospital(In Patient)",
            value: 'drugRequestedHospitalIn',
            hasInsurance: ['ESI', 'EP',]
        },
        {
            label: "Hospital(Out Patient)",
            value: 'drugRequestedHospitalOut',
            hasInsurance: ['ESI', 'EP',]
        },
    ],
},
{
    questionLabel: "Indicate where the medication will be administered?",
    inputField: 'radio',
    model: "PLACE OF ADMNISTRATION RADIO",
    childrenModels:['OTHER NOTES'],
    hasInsurance: ['ESI11',],
    componentParams: [{
            label: "Home",
            value: 'drugRequestedHome',
        },
        {
            label: "Hospital",
            value: 'drugRequestedHospitalIn',
        },
        {
            label: "Private Clinic",
            value: 'drugRequestedPrivate',
        },
        {
            label: "Other",
            value: 'drugRequestedOther',
        },
    ],
    nodes:[
        {
            inputField: "text",
            expectedValue:"drugRequestedOther",
            model: "OTHER NOTES",
            hasInsurance: ['ESI11'],
            componentParams: {
                label: "Other, specify...",
            }
        }
    ]
},
{
    inputField: "text",
    expectedValue:"",
    model: "MEDICAL CONDITION",
    hasInsurance: ['ESI', 'EP'],
    componentParams: {
        label: "Other, specify...",
    }
},
{
    questionLabel: "Please confirm if the drug was started in hospital with the intention for the patient to use at home",
    inputField: 'radio',
    model: "manuHomeIntentionRadio",
    hasInsurance: ['ESI02','EP02'],
    componentParams: [{
            label: "Yes",
            value: 'manuHomeIntentYes'
        },
        {
            label: "No",
            value: 'manuHomeIntentNo'
        },
    ],
}, {
    questionLabel: "Prescribed dose and regimen requested:",
    inputField: 'radio',
    model: "GWL DRUG RADIO",
    childrenModels:['GWL DRUG RATIONALE'],
    hasInsurance: ['AS11'],
    componentParams: [{
            label: "75mg subcutaneously every 2 weeks",
            value: '75mg'
        },
        {
            label: "150mg subcutaneously every 2 weeks",
            value: '150mg'
        },
        {
            label: "Other",
            value: 'otherMg',
        }
    ],
    nodes: [{
        inputField: "text",
        expectedValue: "otherMg",
        model: "GWL DRUG RATIONALE",
        hasInsurance: ['BC', 'OB'],
        componentParams: {
            label: "Number of vials per dose",
        },
    }]
}, {
    questionLabel: "Are any alternative drug treatments available?",
    inputField: 'radio',
    model: "IAdrugAlternative",
    hasInsurance: ['ESI11'],
    componentParams: [{
            label: "Yes",
            value: 'IADrugYes'
        },
        {
            label: "No",
            value: 'IADrugNo'
        },
    ],
}

]
export {
    drugRequestedSub
}
const pharmacistInfoSub = [
{
    questionLabel: "Decision Communication Preference",
    inputField: "radio",
    model: "COMM PREFERENCE",
    hasInsurance: ['AS16', 'BC', 'OB'],
    componentParams: [{
            label: "Phone",
            value: "pharmPHONE"
        },
        {
            label: "Fax",
            value: "pharmFAX"
        }
    ],
}

];

export {
    pharmacistInfoSub
}
const drugRequestedMain = [{
        // should default to Praluent
        inputField: "readonly",
        model: "DRUG NAME",
        hasInsurance: ['ESI12','EP12', 'ESI', 'EP', 'GS', 'SSQ', 'SQ', 'AS', 'AS16', 'NEX', 'NX', 'ESI11', 'RXP', 'CS','ESI02','EP02','AS11','BC','OB','ODB','DB'],
        componentParams: {
            label: "Drug Name",
            value:"Praluent"
        },
    },
    
    

    {
        // default to injection
        inputField: "readonly",
        model: "DRUG DIRECTIONS",
        hasInsurance: ['ESI12','EP12', 'SSQ', 'SQ', 'NEX', 'NX', 'ESI', 'EP'],
        componentParams: {
            label: "Drug Directions",
            value: "Injection"
        }
    },
    
    {
        inputField: "text",
        model: "VIALS PER DOSE",
        hasInsurance: ['BC', 'OB'],
        componentParams: {
            label: "Number of vials per dose",
        }, 
    },
{
    inputField: "text",
    model: "MEDICAL CONDITION",
    hasInsurance: ['ESI', 'EP'],
    componentParams: {
        label: "Indication/Medical Condition(if any):",
    }, 
},  {
    inputField: 'text',
    model: "additionalInfo",
    hasInsurance: ['ESI', 'EP', 'SSQ', 'SQ', 'ESI11', 'RXP', 'CS'],
    componentParams: {
        label: "Any additional info that supports use of this drug for this patient"
    }, 
},
]

export {
    drugRequestedMain
}
const pharmacistInfo = [
    {
        inputField: "text",
        model: "rphfirstname",
        componentParams: 
            {
                label: "Pharmacist Name",
            },
        
        hasInsurance:[]
    },
    {
        inputField: "text",
        model: "ocp",
        componentParams: 
            {
                label: "OCP",
            },
        
        hasInsurance:[]
    },
    {
        inputField: "text",
        model: "PHARMACY FAX NUMBER",
        rules: [(v) => !!v || 'Fax is required'],
        componentParams: 
            {
                label: "Pharmacy Fax",
            },
        
        hasInsurance:[]
    },
    {
        inputField: "text",
        model: "PHARMACY UNIT NUMBER",
        componentParams: {
            label: "Pharmacy Unit #",
        }, 
        hasInsurance: []
    }, {
        inputField: "text",
        model: "PHARMACY STREET NUMBER",
        componentParams: {
            label: "Pharmacy Street #",

        }, 
        hasInsurance: []
    }, {
        inputField: "text",
        model: "PHARMACY STREET NAME",
        componentParams: {
            label: "Pharmacy Street Name",
        }, 
        hasInsurance: []
    }, {
        inputField: "text",
        model: "PHARMACY CITY",
        componentParams: {
            label: "Pharmacy City",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "PHARMACY PROVINCE",
        componentParams: {
            label: "Pharmacy Province",
        },
        hasInsurance: []
    }, {
        inputField: "text",
        model: "PHARMACY POSTAL CODE",
        componentParams: {
            label: "Pharmacy Postal Code",
        }, 
        hasInsurance: []
    }, {
        inputField: "text",
        model: "PHARMACY NAME",
        hasInsurance: ['AS16', 'NEX', 'NX'],
        componentParams: {
            label: "Pharmacy Name",
        },
        hasInsurance: []
    },
    {
        inputField: "text",
        model: "PHARMACY TELEPHONE NUMBER",
        componentParams: {
            label: "Pharmacy Telephone #",
        },
    }, {
        inputField: "text",
        model: "PHARMACY EMAIL ADDRESS",
        componentParams: {
            label: "Pharmacy Email Address",
        },
        hasInsurance: []
    },
];

export {
    pharmacistInfo
}
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" xs="12" sm="12" md="3">
        <v-row class="mb-3">
          <h2>Assessment</h2>
        </v-row>
        <v-row class="mb-7" v-if="!specificInsurance(['RXP','CS','ASI','ESI11'])">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#B30000"
              class="white--text"
                minWidth="200px"
                v-bind="attrs"
                v-on="on"
                @click="dialogInfo = true"
              >
                Additinal Info
                
              </v-btn>
            </template>
            <span>
              {{
                valid === "valid"
                  ? "All fields are filled!"
                  : valid === "optional"
                  ? "Optional fields not filled out"
                  : "Pharmacy Fax is missing"
              }}
            </span>
            <v-dialog v-model="dialogInfo" max-width="900px">
              <v-card>
                <v-card-title>
                  <span class="h4">Assessment Questions</span>
                </v-card-title>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-container>
                      <v-row>
                        <v-col cols="auto" xs="12" sm="12" md="3">
                          <div class="h5">Questions:</div>
                        </v-col>
                        <v-col cols="auto" xs="12" sm="12" md="9">
                          <template v-if="isInitialRenewal === 'initial'">
                            <recursive-tree
                              v-for="(field, index) in assessmentInitial"
                              :key="`initial${index}`"
                              :questionLabel="field.questionLabel"
                              :nodes="field.nodes"
                              :inputField="field.inputField"
                              :model="field.model"
                              :componentParams="field.componentParams"
                              :expectedValue="field.expectedValue"
                              :previousValue="field.previousValue"
                              :rules="field.rules"
                              :depth="0"
                              :hasInsurance="field.hasInsurance"
                              :items="field.items"
                              :textTitle="field.textTitle"
                            >
                            </recursive-tree>
                          </template>
                          <template v-if="isInitialRenewal === 'renewal'">
                            <recursive-tree
                              v-for="(field, index) in assessmentRenewal"
                              :key="`renewal${index}`"
                              :questionLabel="field.questionLabel"
                              :nodes="field.nodes"
                              :inputField="field.inputField"
                              :model="field.model"
                              :componentParams="field.componentParams"
                              :expectedValue="field.expectedValue"
                              :previousValue="field.previousValue"
                              :rules="field.rules"
                              :depth="0"
                              :hasInsurance="field.hasInsurance"
                              :items="field.items"
                              :textTitle="field.textTitle"
                            >
                            </recursive-tree>
                          </template>
                          <template v-if="isHomoHeteroCardio === 'homo'">
                            <recursive-tree
                              v-for="(field, index) in assessmentHomo"
                              :key="`homo${index}`"
                              :questionLabel="field.questionLabel"
                              :nodes="field.nodes"
                              :inputField="field.inputField"
                              :model="field.model"
                              :componentParams="field.componentParams"
                              :expectedValue="field.expectedValue"
                              :previousValue="field.previousValue"
                              :rules="field.rules"
                              :depth="0"
                              :hasInsurance="field.hasInsurance"
                              :items="field.items"
                              :textTitle="field.textTitle"
                            >
                            </recursive-tree>
                          </template>
                          <template v-if="isHomoHeteroCardio === 'cardio'">
                            <recursive-tree
                              v-for="(field, index) in assessmentCardio"
                              :key="`cardio${index}`"
                              :questionLabel="field.questionLabel"
                              :nodes="field.nodes"
                              :inputField="field.inputField"
                              :model="field.model"
                              :componentParams="field.componentParams"
                              :expectedValue="field.expectedValue"
                              :previousValue="field.previousValue"
                              :rules="field.rules"
                              :depth="0"
                              :hasInsurance="field.hasInsurance"
                              :items="field.items"
                              :textTitle="field.textTitle"
                            >
                            </recursive-tree>
                          </template>
                          <template v-if="isHomoHeteroCardio === 'hetero'">
                            <recursive-tree
                              v-for="(field, index) in assessmentHetero"
                              :key="`hetero${index}`"
                              :questionLabel="field.questionLabel"
                              :nodes="field.nodes"
                              :inputField="field.inputField"
                              :model="field.model"
                              :componentParams="field.componentParams"
                              :expectedValue="field.expectedValue"
                              :previousValue="field.previousValue"
                              :rules="field.rules"
                              :depth="0"
                              :hasInsurance="field.hasInsurance"
                              :items="field.items"
                              :textTitle="field.textTitle"
                            >
                            </recursive-tree>
                          </template>
                          <template>
                            <recursive-tree
                              v-for="(field, index) in assessmentGeneral"
                              :key="`general${index}`"
                              :questionLabel="field.questionLabel"
                              :nodes="field.nodes"
                              :inputField="field.inputField"
                              :model="field.model"
                              :componentParams="field.componentParams"
                              :expectedValue="field.expectedValue"
                              :previousValue="field.previousValue"
                              :rules="field.rules"
                              :depth="0"
                              :hasInsurance="field.hasInsurance"
                              :items="field.items"
                              :textTitle="field.textTitle"
                            >
                            </recursive-tree>
                          </template>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="dialogInfo = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tooltip>
        </v-row>
      </v-col>
      <v-col cols="auto" xs="12" sm="12" md="9">
        <v-row v-if="specificInsurance(['RXP','CS','ESI11','NEX','NX','ODB','DB'])">
          <h2>N/A. Please check additional info button if present</h2>
        </v-row>
        <v-row>
          <recursive-tree-multiple
            v-for="(field, index) in assessmentMain"
            :key="index"
            :questionLabel="field.questionLabel"
            :nodes="field.nodes"
            :inputField="field.inputField"
            :model="field.model"
            :componentParams="field.componentParams"
            :expectedValue="field.expectedValue"
            :previousValue="field.previousValue"
            :rules="field.rules"
            :hasInsurance="field.hasInsurance"
            :depth="0"
            :items="field.items"
          >
          </recursive-tree-multiple>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecursiveTree from "@/components/common/ui/Layout/RecursiveTree/RecursiveTree";
import RecursiveTreeMultiple from "@/components/common/ui/Layout/RecursiveTree/RecursiveTreeMultiple";
import { assessmentMain } from "./praluentData/Main";
import { assessmentHomo } from "./praluentData/Homo";
import { assessmentHetero } from "./praluentData/Hetero";
import { assessmentCardio } from "./praluentData/Cardio";
import { assessmentInitial } from "./praluentData/Initial";
import { assessmentRenewal } from "./praluentData/Renewal";
import {assessmentGeneral} from "./praluentData/General";
export default {
  components: {
    "recursive-tree": RecursiveTree,
    "recursive-tree-multiple": RecursiveTreeMultiple,
  },
  data() {
    return {
      assessmentMain: assessmentMain,
      assessmentInitial: assessmentInitial,
      assessmentRenewal: assessmentRenewal,
      assessmentHomo: assessmentHomo,
      assessmentHetero: assessmentHetero,
      assessmentCardio: assessmentCardio,
      assessmentGeneral:assessmentGeneral,
      isInitialRenewal: null,
      isHomoHeteroCardio: null,
      // FOR TESTING:to remove valid error
      valid: "valid",
      dialogInfo: false,
    };
  },
  updated() {
    this.isInitialRenewal = this.$store.state.Praluent.praluentPatient[
      "INITIAL RENEWAL CHOICE"
    ];
    this.isHomoHeteroCardio = this.$store.state.Praluent.praluentPatient[
      "HOMO HETERO CARDIO CHOICE"
    ];
  },
  methods: {
    specificInsurance(insuranceCompanies) {
      let flag = false;
      let privateInsurance;
      if(Object.entries(this.$store.getters.TrackProcess.praluentPatient).length === 0 ){
        return flag;
      }else{
        if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb' && this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider.length < 2){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        else if(this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0].apikey === 'odb'){
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[1]
        }
        else{
            privateInsurance = this.$store.getters.TrackProcess.praluentPatient.InsuranceProvider[0]
        }
        insuranceCompanies.forEach(insuranceCompany => {
          if(privateInsurance.apikey === 'odb'){
            flag = true;
          }
          if (privateInsurance.code === insuranceCompany) {
            flag = true;
          }
        });
        return flag;
      }
    },
  },
};
</script>

<style>
</style>